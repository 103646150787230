import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import NonOpImage from "../../assets/non-op.png";
import GetAppIcon from "@material-ui/icons/GetApp";
// import { Link } from "react-router-dom";
import "./JointNoi1.css";
import NonOperativePacket from "../../../src/assets/pdf/Non-operative_Education_Packet.pdf";

import { Grid } from "@material-ui/core";
import { getLeftHipId, getRightHipId } from "../../StoreFunctions/evaluationStoreFunctions";

class LearnMoreNoi extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _downloadPdf = () => {
    var link = document.createElement("a");
    link.innerHTML = "Download ";
    link.download = "NonOperativePacket.pdf";
    link.href = NonOperativePacket;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  render() {
    const {joint} = this.props;
    return (
      <div id="RCPE-Welcome-main-container">
        <div id="all-content-wrapper">
          <div id="RCPE-Welcome-box-1">
            <div id="JointNoi3-inner-box-1">
              {/* Dic 1 */}
              <div id="RCPNOI-container-1">
                <h2 id="RCPNOI-heading">
                  {`Reducing Pain, Improving Joint Function`} <br />
                  {`and Slowing the Progression of Arthritis`}
                </h2>

                <div id="RCPNOI-form-box-1">
                  <form id="RCPNOI-form">
                    <div className="RCPNOI-heading-1-wrapper">
                      <div
                        className="RCPNOI-heading-1-button-2-wrapper"
                        onClick={this._downloadPdf}
                      >
                        <GetAppIcon style={{ color: "#B4EC51" }} />
                      </div>
                      <div className="RCPNOI-heading-1"
                        style={{ cursor: 'pointer' }}
                        onClick={this._downloadPdf}>
                        Learn How to Improve Your Joint Health
                      </div>
                    </div> 
                    <ul className="LearnAbout-unstyled RCPNOIcentered">
                      <Grid container>
                        <Grid item md={6}xs={12} sm={12}>
                          {[
                            "How the body Controls Pain",
                            "Physical Activity",
                            "Emotional Wellness",
                            "Relaxation Therapy",
                            "Complementary Pain Management",
                            'Coping Mechanisms for Dealing with Pain',
                          ].map((text) => (
                            <li>
                              <label htmlFor={text} className="RCPNOI-form-field">
                                -{text}
                              </label>
                            </li>
                          ))}
                        </Grid>
                        <Grid item md={6}>
                          { ( ( (joint.joint_id===getLeftHipId()) || (joint.joint_id===getRightHipId()) )? [
                            "Nutrition and Weight Management",
                            "Smoking Cessation",
                            "Physical and Occupation",
                            "Assistive Devices",
                          ]: ["Nutrition and Weight Management",
                          "Smoking Cessation",
                          "Physical and Occupation",
                          "Assistive Devices",
                          "Joint Injections"]).map((text) => (
                            <li>
                              <label htmlFor={text} className="RCPNOI-form-field">
                                -{text}
                              </label>

                              {/* <span className="RCPNOI-square-box">

                                                            </span>
                                                            <span className="RCPNOI-text-field">
                                                                {text}
                                                            </span> */}
                            </li>
                          ))}
                        </Grid>
                      </Grid>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div id="RCPE-Welcome-box-2">
            <div id="RCPE-Welcome-footer-div1">
              <Button
                id="RCPE-Welcome-btn-1"
                variant="contained"
                onClick={this.props.handleBackClick}
              >
                {" "}
                Back{" "}
              </Button>
              <Button
                id="LearnAbout-btn-2"
                variant="contained"
                onClick={this._downloadPdf}
              >
                {" "}
                Download Education{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LearnMoreNoi;
