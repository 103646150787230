import React, { Component } from "react";
import MyContext from "../../helper/themeContext";
import { VictoryStack, VictoryArea } from "victory";
// import FooterImage from '../../assets/pdfImages/charts-pdf-footer-image.PNG'
// import FooterImage1 from '../../assets/pdfImages/charts-pdf-footer-image1.PNG'
import FooterImage3 from "../../assets/pdfImages/charts-pdf-footer-image3.PNG";
import ArrowImage from "../../assets/chart-pdf-arrow.jpg";

import "./chartImage.css";
import { collectCommonJoints, getCommonEval, getJointMapObject, getJointNameById, sortEval } from "../../StoreFunctions/evaluationStoreFunctions";

class ChartImage extends Component {
  constructor(props) {
    super(props);
    this.state = { JointMapArray: [] };
  }

  UNSAFE_componentWillMount() {

    let Eval = this.context.state.Eval;
    let activeJointIndex = this.context.state.activeJointIndex;
    let currentFormJoints = [];
    
    currentFormJoints = getCommonEval(activeJointIndex,Eval)   
    currentFormJoints = sortEval(currentFormJoints);

    let JointMapArray = [];
    

    for (let i = 0; i < currentFormJoints.length; i++) {
      let joint_id = currentFormJoints[i].joint_id.toString();
      let priority_id = currentFormJoints[i].priority_id.toString();
      let Intervals = getJointMapObject(this.context,joint_id);
      let JointMapObject = { Intervals:Intervals, joint_id: joint_id, priority_id }
      JointMapArray.push(JointMapObject);
    }

    // console.log(JointMapArray);
    this.setState({ JointMapArray });
  }

  render() {
    return (
      <div className="chart-image-wrapper">
        <div className="ImageReportMainDiv" style={{ width: "860px" }}>
          {/* <div className="pdf-page-number-header">Page 3</div> */}
          <div className="xray-report-card-header">
            Patient Reported Pain and Function Profile
          </div>

          <div className="chart-pdf-patient-header">
            {this.context.state.patient.name}{" "}
            <span className="chart-patient-header-middle">
              {" "}
              Evaluation Date : {this.context.state.patient.date}{" "}
            </span>
            <span className="chart-patient-header-right">
              {" "}
              ID: {this.context.state.patient_id}{" "}
            </span>
          </div>

          {this.state.JointMapArray.map((joint, id) => (
            <div
              className="chart-joint-outer-wrapper"
              style={{
                transform: `scale(0.7) translate(-200px,${
                  (id + 1) * -90 - (id > 0 ? 76 : 0)
                }px)`,
              }}
            >
              <div className="chart-joint-outer-text">
                {getJointNameById(joint.joint_id)}
                <div> Priority {joint.priority_id} </div>
                
              </div>
              <div
                className="chart-joint-inner-wrapper"
                style={{ width: "1050px" }}
              >
                <div
                  style={{
                    display: "inline-block",
                    height: "330px",
                    verticalAlign: "top",
                    position: "relative",
                    background: "white",
                    paddingTop: "20px",
                  }}
                >
                  <span className="arrow-text-span-image">
                    BETTER &nbsp; JOINT &nbsp; HEALTH
                  </span>
                  <span id="arrow-image-div">
                    <img src={ArrowImage} id="arrow-image-tag" alt="Arrow" />
                  </span>

                  {
                    joint.Intervals.map((interval,key)=>
                      <span className={`chart-head-${key+1}`}>
                          {interval.label}
                      </span>
                    )
                  }
                </div>

                <div style={{width:"1000px",maxWidth:"calc(100vw - 80px)",display:"inline-block",marginTop:"100px",position:"relative"}}>
                {
                  joint.Intervals.map((interval, key) =>
                (
                  <div
                    style={{
                      display: "inline-block",
                      marginLeft: key === 0 ? "30px" : "",
                      verticalAlign: "bottom",
                      height: "250px",
                      background: "",
                      width: "250px",
                      position: "relative",
                    }}
                  >
                    <VictoryStack
                      animate={{
                        duration: 0,
                        onLoad: { duration: 0 },
                      }}
                      height={450}
                      //   width={100}
                      colorScale={["#F56C7A", "#F7A11A", "#B9DBA7"]}
                    >
                      <VictoryArea
                        data={[
                          { x: "a", y: 4 },
                          { x: "b", y: 4 },
                        ]}
                      />

                      <VictoryArea
                        data={[
                          { x: "a", y: 3 },
                          { x: "b", y: 3 },
                        ]}
                      />

                      <VictoryArea
                        data={[
                          { x: "a", y: 3 },
                          { x: "b", y: 3 },
                        ]}
                      />
                    </VictoryStack>
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "0px",
                        fontWeight: "bold",
                        height: "30px",
                        width: "50px",
                        background: "",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          left: "0px",
                          top: "0px",
                        }}
                      >
                        100
                      </div>
                      <div
                        style={{
                          position: "relative",
                          top: "43.5px",
                          left: "9px",
                        }}
                      >
                        70
                      </div>
                      <div
                        style={{
                          position: "relative",
                          top: "87px",
                          left: "9px",
                        }}
                      >
                        40
                      </div>
                      <div
                        style={{
                          position: "relative",
                          top: "145px",
                          left: "19px",
                        }}
                      >
                        0
                      </div>
                    </div>
                    <div
                      className="chart-notation-dot"
                      style={{ bottom: [interval.score * 1.93 + 23 + "px"] }}
                    ></div>
                    <div
                      className="chart-notation-line"
                      style={{ bottom: [interval.score * 1.93 + 12 + "px"] }}
                    ></div>
                    <div
                      className="chart-notation-text"
                      style={{ bottom: [interval.score * 1.93 - 2 + "px"] }}
                    >
                      {interval.score}
                    </div>
                  </div>
                ))}
                </div>
              </div>
            </div>
          ))}

          <div
            className="chart-pdf-image-footer-wrapper"
            style={{
              transform: `translateY( ${
                -116 * this.state.JointMapArray.length
              }px)`,
            }}
          >
            {/* <img src={FooterImage} alt="Chart - Definitions"/> */}
            <img
              src={FooterImage3}
              alt="Chart - Definitions"
              className="chart-pdf-image-footer-1"
            />
            {/* <img src={FooterImage1} alt="Chart - Definitions"/> */}
          </div>

          {/* <div className="Page1B-Number">
                    3
                </div> */}
        </div>
      </div>
    );
  }
}

ChartImage.contextType = MyContext;
export default ChartImage;
