import React, { Component } from 'react';

import Button from '@material-ui/core/Button';

import Tick from '../../assets/button-tick.png';

import './overview.css'
import MyContext from '../../helper/themeContext';


import { TextField } from '@material-ui/core';


import RightHipIconRed from '../../assets/right hip pain.png'
import LeftHipIconRed from '../../assets/left hip pain.png'
import RightKneeIconRed from '../../assets/right-knee-icon-red.png'
import LeftKneeIconRed from '../../assets/left-knee-icon-red.png'

import LeftHipImage from '../../assets/Asset 10LHip.png'
import RightHipImage from '../../assets/Asset 9RHip.png'
import RightKneeImage from '../../assets/Asset 13RKnee.png'
import LeftKneeImage from '../../assets/Asset 14LKnee.png'

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let skullImage = ""
        let joint = this.context.state.Eval[this.context.state.activeJointIndex].joint_id.toString();
        if(joint==="1")
        {
            skullImage = RightHipImage;
        }
        else if(joint==="2")
        {
            skullImage = LeftHipImage;
        }
        
        else if(joint==="3")
        {
            skullImage = RightKneeImage;
        }
        else if(joint==="4")
        {
            skullImage = LeftKneeImage;
        }


        return (
            <div style={{ zIndex: 2 }}>
                <div id="Evaluaion_XrayMatching_OverView_Content1_Wrapper">
                    <div id="Evaluaion_XrayMatching_OverView_Heading1_Div">
                        X-ray Evaluation Confirmation
                    </div>
                    <div id="Evaluaion_XrayMatching_OverView_Heading2_Div">
                        {this.context.state.joint_id == '3' ? 'Right Knee' : null}
                        {this.context.state.joint_id == '4' ? 'Left Knee' : null}
                    </div>

                    <div id="Evaluaion_XrayMatching_EvalName_Content2_Outer_Wrapper">

                        <div id="Evaluaion_XrayMatching_EvalName_Content2_Wrapper" style={{ width: '40%', verticalAlign: 'middle' }}>

                            {this.context.state.Eval[this.context.state.activeJointIndex].joint_id.toString() === '3' ?
                                <div className="Evaluaion_PatientProfile_Image_Left_Div">

                                    <div className="Evaluaion_PatientProfile_Image_Left_Inner_Down" >
                                        <div className="Evaluaion_PatientProfile_Image_Left_Inner_Up_Content1">
                                            <TextField value={"Priority: " + this.context.state.Eval[this.context.state.activeJointIndex].priority_id.toString()} style={{ width: '115px' }} variant="outlined" inputProps={{ className: "textbox-height" }} />

                                        </div>
                                        <div className="Evaluaion_PatientProfile_Image_Left_Inner_Up_Content2">
                                            <div style={{ color: 'white', marginTop:"10px", marginBottom: '10px', fontSize: '18px' }}>
                                                RIGHT KNEE
                                            </div>
                                            <img style={{ width: '40px', marginBottom: '20px' }} src={RightKneeIconRed} />
                                        </div>

                                    </div>
                                </div>
                                : null}

                        {this.context.state.Eval[this.context.state.activeJointIndex].joint_id.toString() === "1" ?
                            <div className="Evaluaion_PatientProfile_Image_Left_Div">

                                <div className="Evaluaion_PatientProfile_Image_Left_Inner_Up" >
                                    <div className="Evaluaion_PatientProfile_Image_Left_Inner_Up_Content1">
                                        <TextField value={"Priority: " + this.context.state.Eval[this.context.state.activeJointIndex].priority_id.toString()} style={{ width: '115px' }} variant="outlined" inputProps={{ className: "textbox-height" }} />

                                    </div>
                                    <div className="Evaluaion_PatientProfile_Image_Left_Inner_Up_Content2">
                                        <div style={{ color: 'white', marginTop:"10px",marginBottom: '10px', fontSize: '18px' }}>
                                            RIGHT HIP
                                            </div>
                                        <img style={{ width: '40px', marginBottom: '20px' }} src={RightHipIconRed} />
                                    </div>

                                </div>
                            </div>
                            : null}

                            <div id="Evaluaion_XrayMatching_EvalName_Image_Bone_Div"> <img src={skullImage} alt="SBS" id="Evaluaion_XrayMatching_EvalName_Image_Bone" style={{ transform: "translate(20px, 120px)"}} /></div>

                            {this.context.state.Eval[this.context.state.activeJointIndex].joint_id.toString() === '4' ?
                                <div className="Evaluaion_PatientProfile_Image_Right_Div">

                                    <div className="Evaluaion_PatientProfile_Image_Right_Inner_Down" >

                                        <div className="Evaluaion_PatientProfile_Image_Right_Inner_Up_Content1">
                                            <TextField value={"Priority: " + this.context.state.Eval[this.context.state.activeJointIndex].priority_id.toString()} style={{ width: '115px' }} variant="outlined" inputProps={{ className: "textbox-height" }} />
                                        </div>

                                        <div className="Evaluaion_PatientProfile_Image_Right_Inner_Up_Content2">
                                            <div style={{ color: 'white',marginTop:"10px", marginBottom: '10px', fontSize: '18px' }}>
                                                LEFT KNEE
                                            </div>
                                            <img style={{ width: '40px', marginBottom: '20px' }} src={LeftKneeIconRed} />
                                        </div>
                                       

                                    </div>
                                </div>
                                : null
                            }

                            { this.context.state.Eval[this.context.state.activeJointIndex].joint_id.toString() === '2' ?
                                <div className="Evaluaion_PatientProfile_Image_Right_Div">

                                    <div>

                                        <div className="Evaluaion_PatientProfile_Image_Right_Inner_Up_Content1">
                                            <TextField value={"Priority: " + this.context.state.Eval[this.context.state.activeJointIndex].priority_id.toString()}  style={{ width: '115px' }} variant="outlined" inputProps={{ className: "textbox-height" }} />
                                        </div>

                                        <div className="Evaluaion_PatientProfile_Image_Right_Inner_Up_Content2">
                                            <div style={{ color: 'white', marginTop:"10px",marginBottom: '10px', fontSize: '18px' }}>
                                                LEFT HIP
                                            </div>
                                            <img style={{ width: '40px', marginBottom: '20px' }} src={LeftHipIconRed} />
                                        </div>
                                        

                                    </div>
                                </div>
                                : null
                            }
                        </div>

                        <div className="Tutorials_XrayMatchingEducation_Matching_Overview_Wrapper" style={{ width: '60%', verticalAlign: 'middle' }}>

                            {this.props.Evaluation.Xrays.map((type, id) =>
                                <div key={id} >
                                    <div className="Evaluaion_XrayMatching_OverView_Label_Div">
                                        {type.name}
                                    </div>

                                    <div className="Evaluaion_XrayMatching_OverView_Box_Div">
                                        {
                                            type.xrays.map((xray, id) =>
                                                <div key={id}>
                                                    <div className="Evaluaion_XrayMatching_OverView_Box1_Content1">
                                                        <img className="Evaluaion_XrayMatching_OverView_Box1_Content1_Image" alt={xray.name} src={xray.thumbnail} />
                                                    </div>
                                                    <div className="Evaluaion_XrayMatching_OverView_Box1_Content2">

                                                        {xray.isDone === true ?
                                                            <div className="aaaa">
                                                                <div className="Evaluaion_XrayMatching_OverView_Box_DisbaleText"> {xray.name} </div>
                                                                <div className="Evaluaion_XrayMatching_OverView_Box_DisbaleText2"> <img src={Tick} style={{ width: '16px' }} alt="done" /> &nbsp; Complete </div>
                                                            </div>
                                                            :
                                                            xray.enable === true ?
                                                                <Button className="Evaluaion_XrayMatching_OverView_Box_Button" variant="contained" onClick={() => this.props.handleClick(type.name, xray.name)}> {xray.name} </Button>
                                                                :
                                                                <div className="Evaluaion_XrayMatching_OverView_Box_DisbaleText"> {xray.name} </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                            )}
                        </div>
                    </div>
                    {this.props.Next && <div id="Evaluaion_XrayMatching_Overview_Next_Button_Div">
                        <Button id="Evaluaion_XrayMatching_Overview_Next_Button" variant="contained" onClick={this.props.handleNextClick}> {/*View X-ray Report Card*/} Next </Button>
                    </div>}
                </div>
            </div>
        );
    }
}
Overview.contextType = MyContext;
export default Overview;