import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';



import RightHipIcon from '../../assets/right-hip-icon.png'
import LeftHipIcon from '../../assets/left-hip-icon.png'
import RightKneeIcon from '../../assets/right-knee-icon.png'
import LeftKneeIcon from '../../assets/left-knee-icon.png'
import BoneImage from '../../assets/bone2_Bitmap.png'

import RightKneeIconRed from '../../assets/right-knee-icon-red.png'
import LeftKneeIconRed from '../../assets/left-knee-icon-red.png'

import RightHipIconRed from '../../assets/right hip pain.png'
import LeftHipIconRed from '../../assets/left hip pain.png'

import MyContext from '../../helper/themeContext';

// import './newEvaluation.css' (includes new evaluation css)
import './currentEvaluation.css'
import PostData from '../../Fetch/postData1';
import SemipolarLoading from 'react-loadingg/lib/SemipolarLoading';
import { currentCommonJointsArray, getCommonEval, getLeftHipId, getLeftKneeId, getRightHipId, getRightKneeId, initializeForm } from '../../StoreFunctions/evaluationStoreFunctions';

import BothHipImage from '../../assets/Asset 8RLHip.png'
import LeftHipImage from '../../assets/Asset 10LHip.png'
import RightHipImage from '../../assets/Asset 9RHip.png'
import BothKneeImage from '../../assets/Asset 11RLKnee.png'
import RightKneeImage from '../../assets/Asset 13RKnee.png'
import LeftKneeImage from '../../assets/Asset 14LKnee.png'

const nextPageRoute = "./patient-profile";
const backPageRoute = "./new-evaluation";

class CurrentEvaluationJoints extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Eval:[]
        }
    }

    componentDidMount() {
        let Eval = this.context.state.Eval
        if (Eval.length > 0) {
            let commonEvalOnly = getCommonEval(this.context.state.activeJointIndex,Eval);
            this.setState({ Eval:commonEvalOnly })
        }
    }

    // handleClick = () =>
    // {
    //     this.context.multipleUpdateValue([{key:'name',value:'ammar'},{key:'sukoon',value:'sukn'}])
    // }

    handleBack = () => {
        this.context.multipleUpdateValueWithHistory([{ key: 'evaluation_stage', value: '1' }], backPageRoute)
    }

    handleNext = () =>
    {
        this.context.history.push(nextPageRoute)
    }

    render() {
        // const old = parseInt(this.context.state.evaluation_stage) > 1 ? true : false;
        const old = parseInt(this.context.state.evaluation_stage) > 1 ? true : false;
        const {Eval} = this.state
        let leftHip = Eval.find((e)=>e.joint_id.toString()===getLeftHipId())
        let leftKnee = Eval.find((e)=>e.joint_id.toString()===getLeftKneeId())
        let rightKnee = Eval.find((e)=>e.joint_id.toString()===getRightKneeId())
        let rightHip = Eval.find((e)=>e.joint_id.toString()===getRightHipId())

        let skullImage = "";
        let joints = currentCommonJointsArray(this.context.state);
        if(joints.includes("1") && joints.includes("2"))
        {
            skullImage = BothHipImage;
        }
        else if(joints.includes("1"))
        {
            skullImage = RightHipImage;
        }
        else if(joints.includes("2"))
        {
            skullImage = LeftHipImage;
        }
        else if(joints.includes("3") && joints.includes("4"))
        {
            skullImage = BothKneeImage;
        }
        else if(joints.includes("3"))
        {
            skullImage = RightKneeImage;
        }
        else if(joints.includes("4"))
        {
            skullImage = LeftKneeImage;
        }

        let joint_id = this.context.state.Eval[this.context.state.activeJointIndex].joint_id.toString();
        return (
            <div id="Evaluaion_NewEvaluation_Main_Div">
                {this.state.loading == true ? <SemipolarLoading size={"large"} color={'#b4ec51'} />
                    :

                    <div id="Evaluaion_NewEvaluation_Content_Wrapper">
                        <div id="Evaluaion_CurrentEvaluation_Heading1_Div">
                            {       
                                (joint_id===getLeftHipId() || joint_id===getRightHipId()) ?
                                "Hip "
                                : "Knee "
                            }
                            Evaluation Start
                        </div>
                        {/* <div id="Evaluaion_NewEvaluation_Heading2_Div">
                        Indicate which joints hurt
                    </div> */}


                        <div id="Evaluaion_NewEvaluation_Image_div">

                            <div className="Evaluaion_NewEvaluation_Image_Left_Div">

                                {
                                    rightHip &&                         
                                    <div className="Evaluaion_NewEvaluation_Image_Left_Inner_Up" >
                                        <div className="Evaluaion_NewEvaluation_Image_Left_Inner_Up_Content1">
                                                <TextField value={"Priority: " + rightHip.priority_id} style={{ width: '115px' }} variant="outlined" inputProps={{ className: "textbox-height" }} />
                                        </div>
                                        <div className="Evaluaion_NewEvaluation_Image_Left_Inner_Up_Content2">
                                            <div style={{ color: 'white', marginBottom: '10px', fontSize: '18px' }}>
                                                RIGHT HIP
                                            </div>
                                            <img style={{ width: '40px', marginBottom: '20px' }} src={rightHip.priority_id.toString() !== "0" ? RightHipIconRed : RightHipIcon} />
                                        </div>
                                    </div>
                                }

                                {
                                    rightKnee &&

                                    <div className="Evaluaion_NewEvaluation_Image_Left_Inner_Down" >
                                        <div className="Evaluaion_NewEvaluation_Image_Left_Inner_Down_Content1">
                                            <TextField value={"Priority: " + rightKnee.priority_id} style={{ width: '115px' }} variant="outlined" inputProps={{ className: "textbox-height" }} />
                                        </div>
                                        <div className="Evaluaion_NewEvaluation_Image_Left_Inner_Down_Content2">
                                            <div style={{ color: 'white', marginBottom: '10px', fontSize: '18px' }}>
                                                RIGHT KNEE
                                            </div>
                                            <img style={{ width: '40px', marginBottom: '20px' }} src={rightKnee.priority_id.toString() !== "0" ? RightKneeIconRed : RightKneeIcon} />
                                        </div>

                                    </div>

                                }
                            </div>



                            <div id="Evaluaion_NewEvaluation_Image_Wrapper" >
                                <img src={skullImage} alt="SBS" id="Evaluaion_NewEvaluation_Image_Bone" />
                            </div>

                            <div className="Evaluaion_NewEvaluation_Image_Right_Div">
                                {
                                    leftHip &&
                                    <div className="Evaluaion_NewEvaluation_Image_Right_Inner_Up" >
                                        <div className="Evaluaion_NewEvaluation_Image_Right_Inner_Up_Content2">
                                            <div style={{ color: 'white', marginBottom: '10px', fontSize: '18px' }}>
                                                LEFT HIP
                                            </div>
                                            <img style={{ width: '40px', marginBottom: '20px' }} src={leftHip.priority_id.toString() !== "0" ? LeftHipIconRed : LeftHipIcon} />
                                        </div>
                                        <div className="Evaluaion_NewEvaluation_Image_Right_Inner_Up_Content1">
                                            <TextField value={"Priority: " + leftHip.priority_id} style={{ width: '115px' }} variant="outlined" inputProps={{ className: "textbox-height" }} />
                                        </div>
                                    </div>
                                }
                                
                                {
                                    leftKnee &&
                                    <div className="Evaluaion_NewEvaluation_Image_Right_Inner_Down" >
                                        <div className="Evaluaion_NewEvaluation_Image_Right_Inner_Up_Content2">
                                            <div style={{ color: 'white', marginBottom: '10px', fontSize: '18px' }}>
                                                LEFT KNEE
                                            </div>
                                            <img style={{ width: '40px', marginBottom: '20px' }} src={leftKnee.priority_id.toString() !== "0" ? LeftKneeIconRed : LeftKneeIcon} />
                                        </div>
                                        <div className="Evaluaion_NewEvaluation_Image_Right_Inner_Down_Content1">
                                            <TextField value={"Priority: " + leftKnee.priority_id} style={{ width: '115px' }} variant="outlined" inputProps={{ className: "textbox-height" }} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        
                        <div>
                            <div style={{ position: 'absolute', left: '0px' }}
                                id="Evaluaion_NewEvaluation_Back_Button_Div">
                                <Button id="Evaluaion_NewEvaluation_Next_Button"
                                    variant="contained"
                                    onClick={this.handleBack}> Back </Button>
                            </div>
                            <div id="Evaluaion_NewEvaluation_Next_Button_Div">
                                <Button id="Evaluaion_NewEvaluation_Next_Button"
                                    variant="contained" onClick={this.handleNext}> Next </Button>
                            </div>
                        </div>
                    </div>
                }

            </div>);
    }
}

CurrentEvaluationJoints.contextType = MyContext;
export default CurrentEvaluationJoints;