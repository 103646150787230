import React, { Component } from 'react';

import ChartImage from '../pdfImages/chartImage';
import ReportCard from '../pdfImages/NewReportCard';
import JointRcpImage from '../pdfImages/JointRcpImage';
import ReportPage1 from '../pdfImages/reportPage1';

import html2canvas from 'html2canvas'
import firebase from '../../helper/firebase';
import PostData1 from '../../Fetch/postData5';

import { SemipolarLoading } from 'react-loadingg';
import MyContext from '../../helper/themeContext';
import { Button } from '@material-ui/core';

import './uploadReportImages.css';
import GetImage from '../pdfImages/getImage';
import NoiTreatmentImage from '../pdfImages/NoiTreatmentImage';
import ReportPage1B from '../pdfImages/page-1b';
import TreatmentAImage from '../pdfImages/TreatmentAImage';
import TreatmentBImage from '../pdfImages/TreatmentBImage';
import XrayImages from '../pdfImages/xrayImages';
import OpCTreatment from '../pdfImages/OpCTreatment';
import NOpCTreatment from '../pdfImages/NOpCTreatment';
import { getChartOverallByHip, getChartOverallByKnee, getCommonEval, getJointMapObject, getLeftKneeId, getRightKneeId, moreThanOneJointEval, previousAlreadyDone, sortEval } from '../../StoreFunctions/evaluationStoreFunctions';
import { getAggregate, getNOCOIPathId, getNOCPathId, getPHRPathId, getPJRPathId, getScore, getTHRPathId, getTJRPathId, LoadPath } from '../../StoreFunctions/PRCStoreFunctions';
import PostFormData from '../uploadXray/uploadPostForm';

const nextPageRoute = "./recommended-care-pathway"


const singlePtSummaryPages = 6;
let ptSummaryPages = 6;

//reportpage1a  //page1
//reportpage1b //page2
//informationpage //page3

//chartpage // // page 4
//ReportCardPage // // page 5
//KneeEvaluationandHistory // page 6

//chartpage // // page 4
//ReportCardPage // // page 5
//KneeEvaluationandHistory // page 6

var pdfPages = 6;

class UploadReportImages extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, imageMounted: false, startedUploading: false, JointMapArray: [], req: [] }
    }

    componentDidMount() {
        this.loadJointsRCP();
    }

    componentDidUpdate() {
        if (this.state.imageMounted === true && this.state.startedUploading === false) {
            this.setState({ startedUploading: true });
            this.handleReportUpload()
        }
    }

    loadJointsRCP() {
    

        let Eval = getCommonEval(this.context.state.activeJointIndex,this.context.state.Eval)
        Eval = sortEval(Eval);

        let MatchingEvaluations = this.context.state.Evaluations;



        let JointMapArray = [];

        for (let i = 0; i < Eval.length; i++) {
            let Compartments = [];
            let joint_id =  Eval[i].joint_id.toString();
            let priority_id =  Eval[i].priority_id.toString();

            let Evaluation = MatchingEvaluations.find(eva => eva.joint_id.toString() === joint_id);
            Evaluation.Xrays.forEach((comp)=>{
                let CompartmentScore = getAggregate(comp.xrays[0].state, comp.xrays[1]?.state)
                Compartments.push({name:comp.name,score:CompartmentScore,id:comp.id});
            });

            // let Intervals = getJointMapObject(this.context,this.state.activeJointId);
            let ChartOverAll;
            if((joint_id==="1") || (joint_id==="2"))
            {
                ChartOverAll = getChartOverallByHip();
            }

            if((joint_id==="3") || (joint_id==="4"))
            {
                ChartOverAll = getChartOverallByKnee();
            }

            let form = this.context.state.form;
            let Score = getScore(ChartOverAll,joint_id,form);
            let { pathId,Replacement } = LoadPath(Score, Compartments);
            JointMapArray.push({ Name: Evaluation.name, joint_id:Evaluation.joint_id, priority_id:priority_id, Compartments, Score, pathId, Replacement })
        }
        this.setState({ JointMapArray, imageMounted: true,})
    }

    




    handleReportUpload = async () => {
        const global = this;
        var storage = firebase.storage();
        var upload = 0;
        let PRCPages = [];

        // console.log(this.state.JointMapArray)

        for (let j = 0; j < this.state.JointMapArray.length; j++) {
            PRCPages.push({ pageType: 'live', elementClass: 'JointImage', elementId: j })

            // if(this.state.JointMapArray[j].Path===NOCOIPath || this.state.JointMapArray[j].Path===NOCOIPath)
            // {
            //     PRCPages.push({pageType:'live',elementClass:'treatment-c-wrapper',elementId:j})
            //     // PRCPages.push({pageType:'static',src:'https://firebasestorage.googleapis.com/v0/b/sbs-evaluation.appspot.com/o/static-report-images%2FNoi-Treatments.jpg?alt=media&token=4364d4be-64d1-4ab5-a2a7-6c8e96bd5f06'})
            // }

            if (this.state.JointMapArray[j].pathId === getNOCOIPathId() || this.state.JointMapArray[j].pathId === getNOCPathId()) {
                PRCPages.push({ pageType: 'live', elementClass: 'treatment-c-wrapper', elementId: j })
                // PRCPages.push({pageType:'static',src:'https://firebasestorage.googleapis.com/v0/b/sbs-evaluation.appspot.com/o/static-report-images%2FNoi-Treatments.jpg?alt=media&token=4364d4be-64d1-4ab5-a2a7-6c8e96bd5f06'})
            }

            else if (this.state.JointMapArray[j].pathId === getTJRPathId() || this.state.JointMapArray[j].pathId === getPJRPathId() || this.state.JointMapArray[j].pathId === getTHRPathId() || this.state.JointMapArray[j].pathId === getPHRPathId()) {
                PRCPages.push({ pageType: 'live', elementClass: 'treatment-c-wrapper', elementId: j })

                // PRCPages.push({pageType:'live',elementClass:'treatment-a-wrapper',elementId:j})
                // PRCPages.push({pageType:'live',elementClass:'treatment-b-wrapper',elementId:j})

                // PRCPages.push({pageType:'static',src:'https://firebasestorage.googleapis.com/v0/b/sbs-evaluation.appspot.com/o/static-report-images%2FExpected_Outcomes.jpg?alt=media&token=13eb86ac-67f5-46a1-be19-acc133f58435'})
                // PRCPages.push({pageType:'static',src:'https://firebasestorage.googleapis.com/v0/b/sbs-evaluation.appspot.com/o/static-report-images%2FSurgical_Oi.jpg?alt=media&token=90bb4e8f-637f-4b3e-a8c0-67df6c10378a'})
            }

        }

       

        let Eval = this.context.state.Eval;
        let starting_page = 0;
        let firstAlreadyDone = false;

        if(moreThanOneJointEval(this.context.state.Eval) && previousAlreadyDone(Eval,this.context.state.activeJointIndex))
        {
            
            //starting_page = 10;  //page 11 (starting page cause of loop is -1 from actual page)
            firstAlreadyDone=true;
            ptSummaryPages = 4;
            pdfPages = ptSummaryPages + PRCPages.length;
            // ptSummaryPages = ptSummaryPages + 3;
            pdfPages = pdfPages + 10; //previous pt summary pages + previous prc pages + Second Joint Main Page                (should be dynamic from server)
        }

        else {

            ptSummaryPages = singlePtSummaryPages
            pdfPages = ptSummaryPages + PRCPages.length;

        }

        
        // console.log(ptSummaryPages);
        // console.log(PRCPages);
        // console.log(pdfPages)
        // console.log(starting_page)

        starting_page = 0  // starting page force o zero to update joint main page at page 2 (i = 1)


        for (let i = starting_page;i < pdfPages; i++) {

           const pageNumber = i + 1;
           
           if(firstAlreadyDone)
            {
                if(i<1 || (i > 1 && i < 10))
                {
                    upload = parseInt(upload) + 1;
                    continue;
                }
            }

            // console.log('page went',i+1)
            let element = null

            if (i == 0) {    //first Joint Collection Main Page
                element = document.getElementsByClassName("Report-Page1")[0]
            }

            if (i == 10) {    //Second Joint Collection Main Page
                element = document.getElementsByClassName("Report-Page1")[0]
            }

            if (i == 1) {     //Complete Pdf Main Page
                element = document.getElementsByClassName("Report-Page1B")[0]
            }

            else if (i == 2) {
                element = document.getElementsByClassName("GetImage")[0]
            }

            else if (i == 3 || i == 11) {
                element = document.getElementsByClassName("chart-image-wrapper")[0];
            }

            else if (i == 4 || i == 12) {
                element = document.getElementsByClassName("GetImage1")[0]
            }

            else if (i == 5 || i == 13) {
                let Eval = this.context.state.Eval;
                let activeJointIndex = this.context.state.activeJointIndex;
                let current_joint_id = Eval[activeJointIndex].joint_id.toString();
                // console.log('current_joint_id = > ',current_joint_id)
                if( (current_joint_id===getLeftKneeId())||(current_joint_id===getRightKneeId()) )
                {
                    element = document.getElementsByClassName("pdf-xrays-images-wrapper")[0]
                }
                // upload=upload+1;
                // global.handleAllCheckUpload(upload,"https://firebasestorage.googleapis.com/v0/b/sbs-evaluation.appspot.com/o/static-report-images%2FPt-Summary-Page-5.jpg?alt=media&token=a13e1fef-e4e6-4af9-9465-b05cbd3d63eb",i+1)
            }

            else if ( (i > 5 && i < 10) || (i > 13) ) {
                // console.log(i) 
                // console.log(starting_page)
                // console.log(ptSummaryPages) 
                // console.log(PRCPages)
                // console.log(pdfPages)
                
                let pivot = i - ptSummaryPages;
                if(firstAlreadyDone)
                {
                    pivot = i - 10 - ptSummaryPages   //10 = previous pages  
                }

                if (PRCPages[pivot].pageType === "static") 
                {
                    upload = parseInt(upload) + 1;
                    global.handleAllCheckUpload(upload, PRCPages[pivot].src, pageNumber)
                }

                else {
                    let elementClass = PRCPages[pivot].elementClass;
                    let classId = PRCPages[pivot].elementId;
                    let query = elementClass + classId
                    element = document.getElementsByClassName(query)[0];
                }
            }

            if (element) {

                try {
                    // const storageRef = storage.ref().child('report-images/' + this.context.state.report_id + " page" + (pageNumber));
                    await new html2canvas(element, {
                        useCORS: true,
                        allowTaint: false,
                        letterRendering: true,
                        logging: true,
                    }).then(async function (canvas) {

                        const dataUrl = canvas.toDataURL('image/png');
                        const file = await fetch(dataUrl).then((response) => response.blob());

                        let joint_id = global.context.state.Eval[global.context.state.activeJointIndex]?.joint_id;

                        let form_data = new FormData();
                        form_data.append('visitor_id', global.context.state.report_id)
                        form_data.append('joint_id', joint_id)
                        form_data.append('page_no', pageNumber)
                        form_data.append('img', file)
                        upload = parseInt(upload) + 1;
                        let res = await PostFormData(global.context.baseUrl + '/api/v1/report/image', 200, form_data, global.context.state.token)
                        // console.log("res",res)
                        global.handleAllCheckUpload(upload, res.url , pageNumber)

                        return;
                    })
                }
                catch (e) {
                    console.log('error uploading image', e)
                    alert("Alert Uploading Image")
                }
            }
            else {
                console.log("Unable to upload i+1(page) => ",i+1)
                upload = upload + 1;
            }
        }
        return;
    }
    handleAllCheckUpload = (uploaded, url, pageNumber) => {
        let req = this.state.req;
        // console.log(pageNumber)
        let joint_id = this.context.state.Eval[this.context.state.activeJointIndex]?.joint_id;
        // console.log(joint_id)
        req.push({ visitor_id: this.context.state.report_id, joint_id:joint_id })
        // console.log(uploaded)
        // console.log(pdfPages)
        if (uploaded?.toString() === pdfPages?.toString()) {
            PostData1(this.context.baseUrl + '/api/v1/report/confirm-image-upload', 201, req, this.context.state.token, this.setMeThree)
        }
    }

    setMeThree = () => {
        // Next=true;
        // this.setState({loading:false,Next:true,Matching:null});
        this.context.evalDone();
        this.setState({ loading: false })
        // this.context.history.push('./patient-summary')
        // --------- Skipping Page Entry -v1 Start-------------
        this.context.history.push(nextPageRoute)
        // --------- Skipping Page Entry -v1 End-------------
    }
    render() {
        return (
            <div id="Evaluaion_XrayMatching_Intro_Main_Div">
                {this.state.loading === true ?
                    <React.Fragment>
                        <div style={{ height: '100vh', width: '100vw' }}> <SemipolarLoading size={"large"} color={'#b4ec51'} /> </div>
                        <ReportPage1 /> {/* Cover Page */}
                        <ReportPage1B /> {/* Cover Page 1*/}
                        <GetImage />  {/* Information Page */}
                        <ChartImage /> {/* Chart Page */}
                        <ReportCard JointMapArray = {this.state.JointMapArray}/> {/* Report Card Page */}
                        <XrayImages /> {/* Uploaded xray images Page */}

                        {/* Evaluation History Page */}

                        <JointRcpImage JointMapArray={this.state.JointMapArray} />
                        {/* <NoiTreatmentImage JointMapArray={this.state.JointMapArray}/> 
                        <TreatmentAImage JointMapArray={this.state.JointMapArray}/>
                        <TreatmentBImage JointMapArray={this.state.JointMapArray} /> */}
                        <OpCTreatment JointMapArray={this.state.JointMapArray} />
                        <NOpCTreatment JointMapArray={this.state.JointMapArray} />

                    </React.Fragment>
                    :
                    <React.Fragment>

                        <div className="Evaluation_Upload_Report_Images_Text">
                            Report Pdf Generated...
                        </div>

                        <div className="Evaluation_Upload_Report_Images_Next_Button_Wrapper">
                            <Button variant="contained" className="Evaluation_Upload_Report_Images_Next_Button" onClick={() => this.context.history.push(nextPageRoute)}> Next </Button>
                        </div>

                    </React.Fragment>

                }
            </div>

        );
    }
}

UploadReportImages.contextType = MyContext;
export default UploadReportImages;