import React, { Component } from 'react';


// import Tick from '../../assets/tick-black.png';
// import Grid from '@material-ui/core/Grid';
import MyContext from '../../helper/themeContext';

import './getImage.css';

const height = '60px';

class GetImage extends Component {
    constructor(props) {
        super(props);
        this.state = { Evaluations: [] }
    }

    UNSAFE_componentWillMount() {
        this.setState({ Evaluations: this.context.state.Evaluations })
    }


    render() {
        // console.log('getImage.jsx')
        return (
            <div className="GetImage" style={{
                width: "900px", height: "1100px",
                backgroundColor: "white", position: 'absolute',
                top: '-1600px', //-1600px
                left: '1000px',
                paddingTop: '40px', paddingBottom: '20px'
            }}>
                <div className="ImageReportMainDiv">

                    {/* <div className="pdf-page-number-header">
                        Page 2
                    </div> */}
                    <div className="xray-report-card-header">
                        <span className="bold"> Patient Information </span>
                    </div>

                    <div className="report-image-patient-info-div  GetImage_Report_Box_Text3">
                        <div className="report-image-patient-info-box">
                            <div className="report-image-patient-info-left">
                                <span className="bold margin-right-10">Name: </span> {this.context.state.patient.name}
                            </div>
                            <div className="report-image-patient-info-right">
                                <span className="bold">Birthdate: </span>
                                <span className="report-image-patient-info-right-inner-text"> {this.context.state.patient.birth_date} </span>
                            </div>

                        </div>
                        <br />
                        <div className="report-image-patient-info-box">
                            <div className="report-image-patient-info-left">
                                <span className="bold margin-right-10">ID: </span> {this.context.state.patient_id}
                            </div>
                            <div className="report-image-patient-info-right">
                                <span className="bold">Height: </span>
                                <span className="report-image-patient-info-right-inner-text">  {this.context.state.patient.height} </span>
                            </div>

                        </div>
                        <br />
                        <div className="report-image-patient-info-box GetImage_Report_Box_Text3">
                            <div className="report-image-patient-info-left">
                                <span className="bold margin-right-10">Age: </span>  {this.context.state.patient.age}
                            </div>
                            <div className="report-image-patient-info-right">
                                <span className="bold">Weight: </span>
                                <span className="report-image-patient-info-right-inner-text">  {this.context.state.patient.weight} </span>
                            </div>

                        </div>
                        <br />
                        <div className="report-image-patient-info-box  GetImage_Report_Box_Text3">
                            <div className="report-image-patient-info-left">
                                <span className="bold margin-right-10">Gender: </span>  {this.context.state.patient.gender}
                            </div>
                            <div className="report-image-patient-info-right">
                                <span className="bold">Email: </span>
                                <span className="report-image-patient-info-right-inner-text"> {this.context.state.patient.email} </span>
                            </div>

                        </div>
                        <br />
                        <div className="report-image-patient-info-box">
                            <div className="report-image-patient-info-left">
                                <span className="bold">Cell Phone: </span>  {this.context.state.patient.cell_phone}
                            </div>
                            <div className="report-image-patient-info-right">

                            </div>

                        </div>
                        <br />
                        <div className="report-image-patient-info-box">
                            <div className="report-image-patient-info-left">
                                <span className="bold">Home Phone: </span>  {this.context.state.patient.home_phone}
                            </div>
                            <div className="report-image-patient-info-right">

                            </div>

                        </div>
                        <br />
                        <div className="report-image-patient-info-box">
                            <div className="report-image-patient-info-left">
                                <span className="bold">Home Adress: </span> {this.context.state.patient.home_address}
                            </div>
                            <div className="report-image-patient-info-right">

                            </div>

                        </div>


                    </div>
                </div>
                {/* <div className="Page1B-Number">
                    2
                </div> */}

            </div>
        );
    }
}
GetImage.contextType = MyContext;
export default GetImage;