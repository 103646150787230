import React, { Component } from "react";
import MyContext from "../../helper/themeContext";
import "./educationStepper.css";
import openIcon from "../../assets/open-education-stepper.png";

const themeGrey = "rgb(194, 194, 194)";

class EducationStepper extends Component {
  constructor(props) {
    super(props);
    this.state = { currentSectionId: 1, path: null, isMenuOpen: false };
  }

  _toggleMenu = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  componentDidUpdate() {
    if (this.state.path !== this.context.history.location.pathname) {
      const pages = this.props.pages;
      const path = this.context.history.location.pathname;
      const currentPage = pages.find((page) => page.path === path);
      let currentSectionId = 0;
      if (currentPage) {
        currentSectionId = currentPage.sectionId;
      }
      this.setState({ currentSectionId, path });
    }
  }

  _getBg = (id) => {
    let { currentSectionId } = this.state;
    return id === currentSectionId
      ? themeGrey
      : currentSectionId > id
      ? "orange"
      : "white";
  };

  _handleClick = (path) => {
    this.context.history.push(path);
  };

  render() {
    const { sections } = this.props;
    const { isMenuOpen } = this.state;

    const transformClass = isMenuOpen ? "rotate(0deg)" : "rotate(180deg)";
    const widthClass = isMenuOpen ? "300px" : "22px";
    // console.log("Sections", sections);
    return (
      <div className="education-stepper-wrapper">
        <div className="vertical-stepper-wrapper">
          {sections.map((section, id) => {
            return (
              <div
                className="vertical-stepper-box-wrapper"
                onClick={() => this._handleClick(section.path)}
                style={{ width: widthClass }}
              >
                <div
                  className="vertical-stepper-box"
                  style={{ background: this._getBg(section.id) }}
                ></div>
                <div
                  className="vertical-stepper-menu"
                  style={{ background: this._getBg(section.id) }}
                >
                  {section.label}
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="vertical-stepper-menu-wrapper" style={{width:widthClass}}>

            </div> */}

        <div
          className="vertical-stepper-toggler-wrapper"
          onClick={this._toggleMenu}
        >
          <img
            src={openIcon}
            className="vertical-stepper-toggler-icon"
            style={{ transform: transformClass }}
            alt={"open menu"}
          />
        </div>
      </div>
    );
  }
}

EducationStepper.contextType = MyContext;
export default EducationStepper;
