import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';



import RightHipIcon from '../../assets/right-hip-icon.png'
import LeftHipIcon from '../../assets/left-hip-icon.png'
import RightKneeIcon from '../../assets/right-knee-icon.png'
import LeftKneeIcon from '../../assets/left-knee-icon.png'
import BoneImage from '../../assets/bone2_Bitmap.png'

import RightKneeIconRed from '../../assets/right-knee-icon-red.png'
import LeftKneeIconRed from '../../assets/left-knee-icon-red.png'

import RightHipIconRed from '../../assets/right hip pain.png'
import LeftHipIconRed from '../../assets/left hip pain.png'

import HipEvaluationImage from '../../assets/hip_evaluation_home.png';
import KneeEvaluationImage from '../../assets/knee_evaluation_home.png'

import MyContext from '../../helper/themeContext';

// import './newEvaluation.css' (includes new evaluation css)
import './PatientSummariesDownload.css';

import PostData from '../../Fetch/postData1';
import SemipolarLoading from 'react-loadingg/lib/SemipolarLoading';
import { currentCommonJointsArray, GetAllHipIds, GetAllKneeIds, getCommonEval, getLeftHipId, getLeftKneeId, getRightHipId, getRightKneeId, initializeForm } from '../../StoreFunctions/evaluationStoreFunctions';
import { getJointSummaryNumber } from '../../StoreFunctions/PRCStoreFunctions';
import GetData from '../../Fetch/getData1';

const nextPageRoute = "./report-feedback";
const backPageRoute = "./common-joint-pdf";

class PatientSummariesDownload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            knee_joint_summary_number:null,hip_joint_summary_number:null,loading:false
        }
    }

    componentDidMount() {
        let Eval = this.context.state.Eval;
    
        let KNEEINDEX = Eval.findIndex((e)=>GetAllKneeIds().includes(e.joint_id.toString()));
        let HIPINDEX = Eval.findIndex((e)=>GetAllHipIds().includes(e.joint_id.toString()));

        let knee_joint_summary_number = getJointSummaryNumber(Eval,KNEEINDEX);
        let hip_joint_summary_number = getJointSummaryNumber(Eval,HIPINDEX);

        this.setState({knee_joint_summary_number,hip_joint_summary_number})

    }

    handleDownloadPdf = (joint_summary_number) =>
    {
        let visitor_id = this.context.state.report_id;

        let req = {
            visitor_id: visitor_id,
        }

        if(joint_summary_number)
        {
            req["joint_summary_number"] = joint_summary_number
        }

        this.setState({loading:true})
       
        GetData(this.context.baseUrl + '/api/v1/get-prc/single/pdf', 200, req,this.context.state.token, this.setMe)
    }

    setMe = (response) => {

        if (response.base64pdf) {
            this.handleDownload(response.base64pdf)
        }

        else alert("Pdf not found")

        this.setState({ loading: false})

    }

    handleDownload = (base64) => {
        var link = document.createElement('a');
        link.innerHTML = 'Download PDF file';
        link.download = this.context.state.patient_id + "_" + this.context.state.report_id + '_Report.pdf';
        link.href = 'data:application/octet-stream;base64,' + base64;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // handleClick = () =>
    // {
    //     this.context.multipleUpdateValue([{key:'name',value:'ammar'},{key:'sukoon',value:'sukn'}])
    // }

    handleBack = () => {
        this.context.history.push(backPageRoute)
    }

    handleNext = () =>
    {
        this.context.history.push(nextPageRoute)
    }

    render() {
        // const old = parseInt(this.context.state.evaluation_stage) > 1 ? true : false;
        const old = parseInt(this.context.state.evaluation_stage) > 1 ? true : false;
        return (
            <div id="Evaluaion_NewEvaluation_Main_Div">
                {this.state.loading == true ? <SemipolarLoading size={"large"} color={'#b4ec51'} />
                    :

                    <div id="Evaluaion_NewEvaluation_Content_Wrapper">
                        <div id="Evaluaion_CurrentEvaluation_Heading1_Div">
                           Your Patient Summaries
                        </div>
                        {/* <div id="Evaluaion_NewEvaluation_Heading2_Div">
                        Indicate which joints hurt
                    </div> */}


                        <div id="Evaluaion_NewEvaluation_Image_div">
                                <div>
                                    <div className="Download_Both_Summaries_Div">
                                        <div>
                                            Download Combined Summary
                                        </div>
                                        <div>
                                            <Button className="Summaries_Download_Buttons" onClick={this.handleDownloadPdf} > Download </Button>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="Download_Single_Summaries_Div">
                                        <div>
                                            Hip Only Summary
                                        </div>
                                        <div>
                                            <Button className="Summaries_Download_Buttons" onClick={()=>{this.handleDownloadPdf(this.state.hip_joint_summary_number)}}> Download </Button>
                                        </div>
                                        <div style={{marginTop:"50px"}}>
                                            <img src={HipEvaluationImage} alt="Hip" style={{maxWidth:"100%"}} />
                                        </div>
                                    </div>
                                    <div className="Download_Single_Summaries_Div">
                                        <div>
                                            Knee Only Summary
                                        </div>
                                        <div>
                                            <Button className="Summaries_Download_Buttons" onClick={()=>{this.handleDownloadPdf(this.state.knee_joint_summary_number)}}> Download </Button>
                                        </div>
                                        <div>
                                            <img src={KneeEvaluationImage} alt="Knee" style={{maxWidth:"100%"}}/>
                                        </div>

                                        
                                    </div>
                                    
                                </div>
                                
                        </div>
                        
                        <div>
                            <div style={{ position: 'absolute', left: '0px' }}
                                id="Evaluaion_NewEvaluation_Back_Button_Div">
                                <Button id="Evaluaion_NewEvaluation_Next_Button"
                                    variant="contained"
                                    onClick={this.handleBack}> Back </Button>
                            </div>
                            <div id="Evaluaion_NewEvaluation_Next_Button_Div">
                                <Button id="Evaluaion_NewEvaluation_Next_Button"
                                    variant="contained" onClick={this.handleNext}> Next </Button>
                            </div>
                        </div>
                    </div>
                }

            </div>);
    }
}

PatientSummariesDownload.contextType = MyContext;
export default PatientSummariesDownload;