import React, { Component } from "react";
import { Route, Router } from "react-router-dom";

//helpers
import history from "./helper/history";
import MyContext from "./helper/themeContext";

//components
import Evaluation from "./evaluation/evaluation";
import Drawer from "./general/drawer/drawer";
import Tutorials from "./tutorials/tutorials";
import LearnMore from "./tutorials/LearnMore/LearnMore";
import EvaluationHistory from "./pages/evaluationHistory/evaluationHistory";
import IncompleteEvaluations from "./pages/incompleteEvaluations/incompleteEvaluations";
import Login from "./login/login";
import CreateUser from "./admin/createUser/createUser";
import Home from "./home/homeNew";
import EditProfile from "./editprofile/EditProfile";


//functions
import { emptyEvalState, emptyVisitorState, LoadDummyEvaluation, _setMatchingEductaionStuff } from './StoreFunctions/evaluationStoreFunctions'




import StartOver from "./offer/startOver";
import Guest from "./guest";
// import RecomCarePath from "./tutorials/PatientEvaluation/recomCarePath/recomendedcarepathway";
// import UploadReport from './evaluation/patientReport/upload/uploadReports';

const baseUrlH = "https://sbs-server-adonis.herokuapp.com"; //old Env
// const baseUrlA = "https://sbs-backend-dw.herokuapp.com"
const baseUrlA = "https://sbs-api-prod.codingtier.com"
const baseUrlL = "http://127.0.0.1:3333";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

//-------- Session Variables --------------------
  //1- type
  //2- user_id
  //4- user_email
  //3- user_type
  //4- isTutorialCompleted
  //5- organization
  //6- loggedIn
  //7- oldEvaluations
  //8- isOldEvaluation
  let sessionVariables = {
    token:null,
    type:null,
    user_id:null,
    user_email:null,
    user_type_id:null,
    user_type_name:null,
    isTutorialCompleted:null,
    organization:null,
    loggedIn:null,
    isOldEvaluations:null,
    tutorials:null,
  }
  //-----------------------------------------------
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, oldEvaluations: [] };
  }

  UNSAFE_componentWillMount() {
    
    // LoadDummyEvaluation(this, '/Evaluation/dev-testing-pdf');
    this.loadPrevSession();
    _setMatchingEductaionStuff(this);

    // prodMode();
  }


  // prodMode = () => {
  //   // this.loadPrevSession();
  //   _setMatchingEductaionStuff(this);
  // }

  loadPrevSession = () => {
    if (this.state.token == null || !this.state.token ||
      this.state.token === "" || this.state.token === " ") {
      // will always be true

      for (var obj in sessionVariables) {
        sessionVariables[obj] = this.getCookie(obj)
      }

      let {organization,user_id,token,type,user_email,user_type_id,user_type_name,tutorials,loggedIn} = sessionVariables;
      if (organization && organization.toString() !== 'undefined' && organization.toString() !== 'null') {
        organization = JSON.parse(organization);
      }
      else organization = {};

      if (tutorials && tutorials.toString() !== 'undefined' && tutorials.toString() !== 'null') {
        let completed_tutorials = [];
        completed_tutorials = tutorials.split('|').map(function(item){
          return parseInt(item)
        });
        
        sessionVariables['tutorials'] = completed_tutorials;
      }
      else tutorials = [];


      let tutorial_rem = this.getCookie("tutorial-" + user_id);


      if (
        token === undefined || token === "" || token === " " ||
        type === undefined || type === "" || type === " " ||
        user_id === undefined || user_id === "" || user_id === " " ||
        user_email === undefined || user_email === "" || user_email === " " ||
        user_type_id === undefined || user_type_id === "" || user_type_id === " " ||
        user_type_name === undefined || user_type_name === "" || user_type_name === " " ||
        organization === undefined || organization === "" || organization === " " || organization === "undefined" ||
        loggedIn === undefined || loggedIn === "" || loggedIn === " " || loggedIn === "undefined"
        

      ) {

        //on No previous session from cookie
        // console.log("No Login Session");
        if(window.location.pathname==="/guest")
        {
          this.setState({ loading: false });
        }
        else {
          this.setState({ loading: false });
          history.push("/login");
        }
        
      }
      else {

        // console.log(sessionVariables)
        // ------------------ redirection after loading previous session----------------------------------
        this.setState({
          ...sessionVariables,
          loading: false,
          tutorial_rem: tutorial_rem,

        });
        //----------- start --------- new setup after login session from cookie
          history.push("/home"); // ------ home will cater resume and tutorials
        //----------- end --------- new setup after login session from cookie
      }
    }

  }

  checkGuesUserMode = () =>
  {
    if(this.state.user_mode==="guest")
    {
      return true
    }
    else return false;
  }
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cname, cvalue, exmins) {
    var d = new Date();
    d.setMinutes(d.getMinutes() + exmins);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  Logout = () => {
    //this.clearLeft();  -> done in setState Below
    this.setState({
      old: false, tutorial: null, evaluation_stage: null, temp_report_id: null, temp_patient_id: null,
      joint_id: null, activePriority: null, isTutorialCompleted: 0, Evaluations: [], Eval: [], Xrays: [],
      loggedIn: false, token: null, type: null, user_id: null, user_email: null, user_type: null, organization: null,
    });
    this.setCookie("token", "", 0); this.setCookie("type", "", 0); this.setCookie("user_id", "", 0);
    this.setCookie("user_email", "", 0); this.setCookie("user_type_id", "", 0); this.setCookie("user_type_name", "", 0);
    this.setCookie("isTutorialCompleted", "", 0); this.setCookie("organization", "", 0); this.setCookie("tutorial", "", 0);
    this.setCookie("oldEvaluations", "", 0); this.setCookie("temp_report_id", "", 0); this.setCookie("temp_patient_id", "", 0);

    history.push("/login");
  };

  createSession = (session) => {

    let {token,type,user_id,user_email,user_type_id,user_type_name,isTutorialCompleted,organization,oldEvaluations,isOldEvaluations,tutorials,user_mode} = session;

    this.multipleUpdateValue([
      {key:'user_mode',value:user_mode || null},
      {key:'token',value:token || null},
      {key:'type',value:type || null},
      {key:'user_id',value:user_id || null},
      {key:'user_email',value:user_email || null},
      {key:'user_type_name',value:user_type_name || null},
      {key:'user_type_id',value:user_type_id || null},
      {key:'isTutorialCompleted',value:isTutorialCompleted || null},
      {key:'organization',value:organization},
      {key:'loggedIn',value:true},
      {key: "oldEvaluations", value: oldEvaluations },
      {key: "isOldEvaluations", value: isOldEvaluations },
      {key: "tutorials", value: tutorials },
    ]);

  }

  updateSession = () => {

    for (var obj in sessionVariables) {
      // console.log(obj);
      sessionVariables[obj] = this.setCookie(obj,this.state[obj],30)
    }

    let oldEvaluations = [];
    this.setCookie("token", this.state.token, 30);
    this.setCookie("type", this.state.type, 30);
    this.setCookie("user_id", this.state.user_id, 30);
    this.setCookie("user_email", this.state.user_email, 30);
    this.setCookie("user_type_id", this.state.user_type_id, 30);
    this.setCookie("user_type_name", this.state.user_type_name, 30);
    this.setCookie("isTutorialCompleted", this.state.isTutorialCompleted, 30);
    this.setCookie("isOldEvaluations", this.state.isOldEvaluations, 30);
    this.setCookie("loggedIn", this.state.loggedIn, 30);

    this.setCookie("organization", JSON.stringify(this.state.organization), 30);

    if(this.state.tutorials && this.state.tutorials.length>0)
    {
      this.setCookie("tutorials", this.state.tutorials.join('|'), 30);
    }
  };



  updateValue = (key, value) => {
    this.setState({ [key]: value }, () => { this.updateSession() });
  };

  multipleUpdateValue = (objects) => {
    let newState = {};
    for (var obj in objects) {
      newState[objects[obj].key] = objects[obj].value;
    }
    this.setState(newState, () => { this.updateSession() });
  };

  multipleUpdateValueWithHistory = (objects, url) => {
    let newState = {};
    for (var obj in objects) {
      newState[objects[obj].key] = objects[obj].value;
    }
    this.setState(newState, () => { this.updateSession() });
    history.push(url);
  };



  evalDone = () => {
    let oldEvaluations = this.state.oldEvaluations;
    if (oldEvaluations) {

      let oldEvaluationNew = oldEvaluations.filter(evalution => evalution.visitor.id.toString() !== this.state.report_id.toString());
      this.multipleUpdateValue([{ key: 'allUploadReportImages', value: true },{ key: 'oldEvaluations', value: oldEvaluationNew }, { key: 'evaluation_stage', value: 5 }])
    }
  };

  newEval = () => {
    emptyEvalState(this);
  }

  newVisitor = () =>
  {
    emptyVisitorState(this);
  }

  discardLeft = () => {
    //api  to delete old evaluation;
    this.setCookie("tutorial-" + this.state.user_id, "", 0);
    this.setState({
      old: null,
      tutorial: null,
      evaluation_stage: null,
      temp_report_id: null,
      temp_patient_id: null,
    });
  };

  render() {
    return this.state.loading === false ? (
      <Router history={history}>
        <MyContext.Provider
          value={{
            newVisitor: this.newVisitor,
            newEval: this.newEval,
            evalDone: this.evalDone,
            setCookie: this.setCookie,
            getCookie: this.getCookie,
            logout: this.Logout,
            createSession: this.createSession,
            updateSession: this.updateSession,
            multipleUpdateValueWithHistory: this.multipleUpdateValueWithHistory,
            multipleUpdateValue: this.multipleUpdateValue,
            baseUrl: baseUrl,
            state: this.state,
            updateValue: this.updateValue,
            checkGuesUserMode:this.checkGuesUserMode,
            history: history,
          }}>
          {this.state.loggedIn && this.state.loggedIn.toString() === "true" ? <Route path="/" component={Drawer} /> : null}
          <Route path="/login" component={Login} />
          <Route exact path="/" component={Login} />
          <Route path="/home" component={Home} /> {/* DEFAULT */}
          <Route path="/start-over" component={StartOver} />
          <Route path="/tutorials" component={Tutorials} />
          <Route path="/Learn-more" component={LearnMore} />
          <Route path="/edit-profile" component={EditProfile} />
          <Route path="/evaluation" component={Evaluation} /> {/* CHECKER */} {/* ROUTER */}
          <Route path="/evaluation-history" component={EvaluationHistory} />
          <Route path="/incomplete-evaluations" component={IncompleteEvaluations} />
          <Route path="/admin/create-user" component={CreateUser} />{" "}


          {/* <Route path="/recommended-care-pathway/" component={RecomCarePath} /> ROUTER */}
          {/* CHECKER */} {/* ROUTER */}
          {/* CHECKER */} {/* ROUTER */}
          {/* <Route path="/Evaluation/forms" component={Forms}/> */}
          {/* <Route path="/Evaluation/patient-report" component={PatientReport}/> */}
          {/* <Route path="/Evaluation/upload-report" component={UploadReport}/> */}
        </MyContext.Provider>
      </Router>
    ) : (
      <div>Loading</div>
    );
  }
}
export default Routes;