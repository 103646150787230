import React, { Component } from 'react';

import Introduction from './intro';
import EvalName from './evalName';
import Overview from './overview';
import Matching from './matching';
import './xrayMatching.css';

import MyContext from '../../helper/themeContext';
import { SemipolarLoading } from 'react-loadingg';
import PostData from '../../Fetch/postData4';
import { EnableNextEvaluation, findNextAvailCommonEval, MarkComplete } from '../../StoreFunctions/evaluationStoreFunctions';



let Next = false;

class XrayMatching extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Evaluations: [],
            activeId: 0,
            ActivePage: 'Intro',
            ActiveType: null,
            ActiveXray: null,
            Next: false,
            req: [],
            mountImages: false,
            startedUploading: false,
            isLoading:false
        }
    }

    

    UNSAFE_componentWillMount() {
        //for resume eval ( if all done, next button should be visible)
        let Next = false;
        let Eval = this.context.state.Eval[this.context.state.activeJointIndex];
        let ActivePage = "Intro";
        let Evaluation = this.context.state.Evaluations.find(eva => eva.joint_id.toString() === Eval.joint_id.toString())
        let LastXray = Evaluation.Xrays[Evaluation.Xrays.length - 1]
        let lastMatching = LastXray.xrays[LastXray.xrays?.length - 1].isDone
        if (lastMatching === true) {
            this.checkNextEval();
            Next = true;
        }

        else {
            //check if any one is enable, if one enable first one t0o get evaluated
            let FindisEnable = false;
            Evaluation.Xrays.forEach((Type)=>{
                Type.xrays.forEach((ev,i)=>{
                    if(!ev.isDone && ev.enable)
                    {
                        FindisEnable = true;
                    }
                })
            })

            if(!FindisEnable)  //no xray available (enabled) for evaluation
            {
                let lastDone = null;
                Evaluation.Xrays.forEach((Type,t)=>{
                    Type.xrays.forEach((ev,i)=>{
                        if(ev.isDone)
                        {
                            lastDone = {t,i}
                        }
                    })
                })

                // console.log(lastDone)

                if(lastDone && (lastDone.t > -1 && lastDone.i > -1))
                {
                    let Evt = EnableNextEvaluation(Evaluation,lastDone?.t,lastDone?.i)
                    if(Evt)
                    {
                        Evaluation = Evt
                    }
                }

                else {
                    Evaluation.Xrays[0].xrays[0].enable = true;
                }
            }

            else {
                //xray already enabled for evaluation
            }


        }

        if (this.context.state.activeJointIndex > 0) {
            ActivePage = 'Overview'
        }

        this.setState({ ActivePage, Evaluations: this.context.state.Evaluations, Next,loading:false });
    }

    async checkNextEval()
    {
        let Curr_Joint_id = this.context.state.Eval[this.context.state.activeJointIndex].joint_id;
        let UpdatedEval = await MarkComplete(this.context.state,Curr_Joint_id);
        let NewIndex = await findNextAvailCommonEval(this.context.state);
        return NewIndex;
    }


    handleIntroClick = () => {
        // this.setState({ActivePage:'EvalName'})
        // --------- Skipping Page Entry -v1 Start-------------
        this.setState({ ActivePage: 'Overview' })
        // --------- Skipping Page Entry -v1 End-------------
    }
    handleEvalClick = () => {
        this.setState({ ActivePage: 'Overview' })
    }
    handleOverviewClick = (ActiveType, ActiveXray) => {
        let Eval = this.context.state.Eval[this.context.state.activeJointIndex];
        let Evaluation = this.state.Evaluations.find(eva => eva.joint_id.toString() === Eval.joint_id.toString())
        let ActiveTypeIndex = Evaluation.Xrays.findIndex(ev => ev.name == ActiveType);
        let ActiveXrayIndex = Evaluation.Xrays[ActiveTypeIndex].xrays.findIndex(eva => eva.name == ActiveXray);
        this.setState({ ActivePage: 'Matching', ActiveType, ActiveXray, ActiveXrayIndex, ActiveTypeIndex })
    }

    handleMatchingClick = async (state, notes) => {
        let Eval = this.context.state.Eval[this.context.state.activeJointIndex];
        let Evaluation = this.state.Evaluations.find(eva => eva.joint_id.toString() === Eval.joint_id.toString())
        let type = Evaluation.Xrays.find(type => type.name === this.state.ActiveType)
        let Xray = type.xrays.find(xray => xray.name === this.state.ActiveXray)
        let XrayIndex = type.xrays.findIndex(xray => xray.name === this.state.ActiveXray)
        let typeIndex = Evaluation.Xrays.findIndex(type => type.name === this.state.ActiveType)

        Xray.state = state;
        Xray.notes = notes;
        Xray.isDone = true;


        this.setState({loading:true})
        

        let Evt = EnableNextEvaluation(Evaluation,typeIndex,XrayIndex)

        if(Evt)
        {
            Evaluation = Evt;
            let reqObject = { processed_xray_id: Xray.processed_xray_id, xray_matching_id: Xray.state, notes: Xray.notes };
            let req = [reqObject];
            let response = await PostData(this.context.baseUrl + '/api/v1/evaluate/xrays', 201, req, this.context.state.token)
            // console.log('res=>',response)
            this.setState({loading:false})
        }

        else
        {
            let reqObject = { processed_xray_id: Xray.processed_xray_id, xray_matching_id: Xray.state, notes: Xray.notes };
            let req = [reqObject];
            if (this.context.state.noOfEvalRemainToUpload > 1) {
                req.push({ updateState: false })
            }
            else {
                req.push({ updateState: true })
            }
            PostData(this.context.baseUrl + '/api/v1/evaluate/xrays', 201, req, this.context.state.token, this.setMeTwo)
            // console.log(req);
        }

        this.setState({ ActivePage: 'Overview' })
    }

    // componentDidUpdate()
    // {
    //     if(this.state.mountImages===true && this.state.startedUploading===false)
    //     {
    //         this.setState({startedUploading:true});
    //         this.handleReportUpload()
    //     }                

    // }

    setMeTwo = async (response) => {
        if (response.length > 0) {

            let NewIndex = await this.checkNextEval();

            if (NewIndex > -1) {
                // console.log('SECOND EVAL');
                Next = true;
                this.setState({ Next: true, loading: false });
            }
            else {
                // console.log(' -----------------------   EVALUATION MATCHING DONE ---------------------');
                this.setState({ Next: true, loading: false });
                Next = true;
            }
        }
        else { alert('Error! Please try again later.') }
    }
    handleNextClick = async () => {
        this.context.multipleUpdateValueWithHistory([{ key: 'Evaluations', value: this.state.Evaluations }], './report')
    }

    render() {

        let Eval = this.context.state.Eval[this.context.state.activeJointIndex];

        return (
            <div id="Evaluaion_XrayMatching_Intro_Main_Div">
                {this.state.loading === true ? <div style={{ height: '100vh', width: '100vw' }}> <SemipolarLoading size={"large"} color={'#b4ec51'} /> </div>
                    : <div>
                        {
                            this.state.ActivePage === 'Intro' && <Introduction handleClick={this.handleIntroClick} />
                        }
                        {
                            this.state.ActivePage === 'EvalName' && <EvalName eval={this.state.Evaluations.find(eva => eva.joint_id.toString() === Eval.joint_id.toString())} handleClick={this.handleEvalClick} />
                        }
                        {
                            this.state.ActivePage === 'Overview' && <Overview Next={this.state.Next} Evaluation={this.state.Evaluations.find(eva => eva.joint_id.toString() === Eval.joint_id.toString())} handleClick={(ActiveType, ActiveXray) => this.handleOverviewClick(ActiveType, ActiveXray)} handleNextClick={this.handleNextClick} />
                        }
                        {
                            this.state.ActivePage === 'Matching' && <Matching apiKey={this.state.Evaluations.apiKey} Nonce={this.state.Evaluations.Nonce} baseUrl={this.state.Evaluations.baseUrl} eval={this.state.Evaluations.find(eva => eva.joint_id.toString() === Eval.joint_id.toString())} ActiveTypeIndex={this.state.ActiveTypeIndex} ActiveXrayIndex={this.state.ActiveXrayIndex} ActiveType={this.state.ActiveType} ActiveXray={this.state.ActiveXray} handleClick={(state, notes) => this.handleMatchingClick(state, notes)} />
                        }
                    </div>
                }
            </div>
        );
    }
}
XrayMatching.contextType = MyContext;
export default XrayMatching;