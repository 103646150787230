import React, { Component } from 'react';
import MyContext from '../../helper/themeContext';
import MainImage from '../../assets/pdfImages/Noi-Treatments.jpg'

import './alltreatment.css'

const NOCOIPath = "Non Operative Candidate - Operational Injection"
// const TJRPath = "Operative Candidate - Total Joint Replacement"
// const PJRPath = "Operative Candidate - Partial Joint Replacement"
const NOCPath = "Non Operative Candidate"

class NoiTreatmentImage extends Component {
    constructor(props) {
        super(props);
        this.state = { DummyJointMapArray: [{ Path: NOCOIPath }, { Path: NOCOIPath, Name: 'RIGHT KNEE' }] }
    }

    render() {
        return (
            this.props.JointMapArray?.map((joint, id) =>
                joint.Path === NOCOIPath ?
                    <div className={`noi-treatment-wrapper${id}`}key={id} id="noi-treatment-wrapper">

                        <div className="chart-pdf-header">
                            {joint.Name}  Non-Operative Treatment Options
            </div>

                        <div className="chart-pdf-patient-header">
                            {this.context.state.patient.name}  <span className="chart-patient-header-middle"> Evaluation Date : {this.context.state.patient.date} </span>
                            <span className="chart-patient-header-right"> ID: {this.context.state.patient_id} </span>
                        </div>

                        <div>
                            <img src={MainImage} alt="Patient Info" style={{ maxWidth: '100%', maxHeight: '730px' }} />
                        </div>

                        <div className="Page1B-Number">
                            {id === 0 ? 7 : this.props.JointMapArray[0].Path === NOCPath ? 8 : this.props.JointMapArray[0].Path === NOCOIPath ? 9 : 10}
                        </div>
                    </div>
                    : null
            )
        );
    }
}

NoiTreatmentImage.contextType = MyContext;
export default NoiTreatmentImage;