import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';


//+++++++++++++++++++ Open Issues ++++++++++++++++++
//1- SessionVariables in General / Drawer.js
//2- Destruct SessionVariables into two (optional + neccessary)
//3- Transfer home routes into pages folder
//++++++++++++++++++++++++++++++++++++++++++++++++++


//+++++++++++++++++ Closed Issues ++++++++++++++++++
//1-
//++++++++++++++++++++++++++++++++++++++++++++++++++

ReactDOM.render(<Routes />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
