import { CompareArrows } from '@material-ui/icons';
import React, { Component } from 'react';
import MyContext from '../../helper/themeContext';
import CRM from './CRM';
import JointNoi1 from './JointNoi1';
import JointNoi2 from './JointNoi2';
import JointNoi3 from './JointNoi3';
import JointNoi4 from './JointNoi4';
import JointNoi5 from './JointNoi5';
import JointNoi6 from './JointNoi6';
import JointNoi7 from './JointNoi7';
import JointSummary from './JointSummary';
import JointTreatment from './JointTreatment';
import JPR from './JPR';
import LearnAboutJointRep from './learnAboutJointRep';
import LearnMoreNo from './LearnMoreNo';
import LearnMoreNoi from './LearnMoreNoi';
import NonOpTreatmentInfo from './NonOpTreatmentInfo';
import PrepareforSur from './PrepareforSur';
import Replacement from './Replacement';
import SurgeryInfo from './SurgeryInfo';
import Welcome from './Welcome';

import LearnMoreGeneral from './LearMoreGeneral';
import LearnAboutPartRecp from './LearnAboutPartRecp';
import { getAggregate, getScore, LoadPath } from '../../StoreFunctions/PRCStoreFunctions';
import { collectCommonJoints, getChartOverallByHip, getChartOverallByKnee, getCommonEval, sortEval } from '../../StoreFunctions/evaluationStoreFunctions';

const nextPageRoute = './common-joint-pdf'
class RecommendedCarePathway extends Component {
    constructor(props) {
        super(props);
        this.state = {
            NoOfJoints: 0, RemainingJointRCP: 0,
            loading: true,
             page: 0, //adnanchanges
            JointsRcp: [],
            viewReport:false
        }
    }

    handleFailure = () => {
        alert('Unable to load Patient Recommended Care Pathway')
        this.context.history.push('/home')
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let viewReport = urlParams.get("view-report");
        let page = 0;

        let Eval = this.context.state.Eval;

        if(viewReport=="true")
        {
            page=9;
        }

        let activeJointIndex = this.context.state.activeJointIndex;
        if(activeJointIndex < 0)
        {
            Eval = sortEval(Eval)
            activeJointIndex = 0;
        }


        Eval = getCommonEval(activeJointIndex,Eval)
        if (Eval) {
            if (Eval.length > 0) {
                this.setState({ loading: true, NoOfJoints: Eval.length, RemainingJointRCP: Eval.length, page, viewReport })
                this.LoadAllRCP(viewReport);
            }

            else this.handleFailure();
        }
        else this.handleFailure();

    }

    LoadAllRCP = (viewReport) => {

        let Eval = [];

        if(viewReport==="true")
        {
            Eval = sortEval(this.context.state.Eval)
        }
        else Eval = getCommonEval(this.context.state.activeJointIndex,this.context.state.Eval);
        
        Eval = sortEval(Eval);
        let MatchingEvaluations = this.context.state.Evaluations;

        let JointsRcp = [];

        for (let i = 0; i < Eval.length; i++) {

            let Compartments = [];

            let Curr_Joint_id = Eval[i].joint_id.toString();
            let Curr_Evaluation = MatchingEvaluations.find(joint => joint.joint_id.toString() === Curr_Joint_id);
            Curr_Evaluation.Xrays.forEach((comp)=>{
                let CompartmentScore = getAggregate(comp.xrays[0].state, comp.xrays[1]?.state)
                Compartments.push({name:comp.name,score:CompartmentScore,id:comp.id});
            });
            let ChartOverAll = [];
            if((Curr_Joint_id==="1") || (Curr_Joint_id==="2"))
            {
                ChartOverAll = getChartOverallByHip();
            }
            else if((Curr_Joint_id==="3") || (Curr_Joint_id==="4"))
            {
                ChartOverAll = getChartOverallByKnee();
            }
            let form = this.context.state.form;
            let Score = getScore(ChartOverAll,Curr_Joint_id,form);
            let { pathId,Replacement } = LoadPath(Score, Compartments, Curr_Joint_id);
            JointsRcp.push({ joint_id:Curr_Evaluation.joint_id, Joint_Name: Curr_Evaluation.name, Replacement, pathId, Score, Compartments })
        }

        this.setState({ JointsRcp, loading: false, RemainingJointRCP:Eval.length })
    }


    getNocPages = () => {
        switch (this.state.page) {
            case 0: return <Welcome handleBackClick={this.handleBackClick}
                handleNextClick={this.handleNextClick} handleReviewClick={this.handleReviewClick} />;
            // case 1: return <CRM handleBackClick = {this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 1: return <JointSummary Recommendation={"Non Operative Treatment"} Joint_Name={this.state.Joint_Name} Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 2: return <NonOpTreatmentInfo Joint_Name={this.state.Joint_Name} Recommendation="Surgery" Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextEval} handleLearnMore={(e) => this.handleNextClick(e, 8)} />;
            case 3: return <JointTreatment Joint_Name={this.state.Joint_Name} Recommendation="NOC" Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 4: return <JointNoi1 Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextEval} />;

            //additional cases
            /*Operative Candidate Learn More*/
            case 8: return <LearnMoreNo Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick={() => this.handleBackClick(3)} />;

            default: return <div> Unreachable step</div>;


        }
    }

    getNocOiPages = () => {

        switch (this.state.page) {
            case 0: return <Welcome handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} handleReviewClick={this.handleReviewClick} />;
            // case 1: return <CRM handleBackClick = {this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 1: return <JointSummary Recommendation={"Non Operative - Operational Injection Treatment"} Joint_Name={this.state.Joint_Name} Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 2: return <NonOpTreatmentInfo Joint_Name={this.state.Joint_Name} Recommendation="Surgery" Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextEval} handleLearnMore={(e) => this.handleNextClick(e, 8)} />;
            case 3: return <JointTreatment Joint_Name={this.state.Joint_Name} Recommendation="NOC" Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 4: return <JointNoi2 Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextEval} />;

            //additional cases
            /*Operative Candidate Learn More*/
            case 8: return <LearnMoreNoi Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick={() => this.handleBackClick(3)} />;
            default: return <div> Unreachable step</div>;
        }
    }

    getTjrPages = () => {
        switch (this.state.page) {
            case 0: return <Welcome handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} handleReviewClick={this.handleReviewClick} />;
            // case 1: return <CRM handleBackClick = {this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 1: return <JointSummary Recommendation={"Total Knee Replacement"} Joint_Name={this.state.Joint_Name} Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 2: return <SurgeryInfo Joint_Name={this.state.Joint_Name} Recommendation="Surgery" Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextEval} handleLearnMore={(e) => this.handleNextClick(e, 8)} handleLearnPrepare={(e) => this.handleNextClick(e, 9)} />;

            case 3: return <JointTreatment Joint_Name={this.state.Joint_Name} Recommendation="OC" Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 4: return <Replacement Joint_Name={this.state.Joint_Name} Recommendation="TKR" Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 5: return <JointNoi3 Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 6: return <JointNoi4 Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextEval} />;

            //additional cases
            /*Surgery Info Lear More*/
            // case 8: return <JointNoi5 Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick = {(e)=>this.handleBackClick(e,3)} />;
            case 8: return <LearnAboutJointRep Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick={(e) => this.handleBackClick(e, 3)} />;

            /*Surgery Info Prepare*/
            // case 9: return <JointNoi6 Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick = {(e)=>this.handleBackClick(e,3)}/>;
            case 9: return <PrepareforSur Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick={(e) => this.handleBackClick(e, 3)} />;

            default: return <div> Unreachable step</div>;
        }
    }

    getPjrPages = () => {
        switch (this.state.page) {
            case 0: return <Welcome handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} handleReviewClick={this.handleReviewClick} />;
            // case 1: return <CRM handleBackClick = {this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 1: return <JointSummary Recommendation={"Partial Knee Replacement"} Joint_Name={this.state.Joint_Name} Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 2: return <SurgeryInfo Joint_Name={this.state.Joint_Name} Recommendation="Surgery" Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextEval} handleLearnMore={(e) => this.handleNextClick(e, 9)} handleLearnPrepare={(e) => this.handleNextClick(e, 10)} />;
            case 3: return <JointTreatment Joint_Name={this.state.Joint_Name} Recommendation="OC" Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 4: return <Replacement Joint_Name={this.state.Joint_Name} Recommendation="PKR" Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 5: return <JPR Joint_Name={this.state.Joint_Name} Replacement={this.state.Replacement} Score={this.state.Score} Compartment1={this.state.Compartment1} Compartment2={this.state.Compartment2} Compartment3={this.state.Compartment3} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 6: return <JointNoi3 Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} />;
            case 7: return <JointNoi4 Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick={this.handleBackClick} handleNextClick={this.handleNextEval} />;

            //additional cases
            /*Surgery Info Lear More*/case 9: return <JointNoi5 Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick={(e) => this.handleBackClick(e, 3)} />;
            /*Surgery Info Prepare*/case 10: return <JointNoi6 Joint_Name={this.state.Joint_Name} Noi={[]} handleBackClick={(e) => this.handleBackClick(e, 3)} />;
            default: return <div> Unreachable step</div>;
        }
    }

    getGeneralRoutes = () => {

        switch (this.state.page) {
            case 0: return <Welcome handleBackClick={this.handleBackClick} handleNextClick={this.handleNextClick} handleReviewClick={this.handleReviewClick} />;
            case 1: return <JointSummary Joint={this.state.JointsRcp[this.state.JointsRcp.length - this.state.RemainingJointRCP]} handleBackClick={this.handleCheckBackClick} handleNextClick={this.handleNextEval} />;

            //additional cases
            /*Lear More General*/
            case 9: return <LearnMoreGeneral Joints={this.state.JointsRcp} isHandleNextDisable={this.state.viewReport} handleBackClick={(e) => this.handleBackClick(e, 1)} handleNextClick={this.handleNextEval} handleLearnMoreNoc={(e, joint) => this.handleInfo(e, joint, 10)} handleLearnMoreNocOi={(e, joint) => this.handleInfo(e, joint, 11)} handleLearnMorePar={(e, joint) => this.handleInfo(e, joint, 14)} handleLearnMoreSur={(e, joint) => this.handleInfo(e, joint, 12)} prepForSur={(e, joint) => this.handleInfo(e, joint, 13)} />;

            case 10: return <LearnMoreNo joint={this.state.Selected_Joint} Joint_Name={this.state.Selected_Joint.Joint_Name} handleBackClick={(e) => this.handleBackClick(e, 9)} />;
            case 11: return <LearnMoreNoi joint={this.state.Selected_Joint} Joint_Name={this.state.Selected_Joint.Joint_Name} handleBackClick={(e) => this.handleBackClick(e, 9)} />;
            case 12: return <LearnAboutJointRep joint={this.state.Selected_Joint} Joint_Name={this.state.Selected_Joint.Joint_Name} handleBackClick={(e) => this.handleBackClick(e, 9)} />;
            case 14: return <LearnAboutPartRecp joint={this.state.Selected_Joint} Joint_Name={this.state.Selected_Joint.Joint_Name} handleBackClick={(e) => this.handleBackClick(e, 9)} />;
            case 13: return <PrepareforSur joint={this.state.Selected_Joint} Joint_Name={this.state.Selected_Joint.Joint_Name} handleBackClick={(e) => this.handleBackClick(e, 9)} />;

        }
    }

    handleCheckBackClick = (e) => {
        if (this.state.JointsRcp.length === this.state.RemainingJointRCP) {
            this.setState({ page: 0 })
        }

        else this.setState({ RemainingJointRCP: this.state.RemainingJointRCP + 1 })
    }


    handleBackClick = (e, page = null) => {

        if(this.state.viewReport ===  "true" && this.state.page === 9)
        {
            this.context.history.push('/evaluation/complete-pdf?view-report=true')
        }

        if (page) {
            let newPage = parseInt(page);
            this.setState({ page: newPage })
            return;
        }

        if (this.state.page === 0) {
            this.context.history.push('/evaluation/report-card-summary')
            return;
        }

        else {
            let newPage = this.state.page - 1;
            this.setState({ page: newPage })
        }
    }

    handleNextClick = (e, page = null) => {
        let newPage = this.state.page + 1;

        if (page != null) {
            newPage = parseInt(page);
        }
        this.setState({ page: newPage })
    }

    handleInfo = (e, joint, newPage) => {
        this.setState({ page: newPage, Selected_Joint: joint })
    }

    handleReviewClick = () => {
        this.context.history.push('/tutorials/patient-evaluation-education')
        //go to review education page
    }

    handleNextEval = () => {
        let RemainingJointRCP = this.state.RemainingJointRCP - 1;

        if (RemainingJointRCP > 0) {
            this.setState({ loading: false, RemainingJointRCP, page: 1 })
        }

        else if (this.state.page === 9) {
            this.context.history.push(nextPageRoute)
        }

        else {
            this.setState({ page: 9 })
            // this.context.history.push('./complete-pdf')
        }
    }

    render() {
        return (
            this.state.loading === false ?
                this.getGeneralRoutes()
                :
                <div>

                </div>
        )
 
    }
}

RecommendedCarePathway.contextType = MyContext;
export default RecommendedCarePathway;