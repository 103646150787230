import React, { Component } from 'react';
import MyContext from '../../helper/themeContext';
import MainImage from '../../assets/pdfImages/Surgical_Oi.jpg'
import { getNOCOIPathId, getNOCPathId } from '../../StoreFunctions/PRCStoreFunctions';

// import './noitreatment.css'

const NOCOIPath = "Non Operative Candidate - Operational Injection"
const TJRPath = "Operative Candidate - Total Joint Replacement"
const PJRPath = "Operative Candidate - Partial Joint Replacement"
const NOCPath = "Non Operative Candidate"

var QRCode = require('qrcode.react');


class NOpCTreatment extends Component {
    constructor(props) {
        super(props);
        this.state = { DummyJointMapArray: [{ priority_id:1, pathId:getNOCOIPathId(), Path: NOCOIPath, Name: 'RIGHT KNEE' }, { priority_id:2, pathId:getNOCPathId(),Path: NOCPath, Name: 'RIGHT KNEE' }] }
    }

    render() {
        // const JointMapArray = this.state.DummyJointMapArray;
        const {isDevTesting} = this.props;
        const JointMapArray = isDevTesting ? this.state.DummyJointMapArray :  this.props.JointMapArray;

        // return (<div>
        //     AS
        // </div>)

        return (
            JointMapArray.map((joint, id) =>
                (joint.pathId === getNOCPathId() || joint.pathId === getNOCOIPathId()) ?

                    <div className={`treatment-c-wrapper${id}`} id="treatment-c-wrapper">
                        <div className="ImageReportMainDiv" >
                            {/* Page {id===0?8:JointMapArray[0].Path===NOCPath?9:JointMapArray[0].Path===NOCOIPath?10:11} */}

                            {/* <div className="pdf-page-number-header">
                    Page {id === 0 ? 7 : 9}
                            </div> */}

                            <div className="xray-report-card-header" style={{ paddingTop: '10px', height: '130px' }}>
                                <span style={{ verticalAlign: 'top', display: 'inline-block' }}>{joint.Name}{" "}<span style={{fontSize:"12px"}}> {"(Priority " + joint.priority_id  +")"} </span></span>
                                <span style={{ lineHeight: '40px', paddingLeft: '20px', display: 'inline-block', fontSize: '28px', fontWeight: '500' }}>
                                    Reducing Pain <br />
                                    Improving Joint Function <br />
                                    Slowing the Progression of Arthritis
                                </span>
                            </div>

                            <div className="chart-pdf-patient-header">
                                {this.context.state.patient.name}  <span className="chart-patient-header-middle"> Evaluation Date : {this.context.state.patient.date} </span>
                                <span className="chart-patient-header-right"> ID: {this.context.state.patient_id} </span>
                            </div>

                            {/* <div>
                    <img src={MainImage} style={{maxWidth:'100%',maxHeight:'730px'}}/>
                </div> */}

                            <div style={{ paddingTop: '20px' }}>
                                <div style={{ paddingTop: '20px' }}> {/* Top Side */}

                                    <div style={{ paddingLeft: '40px', textTransform: 'uppercase', fontWeight: '600' }}>
                                        <ul className="RCPNOI-unstyled RCPNOIcentered">
                                            {(joint.pathId === getNOCOIPathId() ?
                                                ["Home Exercise",
                                                    "Physical therapy",
                                                    "Weight Management",
                                                    "Nutritional support",
                                                    "Smoking cessation",
                                                    "Reducing your anxiety",
                                                    "Managing your discomfort",
                                                    "Complementary alternative pain treatments",
                                                    "POSSIBLE JOINT INJECTIONS"] :
                                                ["Home Exercise", "Physical therapy", "Weight Management", "Nutritional support", "Smoking cessation", "Reducing your anxiety", "Managing your discomfort", "Complementary alternative pain treatments",
                                                ]).map((text) =>
                                                    <li>
                                                        <input
                                                            style={{ borderColor: 'black', opacity: '1', width: '20px', height: '24px' }}
                                                            className="RCPNOI-styled-checkbox"
                                                            id={text}
                                                            name="non-operative-interventions"
                                                            type="checkbox"
                                                        />
                                                        <label htmlFor={text} style={{ color: 'black' }} className="RCPNOI-form-field">
                                                            {text}
                                                        </label>
                                                    </li>
                                                )}
                                        </ul>
                                    </div>
                                </div>
                                <div style={{ paddingLeft: '40px' }}>  {/* Bottom Side */}
                                    <div style={{ lineHeight: '30px', fontWeight: '600', fontSize: '23px', display: 'inline-block', verticalAlign: 'top', width: 'calc(70% - 30px)', marginRight: '30px' }}>  {/* Left Side */}
                                        {/* <div>
                                You are a candidate for surgery.
                            </div>
                            <br/>
                            <div>
                                You first step in your journey to wellness is to learn about joint replacements
                                so that you and your provider can have a shared decision on the best treatment plan for you.
                            </div>
                            <br/> */}
                                        <div>
                                            Learn more about this care options by using the QR code or goint to:
                            </div>
                                        <div>
                                            <a href="www.hipandkneesbs.org/slowingarthritis"> www.hipandkneesbs.org/slowingarthritis </a>
                                        </div>
                                    </div>
                                    <div style={{ display: 'inline-block', verticalAlign: 'top' }}> {/* Right Side */}
                                        <QRCode value="" />
                                    </div>
                                </div>

                            </div>

                            {/* <div className="Page1B-Number">
                    {id===0?8:JointMapArray[0].Path===NOCPath?9:JointMapArray[0].Path===NOCOIPath?10:11}
                </div> */}
                        </div>
                    </div>
                    : null
            ))

    }
}

NOpCTreatment.contextType = MyContext;
export default NOpCTreatment;