// JointSummary Screen...!

import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import "./SurgeryInfo.css";
import MyContext from '../../helper/themeContext';



const comparments = [
    { name: 'Normal to Slight', id: '1',color:'#B3D89B' },
    { name: 'Moderate', id: '2' ,color:'#FAF075'},
    { name: 'Near End Stage', id: '3' , color:'#F26E82'},
    { name: 'End Stage', id: '4' , color:'#F26E82'},
    {name:'Cannot Evaluate',id:'5',color:'#E4E4E4'}
]

class SurgeryInfo extends Component {
    constructor(props) {
        super(props);
        this.state = { ActiveImage:null,modal:false}
    }

    handleSearchClick = (Xray) =>
    {
        this.setState({ActiveImage:Xray,modal:true})
    }
    
    modalClose = () =>
    {
        this.setState({modal:false})
    }

    render() {
        return (
            // Main container
            <div id="JointSummary-main-box">
                <div id="all-content-wrapper">
                    <div id="JointSummary-box-1">
                        <div id="Joint_Summary_Heading_2">
                            {this.props.Joint_Name}
                        </div>
                        <div id="JointSummary-inner-box-1">
                            <p id="JointSummary-heading"> Your {this.props.Joint_Name} is a candidate for {this.props.Recommendation} </p>
                            <div id="JointSummary-row-1">
                                <Button
                                    className="SurgeryInfo-btn-1"
                                    variant="contained"
                                    onClick={this.props.handleLearnMore}
                                >
                                    {" "} Learn about joint replacement surgery {" "}
                                </Button>
                                <Button
                                    className="SurgeryInfo-btn-1"
                                    variant="contained"
                                    onClick={this.props.handleLearnPrepare}
                                >
                                    {" "} How to best prepare for surgery {" "}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div id="RCPE-Welcome-box-2">
                        <div id="RCPE-Welcome-footer-div1">
                            <Button
                                id="RCPE-Welcome-btn-1"
                                variant="contained"
                                onClick={this.props.handleBackClick}
                            >
                                {" "} Back {" "}
                            </Button>
                        </div>

                        <div id="RCPE-Welcome-footer-div3">
                            <Button
                                id="JointSummary-btn-2"
                                variant="contained"
                                onClick={this.props.handleNextClick}
                            >
                                {" "} Next {" "}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SurgeryInfo.contextType = MyContext
export default SurgeryInfo;