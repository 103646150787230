import React, { Component } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';


import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'


import BothHipImage from '../../assets/Asset 8RLHip.png'
import LeftHipImage from '../../assets/Asset 10LHip.png'
import RightHipImage from '../../assets/Asset 9RHip.png'
import BothKneeImage from '../../assets/Asset 11RLKnee.png'
import RightKneeImage from '../../assets/Asset 13RKnee.png'
import LeftKneeImage from '../../assets/Asset 14LKnee.png'



import Tick from '../../assets/button-tick.png';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import RightKneeIconRed from '../../assets/right-knee-icon-red.png'
import LeftKneeIconRed from '../../assets/left-knee-icon-red.png'
import RightHipIconRed from '../../assets/right hip pain.png'
import LeftHipIconRed from '../../assets/left hip pain.png'



import './patientProfile.css'

import MyContext from '../../helper/themeContext';
import { TextField, withStyles } from '@material-ui/core';
import GetData from '../../Fetch/getDataJson';

import ConfirmEvaluationIcon from '../../assets/confirm_eval.png'
import { currentCommonJointsArray } from '../../StoreFunctions/evaluationStoreFunctions';

const styles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
})

class PatientProfile extends Component {
    constructor(props) {
        super(props);
        this.state = { pocessedXrayLoading: false }
    }

    UNSAFE_componentWillMount() {

        // console.log('hete')

        if (!this.context.state.patient) {
            this.context.history.push('./demographics')
        }

        else if (this.context.state.UXray.toString() === "true") {
            // console.log('hete1')
            this.getProcessedXray();
        }

        // console.log('on will mount');
    }


    getProcessedXray = () => {

        let Eval = this.context.state.Eval;

        let activeJointHurtId = Eval[this.context.state.activeJointIndex].joint_hurt_id;
        let JointId = Eval[this.context.state.activeJointIndex].joint_id;


        this.setState({ pocessedXrayLoading: true })
        let req = {
            visitor_id: this.context.state.report_id,
            joint_hurt_id:activeJointHurtId,
            joint_id:JointId
        }
        GetData(this.context.baseUrl + '/api/v1/processed/xrays', 200, req, this.context.state.token, this.setMe)
    }

    setMe = (response) => {
        if (response.ResponseCode === "Success") {
            let updatedEvaluations = this.context.state.Evaluations;
            // updatedEvaluations['apiKey'] = response.apiKey;
            // updatedEvaluations['Nonce'] = response.Nonce;
            // updatedEvaluations['baseUrlMain'] = response.baseUrlMain;
            // updatedEvaluations['baseUrlRoi'] = response.baseUrlRoi;


            if (response.ProcessedXrays.length > 0) {
                // console.log('here')

                let proXrays = response.ProcessedXrays;

                let currentJoints = currentCommonJointsArray(this.context.state);
                // console.log('currentJoints',currentJoints)

                for (let evalu of this.context.state.Eval) {

                    if(! (currentJoints.includes(evalu.joint_id.toString())) )
                    {
                        continue;
                    }

                    // console.log('evalu',evalu)

                    //views
                    const FlexionIndex = 0;
                    const NonFlexionIndex = 1;
                    const PelvisIndex = 0;
                    const LateralTypeIndex = 1;

                    //types
                    const MedialIndex = 0;
                    const LateralIndex = 1;
                    const KneecapIndex = 2;
                    const Zone1Index = 0;
                    const Zone2Index = 1;


                    //knee
                    let flexMedial = proXrays.find((proXray) => proXray.joint_hurt_id.toString() === evalu.joint_hurt_id.toString() && proXray.view_id.toString() === '1' && proXray.processed_xray_type_id.toString() === '1');
                    let flexLateral = proXrays.find((proXray) => proXray.joint_hurt_id.toString() === evalu.joint_hurt_id.toString() && proXray.view_id.toString() === '1' && proXray.processed_xray_type_id.toString() === '2');
                    let nonFlexMedial = proXrays.find((proXray) => proXray.joint_hurt_id.toString() === evalu.joint_hurt_id.toString() && proXray.view_id.toString() === '2' && proXray.processed_xray_type_id.toString() === '1');
                    let nonFlexLateral = proXrays.find((proXray) => proXray.joint_hurt_id.toString() === evalu.joint_hurt_id.toString() && proXray.view_id.toString() === '2' && proXray.processed_xray_type_id.toString() === '2');
                    let Kneecap = proXrays.find((proXray) => proXray.joint_hurt_id.toString() === evalu.joint_hurt_id.toString() && proXray.view_id.toString() === '3' && proXray.processed_xray_type_id.toString() === '3');
                    
                    //hip
                    let PelvisZone1 = proXrays.find((proXray) => proXray.joint_hurt_id.toString() === evalu.joint_hurt_id.toString() && proXray.view_id.toString() === '4' && proXray.processed_xray_type_id.toString() === '5');
                    let PelvisZone2 = proXrays.find((proXray) => proXray.joint_hurt_id.toString() === evalu.joint_hurt_id.toString() && proXray.view_id.toString() === '5' && proXray.processed_xray_type_id.toString() === '5');
                    let LateralZone1 = proXrays.find((proXray) => proXray.joint_hurt_id.toString() === evalu.joint_hurt_id.toString() && proXray.view_id.toString() === '4' && proXray.processed_xray_type_id.toString() === '4');
                    let LateralZone2 = proXrays.find((proXray) => proXray.joint_hurt_id.toString() === evalu.joint_hurt_id.toString() && proXray.view_id.toString() === '5' && proXray.processed_xray_type_id.toString() === '4');

                    let updatedEvaluationsIndex = updatedEvaluations.findIndex((processed_xrays) => processed_xrays.joint_id === evalu.joint_id.toString());
                    // console.log('updatedEvaluationsIndex=> ', updatedEvaluationsIndex);

                    if(updatedEvaluationsIndex > -1)
                    {
                        // console.log('updatedEvaluations[updatedEvaluationsIndex]=> ', updatedEvaluations[updatedEvaluationsIndex]);
                        let parsingArray = [];
                        if( evalu.joint_id.toString()==="1"||evalu.joint_id.toString()==="2")
                        {
                            parsingArray = [
                                { name:'PelvisZone1', info:PelvisZone1, view:"Zone1",type:"Ap Pelvis",typeIndex:PelvisIndex,viewIndex:Zone1Index},
                                { name:'PelvisZone2',info:PelvisZone2, view:"Zone2",type:"Ap Pelvis",typeIndex:PelvisIndex,viewIndex:Zone2Index},
                                { name:'LateralZone1', info:LateralZone1, view:"Zone1",type:"Lateral",typeIndex:LateralTypeIndex,viewIndex:Zone1Index},
                                { name:'LateralZone2', info:LateralZone2, view:"Zone2",type:"Lateral",typeIndex:LateralTypeIndex,viewIndex:Zone2Index},
                            ]
                        }
                        else if(evalu.joint_id.toString()==="3"||evalu.joint_id.toString()==="4")
                        {
                            parsingArray = [
                                { name:'flexMedial', info:flexMedial, view:"flexion",type:"Medial",typeIndex:MedialIndex,viewIndex:FlexionIndex},
                                { name:'nonFlexMedial',info:nonFlexMedial, view:"NonFlexion",type:"Medial",typeIndex:MedialIndex,viewIndex:NonFlexionIndex},
                                { name:'flexLateral', info:flexLateral, view:"flexion",type:"Lateral",typeIndex:LateralIndex,viewIndex:FlexionIndex},
                                { name:'nonFlexLateral', info:nonFlexLateral, view:"NonFlexion",type:"Lateral",typeIndex:LateralIndex,viewIndex:NonFlexionIndex},
                                { name:'Kneecap',info:Kneecap, view:"none",type:"Kneecap",typeIndex:KneecapIndex,viewIndex:0},
                            ]
                        }
                        
                        parsingArray.forEach((evaluation)=>
                        {
                            updatedEvaluations[updatedEvaluationsIndex].Xrays[evaluation.typeIndex].xrays[evaluation.viewIndex].prediction = evaluation.info.prediction;
                            updatedEvaluations[updatedEvaluationsIndex].Xrays[evaluation.typeIndex].xrays[evaluation.viewIndex].image = evaluation.info.image_url;
                            updatedEvaluations[updatedEvaluationsIndex].Xrays[evaluation.typeIndex].xrays[evaluation.viewIndex]['processed_xray_id'] = evaluation.info.id;
                            updatedEvaluations[updatedEvaluationsIndex].Xrays[evaluation.typeIndex].xrays[evaluation.viewIndex]['roi_updated'] = evaluation.info.roi_updated;
                            updatedEvaluations[updatedEvaluationsIndex].Xrays[evaluation.typeIndex].xrays[evaluation.viewIndex]['roi_updated_url'] = evaluation.info.roi_updated;
                        })
                    }
                }
                this.context.multipleUpdateValue([{ key: 'Evaluations', value: updatedEvaluations }])
            }
            this.setState({ pocessedXrayLoading: false })
        }

        else {
            // console.log('failed!!! ', response)
        }
    }




    render() {
        const { classes } = this.props;
        // console.log(this.context.state);
        let skullImage = "";
        let joints = currentCommonJointsArray(this.context.state);
        if(joints.includes("1") && joints.includes("2"))
        {
            skullImage = BothHipImage;
        }
        else if(joints.includes("1"))
        {
            skullImage = RightHipImage;
        }
        else if(joints.includes("2"))
        {
            skullImage = LeftHipImage;
        }
        else if(joints.includes("3") && joints.includes("4"))
        {
            skullImage = BothKneeImage;
        }
        else if(joints.includes("3"))
        {
            skullImage = RightKneeImage;
        }
        else if(joints.includes("4"))
        {
            skullImage = LeftKneeImage;
        }

        return (
            <div id="Evaluaion_PatientProfile_Main_Div">
                {this.state.pocessedXrayLoading === true &&
                    <Backdrop className={classes.backdrop} open={true} >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }



                <div id="Evaluaion_PatientProfile_Content_Wrapper">
                    <div id="Evaluaion_PatientProfile_Content1_Wrapper">
                        <div id="Evaluaion_PatientProfile_Heading1_Div">
                            Patient Profile
                        </div>
                        <div id="Evaluaion_PatientProfile_Profile_Div">
                            <div id="Evaluaion_PatientProfile_Profile_Avatar_Div">
                                <Avatar className="Evaluaion_PatientProfile_Profile_Avatar" alt={this.context.state.patient.name} src={AccountCircleIcon} />

                            </div>
                            <div id="Evaluaion_PatientProfile_Profile_Text_Div">
                                <span className="small-paragraph"> {this.context.state.patient.name} </span>
                                <br />
                                <span className="title-small-2"> {this.context.state.patient.birth_date} </span>
                            </div>
                        </div>
                        <div id="Evaluaion_PatientProfile_Heading2_Div" className="small-paragraph">
                            Evaluation started: {this.context.state.patient.date}
                        </div>
                        <div id="Evaluaion_PatientProfile_Box1_Div">

                            <div className="Evaluaion_PatientProfile_Box1_Content1">
                                <div className="Evaluaion_PatientProfile_Box1_Content1_Image_Wrapper">
                                    <img className="Evaluaion_PatientProfile_Box1_Content1_Image" src="https://assets.website-files.com/5d8d4f718f129fc077e4b98d/5d9e0d14916a658e42a03643_Asset%202doc.png" width="20px" alt="" />
                                </div>
                            </div>
                            <div className="Evaluaion_PatientProfile_Box1_Content2">
                                {this.context.state.Pro === true ?
                                    <div>
                                        <div className="Evaluaion_PatientProfile_Box_DisbaleText"> Patient Survey </div>
                                        <div className="Evaluaion_Forms_Box_DisbaleText2"> <img src={Tick} alt="Completed" /> &nbsp; Complete </div>
                                    </div>
                                    :
                                    <Button className="Evaluaion_PatientProfile_Box_Button" variant="contained" onClick={() => { this.context.history.push('./pro') }}> Patient Survey </Button>
                                }

                            </div>

                            <div className="Evaluaion_PatientProfile_Box1_Content1">
                                <div className="Evaluaion_PatientProfile_Box1_Content1_Image_Wrapper">
                                    <img className="Evaluaion_PatientProfile_Box1_Content1_Image" src="https://assets.website-files.com/5d8d4f718f129fc077e4b98d/5d9e0e86916a654154a044e6_Asset%201doc3.png" width="20px" alt="" />
                                </div>
                            </div>
                            <div className="Evaluaion_PatientProfile_Box1_Content2">
                                {this.context.state.Pro === false ?
                                    <div className="Evaluaion_PatientProfile_Box_DisbaleText"> Upload X-rays </div>
                                    :
                                    this.context.state.UXray === true ?
                                        <div>
                                            <div className="Evaluaion_PatientProfile_Box_DisbaleText"> Upload X-rays </div>
                                            <div className="Evaluaion_Forms_Box_DisbaleText2"> <img src={Tick} alt="Completed" /> &nbsp; Complete </div>
                                        </div>
                                        :
                                        <Button className="Evaluaion_PatientProfile_Box_Button" variant="contained" onClick={() => { this.context.history.push('./upload-xrays') }}> Upload X-rays </Button>
                                }
                            </div>

                            <div className="Evaluaion_PatientProfile_Box1_Content1">
                                <div className="Evaluaion_PatientProfile_Box1_Content1_Image_Wrapper">
                                    <img className="Evaluaion_PatientProfile_Box1_Content1_Image Evaluaion_PatientProfile_Box1_Content1_Image_Custom" src={ConfirmEvaluationIcon} alt="" />
                                </div>
                            </div>
                            <div className="Evaluaion_PatientProfile_Box1_Content2">
                                {this.context.state.UXray === false ?
                                    <div className="Evaluaion_PatientProfile_Box_DisbaleText"> Confirm Evaluations </div>

                                    :
                                    this.state.pocessedXrayLoading === true

                                        ?
                                        <div className='Evaluaion_PatientProfile_Disable_Box'>
                                            <Button className="Evaluaion_PatientProfile_Box_Button Evaluaion_PatientProfile_Box_DisbaleButton" variant="contained" disabled={true}
                                                style={{ fontSize: 'calc(1rem + 10px) !important' }}>
                                                Confirm Evaluations
                                    </Button>
                                            <div className="Evaluaion_PatientProfile_Box_DisbaleText3">
                                                <Loader
                                                    type="Rings"
                                                    color="#B4EC51"
                                                    height={60}
                                                    width={60}
                                                    style={{ display: 'inline-block', verticalAlign: 'middle', opacity: '1', fontSize: '32px' }}
                                                />
                                                <span style={{ fontSize: 'calc(1rem + 5px)' }}> Processing X-rays with AI... </span>
                                            </div>
                                        </div>
                                        :
                                        <Button className="Evaluaion_PatientProfile_Box_Button" variant="contained" onClick={() => { this.context.history.push('./x-ray-matching') }}> Confirm Evaluations </Button>
                                }
                            </div>
                        </div>
                    </div>

                    <div id="Evaluaion_PatientProfile_Content2_Wrapper">

                        {joints.find(evalu => evalu === "3") ?
                            <div className="Evaluaion_PatientProfile_Image_Left_Div">

                                <div className="Evaluaion_PatientProfile_Image_Left_Inner_Down" >
                                    <div className="Evaluaion_PatientProfile_Image_Left_Inner_Up_Content1">
                                        <TextField value={"Priority: " + this.context.state.Eval.find(eva => eva.joint_id.toString() === '3').priority_id} style={{ width: '115px' }} variant="outlined" inputProps={{ className: "textbox-height" }} />

                                    </div>
                                    <div className="Evaluaion_PatientProfile_Image_Left_Inner_Up_Content2">
                                        <div style={{ color: 'white', marginTop:"10px", marginBottom: '10px', fontSize: '18px' }}>
                                            RIGHT KNEE
                                            </div>
                                        <img style={{ width: '40px', marginBottom: '20px' }} src={RightKneeIconRed} />
                                    </div>

                                </div>
                            </div>
                            : null}

                        {joints.find(evalu => evalu === "1") ?
                            <div className="Evaluaion_PatientProfile_Image_Left_Div">

                                <div className="Evaluaion_PatientProfile_Image_Left_Inner_Up" >
                                    <div className="Evaluaion_PatientProfile_Image_Left_Inner_Up_Content1">
                                        <TextField value={"Priority: " + this.context.state.Eval.find(eva => eva.joint_id.toString() === '1').priority_id} style={{ width: '115px' }} variant="outlined" inputProps={{ className: "textbox-height" }} />

                                    </div>
                                    <div className="Evaluaion_PatientProfile_Image_Left_Inner_Up_Content2">
                                        <div style={{ color: 'white', marginTop:"10px",marginBottom: '10px', fontSize: '18px' }}>
                                            RIGHT HIP
                                            </div>
                                        <img style={{ width: '40px', marginBottom: '20px' }} src={RightHipIconRed} />
                                    </div>

                                </div>
                            </div>
                            : null}


                        <div id="Evaluaion_PatientProfile_Image_Bone_Div"> <img src={skullImage} alt="SBS" id="Evaluaion_PatientProfile_Image_Bone" style={{transform: "translate(20px,120px)"}} /></div>

                        {joints.find(evalu => evalu === "4") ?
                            <div className="Evaluaion_PatientProfile_Image_Right_Div">

                                <div className="Evaluaion_PatientProfile_Image_Right_Inner_Down" >
                                    <div className="Evaluaion_PatientProfile_Image_Right_Inner_Up_Content1">
                                        <TextField value={"Priority: " + this.context.state.Eval.find(evalu => evalu.joint_id.toString() === "4").priority_id} style={{ width: '115px' }} variant="outlined" inputProps={{ className: "textbox-height" }} />
                                    </div>

                                    <div className="Evaluaion_PatientProfile_Image_Right_Inner_Up_Content2">
                                        <div style={{ color: 'white', marginBottom: '10px', marginTop:"10px", fontSize: '18px' }}>
                                            LEFT KNEE
                                            </div>
                                        <img style={{ width: '40px', marginBottom: '20px' }} src={LeftKneeIconRed} />
                                    </div>                       
                                </div>
                            </div>
                            : null}

                        {joints.find(evalu => evalu === "2") ?
                            <div className="Evaluaion_PatientProfile_Image_Right_Div">

                                <div className="Evaluaion_PatientProfile_Image_Right_Inner_Up" >
                                    <div className="Evaluaion_PatientProfile_Image_Right_Inner_Up_Content1">
                                        <TextField value={"Priority: " + this.context.state.Eval.find(evalu => evalu.joint_id.toString() === "2").priority_id} style={{ width: '115px' }} variant="outlined" inputProps={{ className: "textbox-height" }} />
                                    </div>
                                    <div className="Evaluaion_PatientProfile_Image_Right_Inner_Up_Content2">
                                        <div style={{ color: 'white', marginTop:"10px",marginBottom: '10px', fontSize: '18px' }}>
                                            LEFT HIP
                                            </div>
                                        <img style={{ width: '40px', marginBottom: '20px' }} src={LeftHipIconRed} />
                                    </div>                                 
                                </div>
                            </div>
                            : null}
                    </div>
                </div>

            </div>);
    }
}
PatientProfile.contextType = MyContext;
export default withStyles(styles)(PatientProfile);