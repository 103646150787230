// JointSummary Screen...!

import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import "./JointSummary.css";
import MyContext from '../../helper/themeContext';

import Search from '../../assets/search.png';
import ImageViewModalWithZoom from '../../components/ImageViewModal/ImageViewModalWithZoom';
import { getAggregate, getJointReplacementThreshold, getPathById, getRatingById } from '../../StoreFunctions/PRCStoreFunctions';
import { getUploadedXrayById } from '../../StoreFunctions/evaluationStoreFunctions';

class JointSummary extends Component {
    constructor(props) {
        super(props);
        this.state = { ActiveImage: null, modal: false }
    }

    handleSearchClick = (Xray) => {
        this.setState({ ActiveImage: Xray, modal: true })
    }

    modalClose = () => {
        this.setState({ modal: false })
    }

    getRecommendation = () => {
        if (this.props.Recommendation) {
            return this.props.Recommendation;
        }

        if (this.props.Joint) {
            let PathObj = getPathById(this.props.Joint.pathId);
            
            if(PathObj)
            {
                return PathObj.label2
            }
            else return "Unable to get Recommendation"
        }

        return "Unable to get Recommendation";
    }

    render() {
        const { Joint_Name, joint_id, Compartments, Score } = this.props.Joint;

        // console.log(Compartments);
        // console.log(Joint_Name);
        // console.log(joint_id);
        // console.log(Score);


        return (
            // Main container
            <div id="JointSummary-main-box">
                <div id="all-content-wrapper">
                    <div id="JointSummary-box-1">
                        <div id="Joint_Summary_Heading_2">
                            {Joint_Name} Recommendation
                        </div>

                        <div id="JointSummary-inner-box-1">

                            <p id="JointSummary-heading">
                                {/* {`SBS Recommendation:`} */}
                                <span id="JointSummary-recommendation-div"> {this.getRecommendation()} </span>
                            </p>

                            <div id="JointSummary-row-1">
                                {
                                    (joint_id.toString()==="3"||joint_id.toString()==="4")?
                                    Compartments.map((comp,id)=>
                                    
                                        <div style={{ marginLeft: id===0?"25px":"" }}>
                                            <h3 id="JointSummary-part-1-heading"> {comp.name} </h3>
                                        
                                            <div id="JointSummary-part-1" 
                                                style={{ background: getRatingById(comp.score.toString()).color }} >
                                                {getRatingById(comp.score.toString()).name}
                                            </div>
                                        </div>
                                    ):
                                    (
                                        <div style={{ marginLeft:"25px"}}>
                                            <h3 id="JointSummary-part-1-heading"> Xray </h3>
                                        
                                            <div id="JointSummary-part-1" 
                                                style={{ background: getRatingById(getAggregate(Compartments[0].score,Compartments[1].score).toString()).color }} >
                                                {getRatingById(getAggregate(Compartments[0].score,Compartments[1].score).toString()).name}
                                            </div>
                                        </div>
                                    )
                                }

                                <div id="JointSummary-part-last-box">
                                    <h3 id="JointSummary-part-2-heading">  Survey </h3>
                                    <div id="JointSummary-part-4" style={{ backgroundColor: Score < getJointReplacementThreshold() ? '#F26E82' : '#B3D89B' }}>
                                        {Score}
                                    </div>
                                </div>
                            </div>

                            {/* Row: 2 */}
                            <div id="JointSummary-row-1" style={{ borderTop: "2px solid white", paddingTop: "20px" }}>

                                {
                                    getUploadedXrayById(joint_id,this.context).map((xray, i) =>
                                        <div key={i} style={{
                                            display: 'inline-block', marginLeft: '10px', padding: '0px 00px 0px 0px',
                                            position: 'relative', maxWidth: '25%', background: 'rgb(0,0,0,0.5)'
                                        }}  >
                                            <img src={xray.image} alt="Xray" style={{ maxHeight: '125px', height: 'auto', width: 'auto', maxWidth: '100%' }} />
                                            <div className="Joint_Summary_Box_Search_Box" onClick={() => { this.handleSearchClick(xray.image) }}>
                                                <img src={Search} alt="Search" style={{ width: '13px' }} />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <ImageViewModalWithZoom modalState={this.state.modal} modalClose={this.modalClose} ActiveImage={this.state.ActiveImage} />

                    </div>

                    <div id="RCPE-Welcome-box-2">
                        <div id="RCPE-Welcome-footer-div1">
                            <Button
                                id="RCPE-Welcome-btn-1"
                                variant="contained"
                                onClick={this.props.handleBackClick}
                            >
                                {" "} Back {" "}
                            </Button>
                        </div>

                        <div id="RCPE-Welcome-footer-div3">
                            <Button
                                id="JointSummary-btn-2"
                                variant="contained"
                                onClick={this.props.handleNextClick}
                            >
                                {" "} Next {" "}
                            </Button>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

JointSummary.contextType = MyContext
export default JointSummary;