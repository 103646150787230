import React, { Component } from "react";

import "./evaluation.css";
import MyContext from "../helper/themeContext";

import { Route, Router } from "react-router-dom";
import Welcome from "./welcome/welcome";
import PatientDemoGraphics from "./patientDemographics/patientDemoGraphics";
import NewEvaluation from "./newEvaluation/newEvaluation";
import PatientProfile from "./patientProfile/patientProfile";
import UploadXray from "./uploadXray/uploadXray";
import XrayMatching from "./xrayMatching/xrayMatching";
import Report from "./report/report";
import RandomPdf from "./randomPdf/pdf";

import ResumeEvaluation from "./resumeEvaluation/resumeEvaluation";
import ResumeEvaluationSelect from "./resumeEvaluation/resumeEvalutionSelect";
import Pdf from "./pdf/pdf";
import ReportCardSummary from "./reportCardSummary/reportCardSummary";
import RequiredReminder from "./requiredReminder/requiredReminder";
import ChartJs from "./chart/newChart";
import FormType from "./PRO/ProEntry/formType";
import PatientReportManual from "./PRO/manual/patientReportManual";
import NewOrExis from "./newOrExis/NewOrExis";
import MedentIntro from "./medent/medentIntro";
import MedentForm from "./medent/medentForm";
import PRO from "./PRO/ProWrapper";
import PatientSummary from "./patientSummary/patientSummary";
import RecommendedCarePathway from "./recommendedCarePathway/recommendedCarePathway";
import CompletePdf from "./completePdf/completePdf";

import UploadReportImages from "./uploadReportImages/uploadReportImages";
import ReportFeedback from "./report-feedback/ReportFeedback";
import ChartShowMulti from "../components/chartShow/chartShowMulti";


//check Pdf Images
import ReportPage1 from "./pdfImages/reportPage1";  //patient summary - intro page
import ReportPage1B from "./pdfImages/page-1b"; //complete pdf - intro page
import ChartImage from "./pdfImages/chartImage"; //chart image

import GetImage from "./pdfImages/getImage";  //patient information

import ReportCard from "./pdfImages/NewReportCard"; //xray-report card
import XrayImages from "./pdfImages/xrayImages"; 

//rcp

import NoiTreatmentImage from "./pdfImages/NoiTreatmentImage";
import JointNoi3 from "./recommendedCarePathway/JointNoi3";
import JointRcpImage from "./pdfImages/JointRcpImage";
import OpCTreatment from "./pdfImages/OpCTreatment";
import NOpCTreatment from "./pdfImages/NOpCTreatment";
import SingleJointPdf from "./singleJointPdf";
import CurrentEvaluationJoints from "./currentEvaluationJoints";
import PatientSummariesDownload from "./PatientSummariesDownload";

//Ind components



class Evaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div id="Evaluation_Main_Div">
        <div>
          {this.context.history.location.pathname !== "/evaluation/resume-evaluation" &&
            this.context.history.location.pathname !== "/evaluation/resume-evaluation/recover" &&
            this.context.history.location.pathname !== "/evaluation/Video" &&
            this.context.history.location.pathname !== "/evaluation/Demographics" &&
            this.context.history.location.pathname !== "/evaluation/welcome" &&
            this.context.history.location.pathname !== "/evaluation/demographics" &&
            this.context.history.location.pathname !== "/evaluation/new-or-existing" &&
            this.context.history.location.pathname !== "/evaluation/required-info" &&
            this.context.history.location.pathname !== "/evaluation" ? (
            this.context.state.patient ? (
              <div id="Evaluation_Main_Div_Top_Name">{this.context.state.patient.name}</div>
            ) : null
          ) : null}
          {/* {console.log(this.context.state.report_id) } */}
          <Router history={this.context.history}>
            <Route exact path="/evaluation/resume-evaluation" component={ResumeEvaluation} />
            <Route exact path="/evaluation/resume-evaluation/recover" component={ResumeEvaluationSelect} />

            <Route exact path="/evaluation/welcome" component={Welcome} />
            <Route exact path="/evaluation/new-or-existing" component={NewOrExis} />
            <Route exact path="/evaluation/required-info" component={RequiredReminder} />

            <Route exact path="/Evaluation/Demographics" component={PatientDemoGraphics} />
            <Route exact path="/Evaluation/new-evaluation" component={NewEvaluation} />

            <Route exact path="/Evaluation/current-evaluation-joints" component={CurrentEvaluationJoints} />


            <Route exact path="/Evaluation/medent-welcome" component={MedentIntro} />
            <Route exact path="/Evaluation/medent-form" component={MedentForm} />


            <Route exact path="/Evaluation/patient-profile" component={PatientProfile} />

            <Route exact path="/Evaluation/pro" component={PRO} />


            <Route path="/Evaluation/form-type" component={FormType} />

            <Route path="/Evaluation/manual-form" component={PatientReportManual} />


            <Route path="/Evaluation/upload-xrays" component={UploadXray} />
            <Route path="/Evaluation/x-ray-matching" component={XrayMatching} />

            <Route path="/Evaluation/report-card-summary" component={ReportCardSummary} />
            <Route path="/Evaluation/report" component={Report} />

            <Route path="/Evaluation/chart" component={ChartJs} />

            <Route path="/Evaluation/patient-summary" component={PatientSummary} />
            <Route path="/Evaluation/recommended-care-pathway" component={RecommendedCarePathway} />

            <Route path="/Evaluation/common-joint-pdf" component={SingleJointPdf} />
            <Route path="/Evaluation/complete-pdf" component={CompletePdf} />

            <Route path="/Evaluation/patient-summary-download" component={PatientSummariesDownload} />

            <Route path="/Evaluation/report-feedback" component={ReportFeedback} />


            <Route path="/Evaluation/selected-patient-report"
              component={RandomPdf} />

            <Route path="/Evaluation/upload-report-images" component={UploadReportImages} />






            {/* DEV TESTING PDF */}

            <Route path="/evaluation/dev-testing-pdf">
              <ReportPage1 isDevTesting={true} />
            </Route>
            <Route path="/Evaluation/dev-testing-pdf">
              <ReportPage1B isDevTesting={true} />
            </Route>
            <Route path="/Evaluation/dev-testing-pdf">
              <ChartImage isDevTesting={true} />
            </Route>
            <Route path="/Evaluation/dev-testing-pdf">
              <ReportCard isDevTesting={true} />
            </Route>

            <Route path="/Evaluation/dev-testing-pdf">
              <NOpCTreatment isDevTesting={true} />
            </Route>


            
            <Route path="/Evaluation/dev-testing-pdf">
              <XrayImages isDevTesting={true} />
            </Route>

          

            





            
            {/* DEV TESTING PDF - OLD ROUTES V4 */}

            {/* <Route  component={ReportPage1} isDevTesting={true}/> */}
            {/* <Route path="/Evaluation/chart-report-image" component={ReportPage1B} isDevTesting/> */}


            {/* <Route path="/Evaluation/chart-report-image" component={ReportFeedback} /> */}
            {/* <Route path="/Evaluation/chart-report-image" component={ChartImage} /> */}
            {/* <Route path="/Evaluation/chart-report-image" component={ReportCard} /> */}
            {/* <Route path="/Evaluation/chart-report-image" component={XrayImages} /> */}
            

            {/* <Route path="/Evaluation/chart-report-image" component={GetImage} /> */}
            {/* <Route path="/Evaluation/chart-report-image" component={NoiTreatmentImage} /> */}

            
            {/* <Route path="/Evaluation/chart-report-image" component={OpCTreatment} /> */}

            {/* <Route path="/Evaluation/chart-report-image" component={JointRcpImage} /> */}
            {/* <Route path="/Evaluation/chart-report-image" component={ChartShowMulti} /> */}
          </Router>
        </div>

      </div>
    );
  }
}
Evaluation.contextType = MyContext;
export default Evaluation;
