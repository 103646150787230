import React, { Component } from 'react';
import MyContext from '../../helper/themeContext';
import MainImage from '../../assets/pdfImages/Expected_Outcomes.jpg'

// import './noitreatment.css'

const NOCOIPath="Non Operative Candidate - Operational Injection"
const TJRPath="Operative Candidate - Total Joint Replacement"
const PJRPath="Operative Candidate - Partial Joint Replacement"
const NOCPath="Non Operative Candidate"

class TreatmentAImage extends Component {
    constructor(props) {
        super(props);
        this.state = { DummyJointMapArray:[{Path:NOCOIPath},{Path:NOCOIPath,Name:'RIGHT KNEE'}] }
    }
    
    render() { 
        return ( 
        this.props.JointMapArray.map((joint,id)=>
        (joint.Path===PJRPath || joint.Path===TJRPath) ?
        <div className={`treatment-a-wrapper${id}`} id="treatment-a-wrapper">

            <div className="chart-pdf-header">
                {joint.Name}  Expected Outcomes After Surgery
            </div>

            <div className="chart-pdf-patient-header">
                {this.context.state.patient.name}  <span className="chart-patient-header-middle"> Evaluation Date : {this.context.state.patient.date} </span>
                <span className="chart-patient-header-right"> ID: {this.context.state.patient_id} </span>
            </div>
        
            <div>
                <img alt="Treatment" src={MainImage} style={{maxWidth:'100%',maxHeight:'730px'}}/>
            </div>

            <div className="Page1B-Number">
                {id===0?7:this.props.JointMapArray[0].Path===NOCPath?8:this.props.JointMapArray[0].Path===NOCOIPath?9:10}
            </div>
        </div>
        :null
        )
    );
    }
}
 
TreatmentAImage.contextType=MyContext;
export default TreatmentAImage;