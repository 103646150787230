import React, { Component } from "react";

import Button from "@material-ui/core/Button";

import MyContext from "../../helper/themeContext";

import Bone2Image from "../../assets/bone1_Bitmap.png";

import "./welcome.css";

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="Evaluaion_Welcome_Main_Div">
        <div id="Evaluaion_Welcome_Text_Wrapper">
          <div id="Evaluaion_Welcome_Heading1_Div">
            Hip & Knee <br />
            Step by Step
          </div>
          <div id="Evaluaion_Welcome_Neon_Line" style={{ marginBottom: '10px' }}></div>
          <div id="Evaluaion_Welcome_Heading2_Div">
            Patient Evaluation and Diagnosis
          </div>
          <div id="Evaluaion_Welcome_Text_Div">
            {/* Start a patient evaluation by inputting the patient’s demographics,
            joints that hurt, patient reported outcome (PRO) and X-rays. */}
            <div className="heading" style={{ fontSize: '20px', paddingBottom: '10px', lineHeight: '1.5' }}> The following information is required to complete this evaluation </div>

            <ul className="Welcome_listing">
              <li> Patient Demographics </li>
              {/* <li> Knee: Clinical Evaluation Information </li> */}
              <li> Patient Reported Surveys </li>
              <li> X-rays </li>
            </ul>
          </div>
          <div id="Evaluaion_Welcome_Next_Button_Div">
            <Button
              id="Evaluaion_Welcome_Next_Button"
              variant="contained"
              onClick={() => {
                this.context.history.push("./new-or-existing");
              }}
            >
              {" "}
              Next{" "}
            </Button>
          </div>
        </div>
        <div id="Evaluaion_Welcome_Image_div">
          <img src={Bone2Image} alt="SBS" id="Evaluaion_Welcome_Image_Bone" />
        </div>
      </div>
    );
  }
}
Welcome.contextType = MyContext;
export default Welcome;
