import React, { Component } from 'react';
import MyContext from '../../../helper/themeContext';

import './patientReport.css';
import Page1 from './rightPages/page1';
import Page2 from './rightPages/page2';
import Page3 from './rightPages/page3';
import PostData from '../../../Fetch/postData2';
import { SemipolarLoading } from 'react-loadingg';
import { Button } from '@material-ui/core'
import Page1Hip from './hip/page1';
import Page2Hip from './hip/page2';
// import RightIntroPage from './rightPages/rightIntroPage';
// import LeftIntroPage from './leftPages/introPage';
import ManualFormWrapper from './ManualFormWrapper';
import ChartShow from '../../../components/chartShow/chartShow';
import ChartShowMulti from '../../../components/chartShow/chartShowMulti';
import { collectCommonJoints, completeStage, currentCommonJointsArray, getJointMapObject, getProStageId } from '../../../StoreFunctions/evaluationStoreFunctions';


class PatientReportManual extends Component {
    constructor(props) {
        super(props);
        this.state = { page: 0, form: null, loading: true, MultiChart: false, AllJoints: [] }
    }

    UNSAFE_componentWillMount() {

        //Decide Forms to Load

        //If priority 1 (top) is hip then all hip form else if priority 1 (top) is knee then load all knee forms...

        let Eval = this.context.state.Eval;
        let activeJointIndex = this.context.state.activeJointIndex;
        let activeJoint =  (Eval[activeJointIndex].joint_id).toString()
        let currentFormJoints = [];
        if(activeJoint)
        {
            currentFormJoints = collectCommonJoints(activeJoint,Eval)   
        }
        this.setState({
            totalLeft: this.context.state.Eval.length,
            activeJointId: null,
            currentFormJoints,
        })
    }

    componentDidMount() {
        // console.log(this.context.state.form)
        if (this.context.state.form) {
            this.setState({ form: this.context.state.form, loading: false })
        }
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    changeAnswer(state, value) {
        let form = this.state.form;
        form.find((question) => question.name == state && question.joint_id.toString() === this.state.activeJointId.toString()).pro_severity_id = value;
        form.find((question) => question.name == state && question.joint_id.toString() === this.state.activeJointId.toString()).visitor_id = this.context.state.report_id;
    }

    setChartInfoandChangePage() {

        let Intervals = getJointMapObject(this.context,this.state.activeJointId);
        let JointMapObject = { Intervals:Intervals, joint_id: this.state.activeJointId }
        let AllJoints = this.state.AllJoints;
        AllJoints.push(JointMapObject)
        this.setState({ page: this.state.page + 1, JointMapObject, AllJoints });
    }

    handlePageChange = (forceDone = false) => {
        // if(this.state.page===3)
        // {
        //     this.setChartInfoandChangePage();
        // }

        // --------- Skipping Page Entry -v1 Start-------------
        if ( (this.state.page === 2) || forceDone === true) {
            this.setChartInfoandChangePage();
        }
        // --------- Skipping Page Entry -v1 End-------------

        else this.setState({ page: this.state.page + 1 })
    }


    handleShowMultiChart = () => {
        this.setState({ MultiChart: true })
    }

    getPageKnee = () => {
        switch (this.state.page) {
            // case 0: return <RightIntroPage handleBack = {this.handleBack0} handlePageChange={this.handlePageChange} />;
            // case 1: return <Page1 handleBack = {this.handleBack1} Answer1={this.state.form.find((question)=>question.name=="Question1" && question.joint_id.toString()===this.state.activeJointId.toString()).pro_severity_id} handlePageChange={this.handlePageChange} changeAnswer={(state,value)=>this.changeAnswer(state,value)} />;
            // case 2: return <Page2 handleBack = {this.handleBack2} Answer2={this.state.form.find((question)=>question.name=="Question2" && question.joint_id.toString()===this.state.activeJointId.toString()).pro_severity_id} Answer3={this.state.form.find((question)=>question.name=="Question3" && question.joint_id.toString()==this.state.activeJointId.toString()).pro_severity_id} Answer4={this.state.form.find((question)=>question.name=="Question4" && question.joint_id.toString()==this.state.activeJointId.toString()).pro_severity_id} Answer5={this.state.form.find((question)=>question.name=="Question5" && question.joint_id.toString()==this.state.activeJointId.toString()).pro_severity_id} handlePageChange={this.handlePageChange} changeAnswer={(state,value)=>this.changeAnswer(state,value)}/>;
            // case 3: return <Page3 handleBack = {this.handleBack3} Answer6={this.state.form.find((question)=>question.name=="Question6" && question.joint_id.toString()===this.state.activeJointId.toString()).pro_severity_id} Answer7={this.state.form.find((question)=>question.name=="Question7" && question.joint_id.toString()==this.state.activeJointId.toString()).pro_severity_id} handlePageChange={this.handlePageChange} changeAnswer={(state,value)=>this.changeAnswer(state,value)}/>;
            // case 4: return <ChartShow  ButtonText = {"NEXT"} JointMapObject={ this.state.JointMapObject } next={this.next}/>;

            case 0: return <Page1 activeJointId={this.state.activeJointId.toString()} handleBack={this.handleBack0} Answer1={this.state.form.find((question) => question.name == "Question1" && question.joint_id.toString() === this.state.activeJointId.toString()).pro_severity_id} handlePageChange={this.handlePageChange} changeAnswer={(state, value) => this.changeAnswer(state, value)} />;
            case 1: return <Page2 activeJointId={this.state.activeJointId.toString()} handleBack={this.handleBackGeneral} Answer2={this.state.form.find((question) => question.name == "Question2" && question.joint_id.toString() === this.state.activeJointId.toString()).pro_severity_id} Answer3={this.state.form.find((question) => question.name == "Question3" && question.joint_id.toString() == this.state.activeJointId.toString()).pro_severity_id} Answer4={this.state.form.find((question) => question.name == "Question4" && question.joint_id.toString() == this.state.activeJointId.toString()).pro_severity_id} Answer5={this.state.form.find((question) => question.name == "Question5" && question.joint_id.toString() == this.state.activeJointId.toString()).pro_severity_id} handlePageChange={this.handlePageChange} changeAnswer={(state, value) => this.changeAnswer(state, value)} />;
            case 2: return <Page3 activeJointId={this.state.activeJointId.toString()} handleBack={this.handleBackGeneral} Answer6={this.state.form.find((question) => question.name == "Question6" && question.joint_id.toString() === this.state.activeJointId.toString()).pro_severity_id} Answer7={this.state.form.find((question) => question.name == "Question7" && question.joint_id.toString() == this.state.activeJointId.toString()).pro_severity_id} handlePageChange={this.handlePageChange} changeAnswer={(state, value) => this.changeAnswer(state, value)} />;
            case 3: return <ChartShow ButtonText={"NEXT"} JointMapObject={this.state.JointMapObject} next={this.next} />;
            default: return <div> Unreachable step</div>;
        }
    }

    getPageHip = () => {
        switch (this.state.page) {
            // case 0: return <LeftIntroPage handleBack = {this.handleBack0} handlePageChange={this.handlePageChange} />;
            // case 1: return <Page1Left handleBack = {this.handleBack1} Answer1={this.state.form.find((question)=>question.name=="Question1" && question.joint_id.toString()===this.state.activeJointId.toString()).pro_severity_id} handlePageChange={this.handlePageChange} changeAnswer={(state,value)=>this.changeAnswer(state,value)} />;
            // case 2: return <Page2Left handleBack = {this.handleBack2} Answer2={this.state.form.find((question)=>question.name=="Question2" && question.joint_id.toString()===this.state.activeJointId.toString()).pro_severity_id} Answer3={this.state.form.find((question)=>question.name=="Question3" && question.joint_id.toString()==this.state.activeJointId.toString()).pro_severity_id} Answer4={this.state.form.find((question)=>question.name=="Question4" && question.joint_id.toString()==this.state.activeJointId.toString()).pro_severity_id} Answer5={this.state.form.find((question)=>question.name=="Question5" && question.joint_id.toString()==this.state.activeJointId.toString()).pro_severity_id} handlePageChange={this.handlePageChange}  changeAnswer={(state,value)=>this.changeAnswer(state,value)}/>;
            // case 3: return <Page3Left handleBack = {this.handleBack3} Answer6={this.state.form.find((question)=>question.name=="Question6" && question.joint_id.toString()===this.state.activeJointId.toString()).pro_severity_id} Answer7={this.state.form.find((question)=>question.name=="Question7" && question.joint_id.toString()==this.state.activeJointId.toString()).pro_severity_id} handlePageChange={this.handlePageChange}  changeAnswer={(state,value)=>this.changeAnswer(state,value)}/>;
            // case 4: return <ChartShow ButtonText = {"NEXT"} JointMapObject={ this.state.JointMapObject } next={this.next}/>;

            case 0: return <Page1Hip activeJointId={this.state.activeJointId.toString()} handleBack={this.handleBack0} Answer1={this.state.form.find((question) => question.name == "Question1" && question.joint_id.toString() === this.state.activeJointId.toString()).pro_severity_id} handlePageChange={this.handlePageChange} Answer2={this.state.form.find((question) => question.name == "Question2" && question.joint_id.toString() === this.state.activeJointId.toString()).pro_severity_id} changeAnswer={(state, value) => this.changeAnswer(state, value)} />;
            case 1: return <Page2Hip activeJointId={this.state.activeJointId.toString()} handleBack={this.handleBackGeneral} Answer6={this.state.form.find((question) => question.name == "Question6" && question.joint_id.toString() === this.state.activeJointId.toString()).pro_severity_id} Answer3={this.state.form.find((question) => question.name == "Question3" && question.joint_id.toString() == this.state.activeJointId.toString()).pro_severity_id} Answer4={this.state.form.find((question) => question.name == "Question4" && question.joint_id.toString() == this.state.activeJointId.toString()).pro_severity_id} Answer5={this.state.form.find((question) => question.name == "Question5" && question.joint_id.toString() == this.state.activeJointId.toString()).pro_severity_id} handlePageChange={()=>this.handlePageChange(true)} changeAnswer={(state, value) => this.changeAnswer(state, value)} />;
            // case 2: return <Page3 activeJointId={this.state.activeJointId.toString()} handleBack={this.handleBackGeneral} Answer6={this.state.form.find((question) => question.name == "Question6" && question.joint_id.toString() === this.state.activeJointId.toString()).pro_severity_id} Answer7={this.state.form.find((question) => question.name == "Question7" && question.joint_id.toString() == this.state.activeJointId.toString()).pro_severity_id} handlePageChange={this.handlePageChange} changeAnswer={(state, value) => this.changeAnswer(state, value)} />;
            case 2: return <ChartShow ButtonText={"NEXT"} JointMapObject={this.state.JointMapObject} next={this.next} />;
            default: return <div> Unreachable step</div>;

        }
    }

    handleBackGeneral = () => {
        this.setState({ page: this.state.page - 1 })
    }

    handleBack0 = () => {
        this.setState({ activeJointId: null })
    }

    handleBack1 = () => {
        this.setState({ page: 0 })
    }

    handleBack2 = () => {
        this.setState({ page: 1 })
    }

    handleBack3 = () => {
        this.setState({ page: 2 })
    }


    next = () => {
        let currentFormJoints = this.state.currentFormJoints;
        if(this.state.activeJointId != null)
        {
            let AllEval = this.context.state.Eval;
            currentFormJoints.find((cf)=>cf.joint_id===this.state.activeJointId).isDone = true;
            let currentEval = AllEval[this.context.state.activeJointIndex];
            this.setState({activeJointId:null,currentFormJoints,page:0});

            //check if any form left
            if(!currentFormJoints.find((cf)=>cf.isDone === false))  //if no form left
            {
                if (parseInt(currentEval.stage_id) > 2) {

                    this.context.multipleUpdateValue([{ key: 'Pro', value: true }]); //it enables next button navigation functionality
                    this.setState({ active: null })
                }   
                else {
                    this.setState({ loading: true })
                    // let currentCommonJoints = currentCommonJointsArray(this.context.state);

                    let currentJoints = currentCommonJointsArray(this.context.state);
                    let currentForm = this.state.form.filter((ques)=>currentJoints.includes(ques.joint_id.toString()))
                    // console.log('currentForm',currentForm);
                    PostData(this.context.baseUrl + '/api/v1/submit/answers', 201, currentForm, this.context.state.token, this.setMe)
                }
            }
        }

        else if (this.state.activeJointId == null) {

            let activeJointId=null;
            let activePoint = currentFormJoints.find((cf)=>cf.isDone===false);
            if(activePoint)
            {
                activeJointId = activePoint.joint_id;
                this.setState({ page: 0, activeJointId })
            }

            else if (parseInt(this.context.state.evaluation_stage) > 2) {
                this.context.multipleUpdateValueWithHistory([{key:'Pro',value:true}])
                //it enables next button navigation functionality
                this.setState({ activeJointId: null })
            }
    
            else {
                this.setState({ loading: true })
                PostData(this.context.baseUrl + '/api/v1/submit/answers', 201, this.state.form, this.context.state.token, this.setMe)
            }
        }
    }

    handleDone = () => {
        this.context.history.push('./patient-profile')
    }

    setMe = (response) => {
        if (response.length > 0) {

            let Eval = completeStage(this.context.state,getProStageId);
            this.context.multipleUpdateValue([{ key: 'Pro', value: true }, { key: 'Eval', value: Eval }, { key: 'form', value: this.state.form }]);
            this.setState({ loading: false, activeJointId: null })
        }
    }

    setActive = (id) => {
        this.setState({ activeJointId: id })
    }

    render() {
        return (
            //if this.state.form does not exist then loader
            <div id="Evaluaion_PatientReport_Main_Div">
                {this.state.loading == true ?
                    <SemipolarLoading size={'large'} color={'#b4ec51'} />

                    : this.state.MultiChart === true ? <ChartShowMulti ButtonText={"NEXT"} JointMapArray={this.state.AllJoints} next={this.handleDone} />
                        : this.state.activeJointId == null ? <ManualFormWrapper handleShowMultiChart={this.handleShowMultiChart}
                            currentFormJoints={this.state.currentFormJoints}
                            next={this.next} setActive={(id) => this.setActive(id)} />
                            :
                            (this.state.activeJointId.toString() === '3' || this.state.activeJointId.toString() === '4') ?
                                this.getPageKnee() :
                                this.getPageHip()
                }
               
            </div>
        );
    }
}
PatientReportManual.contextType = MyContext;
export default PatientReportManual;