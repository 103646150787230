import React, { Component } from 'react';

import MyContext from '../../helper/themeContext';
// import firebase from '../../helper/firebase';

import './uploadXray.css';

import Xray1 from '../../assets/uploadBoxThumb/xray1.jpg';
import Xray2 from '../../assets/uploadBoxThumb/xray2.jpg';
import Xray3 from '../../assets/uploadBoxThumb/xray3.jpg';
import Xray4Left from '../../assets/uploadBoxThumb/xray4Left.jpg';
import Xray4Right from '../../assets/uploadBoxThumb/xray4Right.jpg';
import UNIKnee from "../../assets/uploadBoxThumb/unilateral_knee.jpg";


import Acknowledge from './acknowledge';
import OverviewBox from './overview';
import UploadBox from './upload';
import ExportScript from './exportScript';

import { SemipolarLoading } from 'react-loadingg';
// import PostData from '../../Fetch/postData3';
import PostFormData from './uploadPostForm';
import UploadType from './uploadType';
import UploadTypeOne from './uploadTypeOne';
import UploadTypeTwo from './uploadTypeTwo';
import UploadTypeThree from './uploadTypeThree';
import ConfirmedViewType from './confirmedViewType';
import { collectCommonJoints, getLeftHipId, getRequiredXrays, getRightHipId } from '../../StoreFunctions/evaluationStoreFunctions';


let uploaded = 0;

const AcknowledgePage = 0
const ExportScriptPage = 1
const OverviewPage = 2
const UploadPage = 3
const UploadTypePage = 4
const UploadTypeOnePage = 5
const UploadTypeTwoPage = 6
const UploadTypeThreePage = 7
const ConfirmedViewTypePage = 8

class UploadXray extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: AcknowledgePage,
            activeId: 0,
            Xrays: [],
            files: [],
            req: [],
            loading: false,
            uploadButton: false
        }
    }

    UNSAFE_componentWillMount() {

        uploaded = 0;
        if (this.context.state.Xrays && this.context.state.Xrays != null && this.context.state.Xrays.length > 0) {
            let activeId = this.context.state.Xrays.length - 1;
            this.setState({ Xrays: this.context.state.Xrays, activeId, files: [], OverView: true, req: [], loading: false, page: OverviewPage })
        }
        else {

            let Eval = this.context.state.Eval;
            let activeJointIndex = this.context.state.activeJointIndex;
            let activeJoint =  Eval[activeJointIndex].joint_id.toString();
            let currentCommonJoints = [];
            if(activeJoint)
            {
                currentCommonJoints = collectCommonJoints(activeJoint,Eval)   
            }

            let jointsArray = [];
            currentCommonJoints.forEach((cf)=>jointsArray.push(cf.joint_id));

            let Xrays = getRequiredXrays(jointsArray,activeJoint);

            // if(currentCommonJoints.length > 2)
            // {
            //     sortRequiredXrays(prior)
            //     if(activeJoint===getLeftHipId())
            //     {

            //     }
            //     if(activeJoint===getRightHipId())
            //     {

            //     }
            // }

            Xrays[0].enable=true;

            this.setState({
                Xrays, page: AcknowledgePage,
                activeId: 0,
                files: [],
                req: [],
                loading: false,
            })  
        }
    }

    appendFile = (file, xrayname, xrayid,ImageUrl) => {
        // let files=this.state.files;
        // let fileObj={visitor_id:this.context.state.report_id,xray_type_id:xrayid,file:file,name:xrayname};
        // files.push(fileObj);

        let Xrays = this.state.Xrays;
        Xrays[this.state.activeId].isDone = true;

        
        if(ImageUrl)
        {
            Xrays[this.state.activeId].image = ImageUrl?.toString();
        }
        else Xrays[this.state.activeId].image = URL.createObjectURL(file);


        if (this.state.activeId === this.state.Xrays.length - 1) //last one for upload
        {
            this.setState({ Xrays, page: ConfirmedViewTypePage, file, uploadButton: true })
        }

        else {
            Xrays[this.state.activeId + 1].enable = true;
            this.setState({ Xrays, page: ConfirmedViewTypePage, file })
        }
    }

    handleOverviewClick = (id) => {
        this.setState({ page: UploadPage, activeId: id })
    }


    Old = () => {
        this.context.multipleUpdateValueWithHistory([{ key: 'UXray', value: true }], './patient-profile')
    }

    handleUploadClick = () => {
        if (this.state.activeId === this.state.Xrays.length - 1) // always true because button appear on this condition - reference *appendFile function 
        {
            let selected_joints = []
            let currentCommonJoints = []
            let Eval = this.context.state.Eval;
            let activeJointIndex = this.context.state.activeJointIndex;
            let activeJoint =  Eval[activeJointIndex].joint_id.toString();
            if(activeJoint)
            {
                currentCommonJoints = collectCommonJoints(activeJoint,Eval)   
                currentCommonJoints.forEach((cCJ)=>{
                    selected_joints.push(cCJ.joint_id);
                })
            }   



            this.setState({ loading: true })
            let form_data = new FormData();
            form_data.append('visitor_id', this.context.state.report_id)
            form_data.append('selected_joints', selected_joints);
            PostFormData(this.context.baseUrl + '/api/v1/xray/confirm-upload',
                200, form_data, this.context.state.token, this.newSetMe)
        }
    }

    newSetMe = (response) => {
        if (response.responseCode === "Success") {
            this.context.multipleUpdateValue([{ key: 'UXray', value: true }, { key: 'evaluation_stage', value: 4 }, { key: 'Xrays', value: this.state.Xrays }])
            this.context.history.push('./patient-profile')
        }

        else {
            alert(response.message)
            this.context.multipleUpdateValue([{ key: 'Xrays', value: this.state.Xrays }])
            this.context.history.push('./patient-profile')
        }
    }

    handlePageChange = (page) => {
        this.setState({ page })
    }

    handleDeleteXray = () => {
        this.handlePageChange(OverviewPage)
    }


    getPage = () => {
        switch (this.state.page) {
            case 0: return <Acknowledge handleBackClick={() => this.context.history.push('./patient-profile')} Xrays={this.state.Xrays} handleYesClick={() => this.handlePageChange(UploadTypePage)} handleNoClick={() => this.handlePageChange(ExportScriptPage)} />;
            case 1: return <ExportScript Xrays={this.state.Xrays} handleEntryClick={() => this.handlePageChange(UploadTypePage)} handleBackClick={() => this.handlePageChange(AcknowledgePage)} />;
            case 2: return <OverviewBox Old={this.Old} handleUpload={this.handleUploadClick} Xrays={this.state.Xrays} handleClick={(id) => this.handleOverviewClick(id)} uploadButton={this.state.uploadButton} />;
            case 3: return <UploadBox handleDelete={this.handleDeleteXray}
                appendFile={(file, name, id, ImageUrl) => this.appendFile(file, name, id,ImageUrl)}
                Xray={this.state.Xrays[this.state.activeId]} />
            case 4: return <UploadType handleBackClick={() => this.handlePageChange(AcknowledgePage)} handleTypeOneClick={() => this.handlePageChange(UploadTypeOnePage)} handleTypeTwoClick={() => this.handlePageChange(UploadTypeTwoPage)} handleTypeThreeClick={() => this.handlePageChange(UploadTypeThreePage)} handleTypeFourClick={() => this.handlePageChange(OverviewPage)} />
            case 5: return <UploadTypeOne handleNextClick={() => this.handlePageChange(OverviewPage)} handleBackClick={() => this.handlePageChange(UploadTypePage)} />
            case 6: return <UploadTypeTwo handleNextClick={() => this.handlePageChange(OverviewPage)} handleBackClick={() => this.handlePageChange(UploadTypePage)} />
            case 7: return <UploadTypeThree handleNextClick={() => this.handlePageChange(OverviewPage)} handleBackClick={() => this.handlePageChange(UploadTypePage)} />
            case 8: return <ConfirmedViewType handleBackClick={() => this.handlePageChange(UploadPage)} handleNextClick={() => this.handlePageChange(OverviewPage)} Xray={this.state.Xrays[this.state.activeId]} />


            default: return <div> Unreachable step</div>;
        }

    }

    render() {
        return (

            <div id="Evaluaion_UploadXray_Main_Div">
                {this.state.loading === true ?

                    <SemipolarLoading size={"large"} color={'#b4ec51'} />

                    : this.state.page < 4 ?
                        <div id="Evaluaion_UploadXray_Content1_Wrapper">
                            {
                                (this.state.page !== 0 && this.state.page !== 1) &&

                                <div id="Evaluaion_UploadXray_Heading1_Div">
                                    Upload X-rays
                                </div>
                            }
                            {
                                this.getPage()
                            }
                        </div>
                        :
                        this.getPage()

                }



            </div>);
    }
}
UploadXray.contextType = MyContext;
export default UploadXray;