import React, { Component } from 'react';

import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';


import '../patientReport.css';
import MyContext from '../../../../helper/themeContext';
import { getJointNameById, getProStageId } from '../../../../StoreFunctions/evaluationStoreFunctions';
class Page1 extends Component {
    constructor(props) {
        super(props);
        this.state = { Answer1: null,Answer2: null }
    }

    componentDidMount() {
        this.setState({ Answer1: this.props.Answer1, Answer2: this.props.Answer2 })
    }
    handleChange = (e) => {
        // console.log(e.target.value)
        this.setState({ [e.target.name]: e.target.value })
    }

    handleClick = () => {
        this.props.changeAnswer('Question1', this.state.Answer1)
        this.props.changeAnswer('Question2', this.state.Answer2)

        if (this.state.Answer1 == null || this.state.Answer2 == null) {
            alert('Please choose an appropriate option')
        }
        else this.props.handlePageChange();
    }
    render() {
        const {activeJointId} = this.props;
        const JointName = getJointNameById(activeJointId);

        const options = [{ value: 'None', id: 1 }, { value: 'Mild', id: 2 }, { value: 'Moderate', id: 3 }, { value: 'Severe', id: 4 }, { value: 'Extreme', id: 5 }];
        const currentEval = this.context.state.Eval.find((ev)=>ev.joint_id.toString()===activeJointId.toString());
        const old = parseInt(currentEval.stage_id) >= getProStageId() ? true : false;
        return (
            <div>
                <div id="Evaluaion_PatientReport_Content_Wrapper">
                    <div id="Evaluaion_PatientReport_Heading1_Div">
                        Patient Reported Survey:  <span className="red-emphasis"> {JointName} </span>
                    </div>

                    <div className="Evaluaion_PatientReport_Text1">
                        {/* <span className="Evaluaion_PatientReport_SubHead1">INSTRUCTIONS: </span> <span>This survey asks for your view about your knee. This information will help us keep track of how you feel about your knee and how well you are able to do your usual activities. </span> <br/> */}
                        <span>Answer every question by selecting the appropriate box, <u> only </u> one box for each question.</span>
                    </div>

                    <div className="Evaluaion_PatientReport_Text2" >
                        <span className="Evaluaion_PatientReport_SubHead2">Pain </span> <br /> <span>What amount of <span className="red-emphasis"> {JointName}  </span> pain have you experienced the last week during the following activities? </span>
                    </div>

                    <div className="Evaluaion_PatientReport_Question_Div">
                        1. Going up or down stairs
                    </div>

                    <div className="Evaluaion_PatientReport_Answer1_Div">
                        {
                            options.map((option, id) =>
                                <span key={id}>
                                    <Radio
                                        disabled={old}
                                        checked={this.state.Answer1 == option.id}
                                        onChange={this.handleChange}
                                        value={option.id}
                                        name="Answer1"
                                        style={{ color: 'white' }}
                                    />
                                    <span className="Evaluaion_PatientReport_Answer1_Option">
                                        {option.value}
                                    </span>
                                </span>
                            )
                        }
                    </div>

                    <div className="Evaluaion_PatientReport_Question_Div">
                        2. Walking on an uneven surface
                    </div>

                    <div className="Evaluaion_PatientReport_Answer1_Div">
                        {
                            options.map((option, id) =>
                                <span key={id}>
                                    <Radio
                                        disabled={old}
                                        checked={this.state.Answer2 == option.id}
                                        onChange={this.handleChange}
                                        value={option.id}
                                        name="Answer2"
                                        style={{ color: 'white' }}
                                    />
                                    <span className="Evaluaion_PatientReport_Answer1_Option">
                                        {option.value}
                                    </span>
                                </span>
                            )
                        }
                    </div>



                    <div id="Evaluaion_PatientReport_Back_Button_Div">
                        <Button id="Evaluaion_PatientReport_Next_Button" variant="contained" onClick={this.props.handleBack}> Back </Button>
                    </div>

                    <div id="Evaluaion_PatientReport_Next_Button_Div">
                        <Button id="Evaluaion_PatientReport_Next_Button" variant="contained" onClick={this.handleClick}> Next </Button>
                    </div>


                </div>


            </div>);
    }
}
Page1.contextType = MyContext;
export default Page1;