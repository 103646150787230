import React, { Component } from 'react';

import Button from '@material-ui/core/Button';


import './incompleteEvaluations.css';
import MyContext from '../../helper/themeContext';
import GetData from '../../Fetch/getDataUniversal';
import { SemipolarLoading } from 'react-loadingg';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { getEvaluationRoutes, getPriorityJointRoute, LoadNewEval } from '../../StoreFunctions/evaluationStoreFunctions';



class IncompleteEvaluations extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, rows: [], resumeWarningModal: false, deleteWarningModal: false, id: null }
  }


  componentDidMount() {
    GetData(this.context.baseUrl + '/api/v1/user/all-incomplete-reports', 200,
      this.context.state.token, this.setMe);
  }

  setMe = (response) => {
    let rows = [];
    if (response.reports.length > 0) {
      response.reports.forEach(element => {
        let mydate = element.patient[0].date.toString().match(/\d+/g).map(Number);
        let mydate_year = mydate[0];
        let mydate_month = mydate[1];
        let mydate_date = mydate[2];

        if (mydate_month.toString().length === 1) {
          mydate_month = '0' + mydate_month;
        }

        if (mydate_date.toString().length === 1) {
          mydate_date = '0' + mydate_date;
        }
        let newdate = mydate_month + '-' + mydate_date + '-' + mydate_year;
        rows.push({ stage_id: element.user_state.stage_id, patient_id: element.patient[0].id, patient_name: element.patient[0].name, evaluation_date: newdate, report_id: element.id })
      });
    }
    this.setState({ rows, loading: false })
  }

  handleRecover = (id) => {
    this.setState({ resumeWarningModal: true, id })
  }

  closeResumeWarningModal = () => {
    this.setState({ resumeWarningModal: false, id: null })
  }

  Resume = (review = false) => {
    this.setState({ loading: true, review, resumeWarningModal: false })
    this.context.newVisitor();
    GetData(this.context.baseUrl + `/api/v1/get/incomplete-report?report_id=${this.state.id}`,
      200, this.context.state.token, this.setMeTwo)
  }

  setMeTwo = (response) => {
    if (response.incomplete_vistor_id != null && response.incomplete_vistor_id !== undefined && response.incomplete_vistor_id !== "" && response.incomplete_vistor_id !== " ") {
      if (this.state.review === true) {
        LoadNewEval(this.context, response, './evaluation/demographics');
      }
      else {  

        if(JSON.parse(response.stage.id)>1)       //joints priority selected
        {
          let route = getPriorityJointRoute(response.joint_hurt_priority);
          // console.log(route);
          LoadNewEval(this.context, response, route);
        }
        else
        {
          let route = getEvaluationRoutes().find(el => el.id === response.stage.id.toString()).route
          LoadNewEval(this.context, response, route);
        }
      }
    }
  }

  handleDelete = (id) => {
    this.setState({ deleteWarningModal: true, id })
  }

  closeDeleteWarningModal = () => {
    this.setState({ deleteWarningModal: false, id: null })
  }

  Delete = () => {
    this.setState({ loading: true, deleteWarningModal: false })
    GetData(this.context.baseUrl +
      `/api/v1/delete/report?report_id=${this.state.id}`,
      200, this.context.state.token, this.setMeThree)
  }

  setMeThree = res => {
    // console.log('incomplt_eval==>', res.data)
    let new_rows = this.state.rows;
    new_rows = JSON.parse(JSON.stringify(new_rows));
    new_rows = new_rows.filter((row) => row.report_id.toString() !== this.state.id.toString())
    this.setState({ loading: false, id: null, rows: new_rows })
  }


  render() {
    return (
      <div id="Evaluaion_PatientReport_Main_Div">
        {this.state.loading === true ? (
          <SemipolarLoading size={"large"} color={"#b4ec51"} />
        ) : (
          <div id="Evaluaion_PatientReport_Content_Wrapper" style={{ justifyContent: "flex-start" }}>
            <div id="Evaluaion_PatientReport_Heading1_Div">Select a Report to Resume</div>

            <div id="Evalution_History_Table_Wrapper" style={{ width: "80vw" }}>
              <div id="Evalution_History_Table_Head">
                <div style={{ width: "calc(15% - 20px)", display: "inline-block", marginRight: "20px" }}>Patient Name</div>
                <div style={{ width: "calc(15% - 20px)", display: "inline-block", marginRight: "20px" }}>Date of Evaluation</div>
                <div style={{ width: "calc(26% - 20px)", display: "inline-block", marginRight: "20px" }}>Last Stage Completed</div>
                <div style={{ width: "calc(22% - 20px)", display: "inline-block", marginRight: "20px" }}></div>
                <div style={{ width: "calc(22% - 20px)", display: "inline-block", marginRight: "20px" }}></div>
              </div>

              <div
                id="Evalution_History_Table_Line"
                style={{ width: "100%", height: "2px", background: "#ffffff" }}>
              </div>

              <div id="Evalution_History_Table_Body">
                {this.state.rows.map((row, id) => (

                  <div className="Evalution_History_Table_Body_Row" key={id}>
                    <div
                      className="Evalution_History_Table_Body_Text"
                      style={{ width: "calc(15% - 20px)", display: "inline-block", marginRight: "20px" }}>
                      {row.patient_name}
                    </div>
                    <div
                      className="Evalution_History_Table_Body_Text"
                      style={{ width: "calc(15% - 20px)", display: "inline-block", marginRight: "20px" }}>
                      {row.evaluation_date}
                    </div>
                    <div
                      className="Evalution_History_Table_Body_Text"
                      style={{ width: "calc(26% - 20px)", display: "inline-block", marginRight: "20px" }}>
                      {getEvaluationRoutes().find((eva) => eva.id === row.stage_id.toString()).name}
                    </div>
                    <div style={{ width: "calc(22% - 20px)", display: "inline-block", marginRight: "20px" }}>
                      <Button
                        className="Evaluaion_History_Button"
                        variant="contained"
                        onClick={() => {
                          this.handleRecover(row.report_id);
                        }}>
                        {" "}
                            Resume Report{" "}
                      </Button>
                    </div>
                    <div style={{ width: "calc(22% - 20px)", display: "inline-block", marginRight: "20px" }}>
                      <Button
                        className="Evaluaion_History_Button"
                        variant="contained"
                        onClick={() => {
                          this.handleDelete(row.report_id);
                        }}>
                        {" "}
                            Delete Report{" "}
                      </Button>
                    </div>

                  </div>
                ))}
              </div>
            </div>


            <div id="Evaluaion_PatientReport_Back_Button_Div">
              <Button
                id="Evaluaion_PatientReport_Next_Button"
                variant="contained"
                onClick={() => this.context.history.goBack()}>
                {" "}
                    Back{" "}
              </Button>
            </div>

          </div>
        )}

        {/* Delete Rodal */}
        <Rodal visible={this.state.deleteWarningModal} onClose={this.closeDeleteWarningModal}>
          <div>
            <div className="Evaluation_Home_ResumeEvaluationWarningModal_Text_Div">
              This will delete your on-going evaluation. Would you like to continue?
                  </div>

            <div className="Evaluation_IncompleteEvaluation_Button_Div">
              <Button className="Evaluation_ResumeEvaluation_Button" variant="contained" onClick={this.Delete}> Yes </Button>
            </div>
            <div className="Evaluation_IncompleteEvaluation_Button_Div">
              <Button className="Evaluation_ResumeEvaluation_Button" variant="contained" onClick={this.closeDeleteWarningModal}> No </Button>
            </div>
          </div>
        </Rodal>

        {/* Resume Rodal */}
        <Rodal visible={this.state.resumeWarningModal} onClose={this.closeResumeWarningModal}>
          <div>
            <div className="Evaluation_Home_ResumeEvaluationWarningModal_Text_Div">
              Would you like to resume where you left off or review the evaluation from start?
                  </div>

            <div className="Evaluation_IncompleteEvaluation_Button_Div">
              <Button className="Evaluation_IncompleteEvaluation_Button" variant="contained" onClick={this.Resume}> Resume </Button>
            </div>
            <div className="Evaluation_IncompleteEvaluation_Button_Div">
              <Button className="Evaluation_IncompleteEvaluation_Button" variant="contained" onClick={() => this.Resume(true)}> Review from Start </Button>
            </div>
          </div>
        </Rodal>
      </div>
    );
  }
}
IncompleteEvaluations.contextType = MyContext;
export default IncompleteEvaluations;