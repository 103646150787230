import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";

import Bone2Image from "../assets/bone1_Bitmap.png";

import "./homeNew.css";
import MyContext from "../helper/themeContext";

import { SemipolarLoading } from "react-loadingg";
import { GetHipEducation_Tutorial_Id, GetRequiredHipEdu, GetRequiredKneeEdu, _checkIncomplete } from "../StoreFunctions/tutorialStoreFunction";

// import GetDataJson from '../Fetch/getDataJson';
// import {LoadNewEval} from '../StoreFunctions/evaluationStoreFunctions'

const evaluationRoutes = [
  { id: "0", stage: "0", route: "./evaluation/demographics" },
  {
    id: "1",
    stage: "1",
    route: "./evaluation/new-evaluation",
    name: "Patient Demographics Submitted",
  },
  {
    id: "2",
    stage: "2",
    route: "./evaluation/pro",
    name: "Joints Priority Selected",
  },
  {
    id: "3",
    stage: "3",
    route: "./evaluation/upload-xrays",
    name: "Question Form Submitted",
  },
  {
    id: "4",
    stage: "4",
    route: "./evaluation/x-ray-matching",
    name: "All Xrays Uploaded",
  },
];

const WhatYouKneeKnow_Tutorial_Id = 5;
const XrayAi_Tutorial_Id = 6;
const PatientSurvey_Tutorial_Id = 7;
const SampleKnee_Tutorial_Id = 8;
const RCP_Tutorial_Id = 9;
const TestMatching_Tutorial_Id = 10;

const GetHipAboutArth_Tutorial_Id = 11;
const XrayAi_Hip_Tutorial_Id = 12;
const PatientSurvey_Hip_Tutorial_Id = 13;
const SampleHip_Tutorial_Id = 14;
const RCP_Hip_Tutorial_Id = 15;
const TestMatching_Hip_Tutorial_Id = 16;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { resumeWarningModal: false, loading: false };
  }

  closeResumeWarningModal = () => {
    this.setState({ resumeWarningModal: false });
  };

  handleRecover = () => {
    if (this._checkEvaluationUser) {
      if (parseInt(this.context.state.evaluation_stage) < 5) {
        let isExist = evaluationRoutes.find(
          (route) =>
            route.stage.toString() ===
            this.context.state.evaluation_stage.toString()
        );
        if (isExist) {
          this.context.history.push(isExist.route);
        } else {
          alert("unable to load last evaluation");
          this.setState({ loading: false });
        }
      } else {
        alert("unable to load last evaluation");
        this.setState({ loading: false });
      }
      // let report_id =  this.context.state.oldEvaluations[this.context.state.oldEvaluations.length-1].id;
      // GetData(this.context.baseUrl+`/api/v1/get/incomplete-report?report_id=${report_id}`,200,this.context.state.token,this.setMe)
    }
  };

  startEvaluation = () => {
    if (this._checkEvaluationUser) {
      this.setMeTwo();
    }
  };

  _handleEvaluationHistory = () => {
    if (this._checkEvaluationUser) {
      this.context.history.push("/evaluation-history");
    }
  };

  setMeTwo = () => {
    this.context.newVisitor();
    this.context.history.push("/evaluation/welcome");
  };



  _checkEvaluationUser = () => {
    if (this.context.state.user_type_id.toString() === "4") return false;
    else return true;
  };

  render() {
    const EvaButtonOpacityClass = this._checkEvaluationUser() ? "1" : "0.5";
    const EvaButtonCursorClass = this._checkEvaluationUser()
      ? "pointer"
      : "cursor";

    const incompleteEvalDisable =
      this._checkEvaluationUser() && this.context.state.isOldEvaluations
        ? false
        : true;
    const incompleteEvalOpacityClass =
      this._checkEvaluationUser() && this.context.state.isOldEvaluations
        ? "1"
        : "0.5";

    const resumeEvalDisable =
      this._checkEvaluationUser() &&
      parseInt(this.context.state.evaluation_stage) < 5
        ? false
        : true;
    const resumeEvalOpacityClass =
      this._checkEvaluationUser() &&
      parseInt(this.context.state.evaluation_stage) < 5
        ? "1"
        : "0.5";

    return (
      <div id="Home_Main_Div">
        {this.state.loading === true ? (
          <SemipolarLoading size={"large"} color={"#b4ec51"} />
        ) : (
          <div id="Home_Content1_Wrapper">
            <div id="Home_Heading1_Div">
              Welcome to <br />
              <span style={{ color: "#b4ec51", fontWeight: "bold" }}>
                Hip
              </span>{" "}
              &
              <span style={{ color: "#b4ec51", fontWeight: "bold" }}>
                {" "}
                Knee{" "}
              </span>{" "}
              Step by Step
              {/* <div id="Home_Neon_Line"></div> */}
            </div>
            <div className="Home_Button_Div"></div>

            <Grid container>
              <Grid item xs={4} md={4} lg={4} xl={4}>
                <div className="home-grid-left">
                  <div className="home-grid-inside-heading">
                    Step by Step Education
                  </div>

                  <div className="Home_Button_Div">
                    <Button
                      id="Home_Button"
                      variant="contained"
                      onClick={() => {
                        // this.context.history.push("./tutorials/sbs/welcome");
                        this.context.history.push("./tutorials/sbs/video");
                      }}
                    >
                      Get Started
                    </Button>
                    {_checkIncomplete(1,this.context) ? (
                      <div className="Home_Incomplete_Div">Incomplete</div>
                    ) : (
                      <div className="Home_Complete_Div">Complete</div>
                    )}
                  </div>

                  <div className="Home_Button_Div">
                    <Button
                      id="Home_Button"
                      variant="contained"
                      onClick={() => {
                        this.context.history.push("./tutorials/knee/options");
                      }}
                    >
                      {" "}
                      Knee Education{" "}
                    </Button>
                    {_checkIncomplete(GetRequiredKneeEdu(),this.context) ? (
                      <div className="Home_Incomplete_Div">Incomplete</div>
                    ) : (
                      <div className="Home_Complete_Div">Complete</div>
                    )}
                  </div>

                  <div className="Home_Button_Div">
                    <Button
                      id="Home_Button"
                      variant="contained"
                      onClick={() => {
                        this.context.history.push("./tutorials/hip/options");
                      }}
                    >
                      {" "}
                      Hip Education{" "}
                    </Button>
                    {_checkIncomplete(GetRequiredHipEdu(),this.context) ? (
                      <div className="Home_Incomplete_Div">Incomplete</div>
                    ) : (
                      <div className="Home_Complete_Div">Complete</div>
                    )}
                  </div>

                  <div className="Home_Button_Div">
                    <Button
                      id="Home_Button"
                      variant="contained"
                      onClick={() => {
                        this.context.history.push(
                          "/tutorials/automatic-xray-evaluation"
                        );
                      }}
                    >
                      {" "}
                      AI and Machine Learning{" "}
                    </Button>
                    {_checkIncomplete(4,this.context) ? (
                      <div className="Home_Incomplete_Div">Incomplete</div>
                    ) : (
                      <div className="Home_Complete_Div">Complete</div>
                    )}
                  </div>
                </div>
              </Grid>

              <Grid item xs={4} md={4} lg={4} xl={4}>
                <div id="Home_Image_div_wrapper">
                  {/* <div className="Home_Button_Div"id='Get_started_Div'>
                                        <Button id="Home_Button" variant="contained"
                                            onClick={() => { this.context.history.push("./tutorials/sbs/welcome"); }}>
                                            Get Started
                                        </Button>
                                    </div> */}
                  <div id="Home_Image_div">
                    <img src={Bone2Image} alt="SBS" id="Home_Image_Bone" />
                  </div>
                </div>
              </Grid>

              <Grid item xs={4} md={4} lg={4} xl={4}>
                <div className="home-grid-left">
                  <div className="home-grid-inside-heading">
                    Patient Evaluations
                  </div>
                  <div className="Home_Button_Div">
                    {/* disabled={this.context.state.evaluation_stage} */}
                    <Button
                      id="Home_Button"
                      variant="contained"
                      style={{
                        opacity: EvaButtonOpacityClass,
                        cursor: EvaButtonCursorClass,
                      }}
                      disabled={!this._checkEvaluationUser()}
                      onClick={this.startEvaluation}
                    >
                      {" "}
                      New Evaluation{" "}
                    </Button>
                  </div>

                  <div className="Home_Button_Div">
                    <Button
                      id="Home_Button"
                      variant="contained"
                      disabled={resumeEvalDisable}
                      style={{ opacity: resumeEvalOpacityClass }}
                      onClick={this.handleRecover}
                    >
                      {" "}
                      Resume Last Evaluation{" "}
                    </Button>
                  </div>

                  <div className="Home_Button_Div">
                    <Button
                      id="Home_Button"
                      variant="contained"
                      style={{
                        opacity: EvaButtonOpacityClass,
                        cursor: EvaButtonCursorClass,
                      }}
                      disabled={!this._checkEvaluationUser()}
                      onClick={this._handleEvaluationHistory}
                    >
                      {" "}
                      Evaluation History{" "}
                    </Button>
                  </div>

                  <div className="Home_Button_Div">
                    <Button
                      id="Home_Button"
                      variant="contained"
                      style={{ opacity: incompleteEvalOpacityClass }}
                      onClick={() =>
                        this.context.history.push("/incomplete-evaluations")
                      }
                      disabled={incompleteEvalDisable}
                    >
                      {" "}
                      Incomplete Evaluations{" "}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        {/* <Rodal visible={this.state.resumeWarningModal} onClose={this.closeResumeWarningModal}>
                    <div>
                        <div className="Evaluation_Home_ResumeEvaluationWarningModal_Text_Div">
                            This will delete your on-going evaluation. Would you like to continue?
                        </div>

                        <div className="Evaluation_ResumeEvaluation_Button_Div">
                            <Button className="Evaluation_ResumeEvaluation_Button" variant="contained" onClick={this.deleteReportAndStartNew}> Yes </Button>
                        </div>
                    </div>
                </Rodal> */}
      </div>
    );
  }
}
Home.contextType = MyContext;
export default Home;
