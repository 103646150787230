import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import NonOpImage from "../../assets/non-op.png";
import "./JointNoi1.css"

class JointNoi5 extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div id="RCPE-Welcome-main-container">
                <div id="all-content-wrapper">
                    <div id="RCPE-Welcome-box-1">
                        <div id="JointNoi3-inner-box-1">

                            {/* Dic 1 */}
                            <div id="RCPNOI-container-1">
                                <h2 id="RCPNOI-heading"> {`Learn about joint replacements`} </h2>

                                <div id="RCPNOI-form-box-1">
                                    <form id="RCPNOI-form">
                                        <ul className="RCPNOI-unstyled RCPNOIcentered">

                                            {["My journey to wellness","What is a joint replacement?","Is a joint placement right for me?","Preparing for surgery","Recovery after surgery","Life with a joint replacement"].map((text)=>
                                                <li>
                                                    <label htmlFor={text} className="RCPNOI-form-field">
                                                        {text}
                                                    </label>
                                                </li>
                                            )}
                                        </ul>      
                                    </form>

                                    <img alt="App-Image" src={NonOpImage} id="JN1-Image" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="RCPE-Welcome-box-2">
                        <div id="RCPE-Welcome-footer-div1">
                            <Button
                                id="RCPE-Welcome-btn-1"
                                variant="contained"
                                onClick={this.props.handleBackClick}
                            >
                                {" "} Back {" "}
                            </Button>
                        </div>

                    </div>

                </div>
                
            </div>
        );
    }
}

export default JointNoi5;