
import React, { Component } from 'react';
import MyContext from '../../helper/themeContext';
import GetData from '../../Fetch/getData1';
import SemipolarLoading from 'react-loadingg/lib/SemipolarLoading';
import Button from '@material-ui/core/Button';
import {  checkIfHipExist, checkIfKneeExist, getJointNameById, getLeftHipId, getRightHipId, loadNextEval, NextEvalIndex } from '../../StoreFunctions/evaluationStoreFunctions';
import { getJointSummaryNumber } from '../../StoreFunctions/PRCStoreFunctions';

import HipEvaluationImage from '../../assets/hip_evaluation_home.png';
import KneeEvaluationImage from '../../assets/knee_evaluation_home.png'
import PostData from '../../Fetch/postData4';

import './index.css';

const nextPageRoute1 = "./complete-pdf";
const nextPageRoute2 = "./report-feedback";

const backPageRoute = "./report";

//css from evaluation pdf
class SingleJointPdf extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, base64: "", blobUrl: '' }
    }

    componentDidMount = () => {
        let Eval = this.context.state.Eval;

        // console.log(this.context.state.activeJointIndex)
        // console.log(Eval)

        let joint_summary_number = getJointSummaryNumber(Eval,this.context.state.activeJointIndex);
        let visitor_id = this.context.state.report_id;
        // visitor_id = 1319;
        // joint_summary_number = 1;
        let req = {
            visitor_id: visitor_id,
            joint_summary_number:joint_summary_number
        }
        GetData(this.context.baseUrl + '/api/v1/get-prc/single/pdf', 200, req,
            this.context.state.token, this.setMe)
    
        // Get  (this.context.baseUrl+'/api/v1/download/pdf',token,callback)

    }

    setMe = (response) => {

        if (response.base64pdf) {
            const byteCharacters = atob(response.base64pdf);
            const byteNumbers = new Array(byteCharacters.length);
            // console.log('length:' + byteCharacters.length)

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            this.setState({ loading: false, base64: response.base64pdf, blobUrl })
        }

        else alert("Pdf not found")
    }

    printDownload = e => {
        e.preventDefault();
        let iframe =  document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = this.state.blobUrl;
        iframe.onload = function() {
          setTimeout(function() {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };
        // window.print(this.state.blobUrl);
    }
    handleDownload = () => {
        var link = document.createElement('a');
        link.innerHTML = 'Download PDF file';
        link.download = this.context.state.patient_id + '_Report.pdf';
        link.href = 'data:application/octet-stream;base64,' + this.state.base64;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleNext = async () =>
    {

        this.setState({loading:true})

        let nextIndex = await NextEvalIndex(this.context.state);
        if(nextIndex > -1)
        {
            let req={web_stage:5}
            // await PostData(this.context.baseUrl + '/api/v1/web_stage', 201, req, this.context.state.token, this.setMeTwo)
            this.setState({loading:false})
            loadNextEval(this.context);
        }

        else {

            let req={web_stage:6}
            // await PostData(this.context.baseUrl + '/api/v1/web_stage', 201, req, this.context.state.token, this.setMeTwo)
            this.setState({loading:false})

            let Eval = this.context.state.Eval;

            if(checkIfHipExist(Eval) && checkIfKneeExist(Eval))
            {
                this.context.history.push(nextPageRoute1)
            }

            else this.context.history.push(nextPageRoute2)

        }
       
    }

    render() {
        // console.log(this.context.state.Eval[this.context.state.activeJointIndex]);
        let joint_id = this.context.state.Eval[this.context.state.activeJointIndex]?.joint_id?.toString();
        return (
            <div id="Evaluation_PDF_MAIN_DIV">

                {this.state.loading === false ?
                    <div id="Evaluation_SinglePDF_Content_Wrapper" >


                        <div id="Evaluaion_pdf_Container">

                            <div id="Evaluaion_pdf_child1">
                                <div id="Evaluaion_pdf_Heading1_Div">
                                    Patient Summary + Recommended Care Pathway
                                </div>
                                <div id="Evaluaion_pdf_iframe_wrapper">
                                    <iframe title="pdf" src={this.state.blobUrl} style={{ width: '100%', height: '100%' }} >
                                    </iframe>
                                </div>
                                <div id="Evaluaion_PDF_Back_Button_Div">
                                    <Button id="Evaluaion_PDF_Button" variant="contained" onClick={() => {
                                        this.context.history.push(backPageRoute) }}> Back </Button>
                                </div>
                            </div>

                            <div id="Evaluaion_pdf_child2" style={{verticalAlign:"top"}}>
                                <div id="Evaluaion_SinglePDF_Download_Button_Div" >
                                    <Button className="Download_Single_Summaries_Div_Button" variant="contained" onClick={this.handleDownload}> Download </Button>
                                    <Button className="Download_Single_Summaries_Div_Button" variant="contained"
                                        onClick={this.printDownload}> Print </Button>
                                </div>
                                {/* print button here */}

                                <div id="Evaluaion_PDF_Summary_Div">
                                    <div id="Evaluaion_PDF_Summary_Head">
                                        {
                                            (joint_id===getLeftHipId() || joint_id===getRightHipId()) ?
                                            "HIP SUMMARY"
                                            : "KNEE SUMMARY"
                                        }
                                    </div>
                                    <div id="Evaluaion_PDF_Summary_Image">
                                        {
                                            (joint_id===getLeftHipId() || joint_id===getRightHipId()) ?
                                            <img src={HipEvaluationImage} alt="Hip" style={{maxWidth:"100%"}} />
                                            :
                                            <img src={KneeEvaluationImage} alt="Knee" style={{maxWidth:"100%"}}/>
                                        }
                                    </div>
                                </div>

                                <div id="Evaluaion_PDF_Back_Button_Div" style={{right:"80px"}}>
                                    <Button id="Evaluaion_PDF_Button" variant="contained" onClick={this.handleNext}> Next </Button>
                                </div>

                                {/* <div id="Evaluaion_PDF_Back_Home_Button_Div" className="Evaluation_PDF_Button_Div">
                                    <Button id="Evaluaion_PDF_Button" variant="contained" onClick={this.handleNext}> Next </Button>
                                </div> */}
                            </div>
                        </div>
                    </div> :
                    <SemipolarLoading size={"large"} color={'#b4ec51'} />
                }
            </div>
        );
    }
}

SingleJointPdf.contextType = MyContext;
export default SingleJointPdf;