

const KneeEducation_Tutorial_Id = 2;
const HipEducation_Tutorial_Id = 3;
const WhatYouKneeKnow_Tutorial_Id = 5;
const XrayAi_Tutorial_Id = 6;
const PatientSurvey_Tutorial_Id = 7;
const SampleKnee_Tutorial_Id = 8;
const RCP_Tutorial_Id = 9;
const TestMatching_Tutorial_Id = 10;

const GetHipAboutArth_Tutorial_Id = 11;
const XrayAi_Hip_Tutorial_Id = 12;
const PatientSurvey_Hip_Tutorial_Id = 13;
const SampleHip_Tutorial_Id = 14;
const RCP_Hip_Tutorial_Id = 15;
const TestMatching_Hip_Tutorial_Id = 16;

const RequiredKneeEdu = 17;
const RequiredHipEdu = 18;


export function GetKneeEducation_Tutorial_Id()
{
    return KneeEducation_Tutorial_Id
}
export function GetHipEducation_Tutorial_Id()
{
    return HipEducation_Tutorial_Id
}
export function GetWhatYouKneeKnow_Tutorial_Id()
{
    return WhatYouKneeKnow_Tutorial_Id
}
export function GetXrayAi_Tutorial_Id()
{
    return XrayAi_Tutorial_Id
}
export function GetPatientSurvey_Tutorial_Id()
{
    return PatientSurvey_Tutorial_Id
}
export function GetSampleKnee_Tutorial_Id()
{
    return SampleKnee_Tutorial_Id
}
export function GetRCP_Tutorial_Id()
{
    return RCP_Tutorial_Id
}
export function GetTestMatching_Tutorial_Id()
{
    return TestMatching_Tutorial_Id
}
export function GetGetHipAboutArth_Tutorial_Id()
{
    return GetHipAboutArth_Tutorial_Id
}
export function GetXrayAi_Hip_Tutorial_Id()
{
    return XrayAi_Hip_Tutorial_Id
}
export function GetPatientSurvey_Hip_Tutorial_Id()
{
    return PatientSurvey_Hip_Tutorial_Id
}
export function GetSampleHip_Tutorial_Id()
{
    return SampleHip_Tutorial_Id
}
export function GetRCP_Hip_Tutorial_Id()
{
    return RCP_Hip_Tutorial_Id
}
export function GetTestMatching_Hip_Tutorial_Id()
{
    return TestMatching_Hip_Tutorial_Id
}
export function GetRequiredKneeEdu()
{
    return RequiredKneeEdu;
}

export function GetRequiredHipEdu()
{
    return RequiredHipEdu;
}


export function _checkIncomplete(id,context) {

   

    id =  parseInt(id)

    if(!context)
    {
        return false;
    }

    let tutorials = context.state.tutorials;

    if (tutorials && tutorials.length > 0) {
      if (id === KneeEducation_Tutorial_Id) {
        if (
          tutorials.indexOf(WhatYouKneeKnow_Tutorial_Id) > -1 &&
          tutorials.indexOf(XrayAi_Tutorial_Id) > -1 &&
          tutorials.indexOf(PatientSurvey_Tutorial_Id) > -1 &&
          tutorials.indexOf(SampleKnee_Tutorial_Id) > -1 &&
          tutorials.indexOf(RCP_Tutorial_Id) > -1 &&
          tutorials.indexOf(TestMatching_Tutorial_Id) > -1
        ) {
          return false;
        } else return true;
      }
      if (id === HipEducation_Tutorial_Id) {
        if (
          tutorials.indexOf(GetHipAboutArth_Tutorial_Id) > -1 &&
          tutorials.indexOf(XrayAi_Hip_Tutorial_Id) > -1 &&
          tutorials.indexOf(PatientSurvey_Hip_Tutorial_Id) > -1 &&
          tutorials.indexOf(SampleHip_Tutorial_Id) > -1 &&
          tutorials.indexOf(RCP_Hip_Tutorial_Id) > -1 &&
          tutorials.indexOf(TestMatching_Hip_Tutorial_Id) > -1
        ) {
          return false;
        } else return true;
      }
      else if (tutorials.indexOf(id) > -1) {
        return false;
      } else return true;
    } else return true;
  }
