import React, { Component } from 'react';

import Tick from '../../assets/tick-black.png';
import boxBg from '../../assets/boxbg3.png'

import Grid from '@material-ui/core/Grid';
import MyContext from '../../helper/themeContext';

import './getImage.css';
import { currentCommonJointsArray, getJointCompartmentName, getLeftHipId, getRightHipId } from '../../StoreFunctions/evaluationStoreFunctions';
import { getAggregate, getRatingById, getTHRPathId, LoadDegeneration } from '../../StoreFunctions/PRCStoreFunctions';

const height = '60px';

class ReportCard extends Component {
    constructor(props) {
        super(props);
        this.state = { Evaluations: [],JointMapArray:[]
    }
    }

    UNSAFE_componentWillMount() {

        if(this.props.isDevTesting)
        {
            let joint_id = getRightHipId().toString();
            
            let Compartments = [];
            let Score = 44;
            let pathId = getTHRPathId();
            let MatchingEvaluations = this.context.state.Evaluations;
            let JointMapArray = [];

            let Evaluation = MatchingEvaluations.find(eva => eva.joint_id.toString() === joint_id);

            let priority_id = this.context.state.Eval[0].priority_id;
            // console.log('MatchingEvaluations',this.context.state.Eval)
            Evaluation.Xrays.forEach((comp)=>{
                let CompartmentScore = getAggregate(comp.xrays[0].state, comp.xrays[1]?.state)
                Compartments.push({name:comp.name,score:CompartmentScore,id:comp.id});
            });

            JointMapArray = [{ Name: "Right Hip", joint_id:joint_id, priority_id:priority_id, Compartments:Compartments, Score:Score, pathId:pathId }]
            this.setState({ JointMapArray:JointMapArray })   
        }
        
    }


    render() {

        const {isDevTesting} = this.props;

        let JointMapArray = isDevTesting ? this.state.JointMapArray : this.props.JointMapArray;

        return (
            <div className="GetImage1" style={{
                width: "900px", height: "1100px", backgroundColor: "white",
                position:  isDevTesting ? 'relative': 'absolute', 
                top:  isDevTesting ? '0px': '-1600px', //make top 0 to get image visible
                paddingTop: '40px', paddingBottom: '20px'
            }}>
                <div className="ImageReportMainDiv">
                    {/* <div className="pdf-page-number-header">
                        Page 4
                    </div> */}
                    {JointMapArray.map((joint, id) =>
                        <React.Fragment key={id}>
                            <div className="xray-report-card-header">
                                <span>
                                    {joint.Name}{" "}<span style={{fontSize:"12px"}}> {"(Priority " + joint.priority_id  +")"} </span>
                                </span>
                                <span style={{ textAlign: 'center', paddingLeft: '150px' }}>
                                    Xray Report Card
                                </span>
                            </div>
                            <Grid container style={{ marginBottom: id == 0 ? '40px' : '' }}>
                                <Grid item xs={4} spacing={0} style={{ height: '140px' }}>
                                    <React.Fragment>
                                        <span className="GetImage_Report_Box_Text3">
                                            {this.context.state.patient.name}<br />
                                            ID: {this.context.state.patient_id} <br />
                                            X-ray Evaluations: {this.context.state.patient.date} <br />
                                            {/* Date X-ray Taken: {this.context.state.patient.date} <br/> */}
                                        </span>
                                    </React.Fragment>
                                </Grid>
                                <Grid item xs={8} spacing={0} style={{ height: '140px' }}>
                                    <Grid container>
                                        <Grid item xs={3} >
                                            <div style={{ width: '2px', height: '140px', background: 'black', transform: 'rotate(45deg) translateY(-18px) translateX(49px)', }}>
                                            </div>
                                            <div className="GetImage_Report_Box_Text4">
                                                Normal/Slight
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div style={{ width: '2px', height: '140px', background: 'black', transform: 'rotate(45deg) translateY(-18px) translateX(49px)', }}>
                                            </div>
                                            <div className="GetImage_Report_Box_Text4">
                                                Moderate
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div style={{ width: '2px', height: '140px', background: 'black', transform: 'rotate(45deg) translateY(-18px) translateX(49px)', }}>
                                            </div>
                                            <div className="GetImage_Report_Box_Text4">
                                                Near End Stage
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div style={{ width: '2px', height: '140px', background: 'black', transform: 'rotate(45deg) translateY(-18px) translateX(49px)', }}>
                                            </div>
                                            <div className="GetImage_Report_Box_Text4">
                                                End Stage
                                            </div>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                {joint.Compartments.map((type, id) =>
                                    <Grid item xs={12} key={id}>
                                        <Grid container>
                                            <Grid item xs={4} spacing={0} style={{ border: '1px solid #000000', height: `calc(${height} - 2px)` }}>
                                                <React.Fragment>
                                                    <div style={{ height: '100%' }}>
                                                        <div className="GetImage_Report_Box_Text5">
                                                            {type.name} <br /> {type.name !== "Kneecap" && getJointCompartmentName(joint.joint_id)}   
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            </Grid>
                                            <Grid item xs={8} spacing={0} >
                                                <Grid container>
                                                    {
                                                        type.score < 0 ?
                                                        <Grid item xs={8} style={{ border: '1px solid #000000', height: `calc(${height} - 2px)`, background: '#A7A9AC' }}>
                                                            <div className="Evaluaion_Report_Box_Selected_Box"> Cannot Evaluate </div>
                                                        </Grid> :
                                                        <React.Fragment>
                                                            <Grid item xs={3} style={{ border: '1px solid #000000', height: `calc(${height} - 2px)`, background: (type.score === 1) ? '#6C8D31' : '' }}>
                                                                <div className="Evaluaion_Report_Box_Selected_Box"> {(type.score === 1) && <img src={Tick} alt="Ticked" />} </div>
                                                            </Grid>
                                                            <Grid item xs={3} style={{  border: '1px solid #000000', height: `calc(${height} - 2px)`, background: (type.score === 2) ? 'yellow' : '' }}>
                                                                <div className="Evaluaion_Report_Box_Selected_Box"  > {(type.score === 2) && <img src={Tick} alt="Ticked" />}  </div>
                                                            </Grid>
                                                            <Grid item xs={3} style={{ border: '1px solid #000000', height: `calc(${height} - 2px)`, background: (type.score === 3) ? '#fa9e2d' : '' }}>
                                                                <div className="Evaluaion_Report_Box_Selected_Box"> {(type.score === 3) && <img src={Tick} alt="Ticked" />} </div>
                                                            </Grid>
                                                            <Grid item xs={3} style={{ border: '1px solid #000000', height: `calc(${height} - 2px)`, background: (type.score === 4) ? '#C50000' : '' }}>
                                                                <div className="Evaluaion_Report_Box_Selected_Box"> {(type.score === 4) && <img src={Tick} alt="Ticked" />} </div>
                                                            </Grid>
                                                        </React.Fragment>      
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                                {
                                    ((joint.joint_id===getLeftHipId())||(joint.joint_id===getRightHipId())) &&
                                    <Grid item xs={12} spacing={0}>
                                        <Grid container>
                                            <Grid item xs={4}>                  
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div style={{ border: '1px solid #fff', height: `calc(${height} - 2px)`, fontSize: "24px", lineHeight: "58px", textAlign: "center", color:"#fff" }} >
                                                    Worst Degeneration: <span style={{ fontWeight:"bold" }} > {getRatingById(LoadDegeneration(joint.Compartments,joint.joint_id).toString()).name} </span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}
ReportCard.contextType = MyContext;
export default ReportCard;