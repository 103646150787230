import React, { Component } from "react";
import MyContext from "../../helper/themeContext";
import MainImage from "../../assets/pdfImages/Surgical_Oi.jpg";
import { getPHRPathId, getPJRPathId, getTHRPathId, getTJRPathId } from "../../StoreFunctions/PRCStoreFunctions";

// import './noitreatment.css'

const NOCOIPath = "Non Operative Candidate - Operational Injection";
const TJRPath = "Operative Candidate - Total Joint Replacement";
const PJRPath = "Operative Candidate - Partial Joint Replacement";
const NOCPath = "Non Operative Candidate";

var QRCode = require("qrcode.react");

class OpCTreatment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DummyJointMapArray: [{ Path: TJRPath, Name: "RIGHT KNEE" }],
    };
  }

  render() {
    const JointMapArray = this.props.JointMapArray;
    // console.log("JointMapArray==>", JointMapArray);
    return JointMapArray.map((joint, id) =>
      ( joint.pathId === getPJRPathId() || joint.pathId === getTJRPathId() || joint.pathId === getTHRPathId() || joint.pathId === getPHRPathId() ) ? (
        <div
          className={`treatment-c-wrapper${id}`}
          key={id}
          id="treatment-c-wrapper"
        >
          <div className="ImageReportMainDiv">
            {/* <div className="pdf-page-number-header">
              Page {id === 0 ? 7 : 9}
            </div> */}
            <div
              className="xray-report-card-header"
              style={{ paddingTop: "10px", height: "130px" }}
            >
              <span style={{ verticalAlign: "top", display: "inline-block" }}>
                {joint.Name}{" "}<span style={{fontSize:"12px"}}> {"(Priority " + joint.priority_id  +")"} </span>
              </span>
              <span
                style={{
                  lineHeight: "40px",
                  paddingLeft: "100px",
                  display: "inline-block",
                  fontSize: "28px",
                  fontWeight: "500",
                }}
              >
                Learn About Joint Replacements
              </span>
            </div>

            <div className="chart-pdf-patient-header">
              {this.context.state.patient.name}{" "}
              <span className="chart-patient-header-middle">
                Evaluation Date : {this.context.state.patient.date}{" "}
              </span>
              <span className="chart-patient-header-right">
                ID: {this.context.state.patient_id}{" "}
              </span>
            </div>
            <div style={{ paddingTop: "20px" }}>
              <div style={{ paddingLeft: "40px" }}>
                <div
                  style={{
                    lineHeight: "30px",
                    fontWeight: "600",
                    fontSize: "23px",
                    display: "inline-block",
                    verticalAlign: "top",
                    width: "calc(70% - 30px)",
                    marginRight: "30px",
                  }}
                >
                  {" "}
                  {/* Left Side */}
                  <div>You are a candidate for surgery.</div>
                  <br />
                  <div>
                    You first step in your journey to wellness is to learn about
                    joint replacements so that you and your provider can have a
                    shared decision on the best treatment plan for you.
                  </div>
                  <br />
                  <div>Start by scanning the Qr Code or goint to:</div>
                  <div>
                    <a href="www.hipandkneesbs.org/journeytowellness">
                      {" "}
                      www.hipandkneesbs.org/journeytowellness{" "}
                    </a>
                  </div>
                </div>
                <div style={{ display: "inline-block", verticalAlign: "top" }}>
                  {" "}
                  {/* Right Side */}
                  <QRCode value="" />
                </div>
              </div>
              <div style={{ paddingTop: "20px" }}>
                {" "}
                {/* Bottom Side */}
                <div
                  className="xray-report-card-header"
                  style={{ paddingTop: "10px", height: "60px" }}
                >
                  <span
                    style={{ verticalAlign: "top", display: "inline-block" }}
                  >
                    {" "}
                    How to Prepare for Surgery{" "}
                  </span>
                </div>
                <div
                  style={{
                    paddingLeft: "40px",
                    textTransform: "uppercase",
                    fontWeight: "600",
                  }}
                >
                  <ul className="RCPNOI-unstyled RCPNOIcentered">
                    {[
                      "Home Exercise",
                      "Physical therapy",
                      "Weight Management",
                      "Nutritional support",
                      "Smoking cessation",
                      "Reducing your anxiety",
                      "Managing your discomfort",
                      "Complementary alternative pain treatments",
                      "Possible joint injection if delaying surgery or surgical date is at least 3 months away",
                    ].map((text, i) => (
                      <li key={i}>
                        <input
                          style={{
                            borderColor: "black",
                            opacity: "1",
                            width: "20px",
                            height: "24px",
                          }}
                          className="RCPNOI-styled-checkbox"
                          id={text}
                          name="non-operative-interventions"
                          type="checkbox"
                        />
                        <label
                          htmlFor={text}
                          style={{ color: "black" }}
                          className="RCPNOI-form-field"
                        >
                          {text}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null
    );
  }
}

OpCTreatment.contextType = MyContext;
export default OpCTreatment;
