import React, { Component } from "react";
import MyContext from "../../helper/themeContext";

import "./JointRcpImage.css";

// import BottomImage from "../../assets/pdf-rcp-bottom-nonop.PNG";
// import BottomImage1 from "../../assets/pdf-rcp-bottom-op.PNG";
import BottomImage1 from "../../assets/pdfAssets/Asset 4Left Knee.png";
import BottomImage2 from "../../assets/pdfAssets/Asset 4Left Knee.png";

import BottomImage3 from "../../assets/pdfAssets/Asset 3Right Knee.png";
import BottomImage4 from "../../assets/pdfAssets/Asset 4Left Knee.png";
import { getAggregate, getJointReplacementThreshold, getPathById, getRatingById } from "../../StoreFunctions/PRCStoreFunctions";
import { getLeftHipId, getLeftKneeId, getRightHipId, getRightKneeId, getUploadedXrayById } from "../../StoreFunctions/evaluationStoreFunctions";
import { Grid } from "@material-ui/core";

const jointClassName = [
  //Right Knee
  {
    joint_id:"3",compartment_id:"1",class1:"image-compartment-label-wrapper-3",  //Medial
  },
  {
    joint_id:"3",compartment_id:"2",class1:"image-compartment-label-wrapper-2", //Lateral
  },
  {
    joint_id:"3",compartment_id:"3",class1:"image-compartment-label-wrapper-1", //Kneecap
  },

  //Left Knee
  {
    joint_id:"4",compartment_id:"1",class1:"image-compartment-label-wrapper-5",
  },
  {
    joint_id:"4",compartment_id:"2",class1:"image-compartment-label-wrapper-6",
  },
  {
    joint_id:"4",compartment_id:"3",class1:"image-compartment-label-wrapper-4",
  },

  //Right Hip
  {
    joint_id:"1",compartment_id:"4",class1:"",  // Ap Pelvis Lateral
  },
  {
    joint_id:"1",compartment_id:"5",class1:"", // Ap Pelvis
  },

  //Left Hip
  {
    joint_id:"2",compartment_id:"4",class1:"",    // Ap Pelvis Lateral
  },
  {
    joint_id:"2",compartment_id:"5",class1:"",    // Ap Pelvis
  }
]

function getJointClass(joint_id,compartment_id)
{
  let findedJoint = jointClassName.find((jC)=>jC.joint_id===joint_id.toString() && jC.compartment_id===compartment_id.toString());
  if(findedJoint)
  {
    return findedJoint.class1;
  }
  else return "";
}

class JointRcpImage extends Component {
  constructor(props) {
    super(props);
    this.state = { JointMapArray: [] };
  }

  componentDidMount() {
    // this.loadJointsRCP();  //Dummy Image
  }

  loadJointsRCP() {
  }

  render() {
    return (
      <React.Fragment>
        {this.props?.JointMapArray.map((joint, id) => (
          <div
            className={`JointImage${id}`}
            style={{

              width: "900px",
              height: "1100px",
              backgroundColor: "white",
              position: "absolute",
              top: [`-1600px`], //-1600px
              left: [`-${3000*(id+1)}px`], //-1400px
              paddingTop: "40px",
              paddingBottom: "20px",
              zIndex:'100'
            }}
          >
            <div className="ImageReportMainDiv">
              {/* <div className="pdf-page-number-header">
                Page {id === 0 ? 6 : 8}
              </div> */}

                <div className="xray-report-card-header">
                  {joint.Name}{" "}<span style={{fontSize:"12px"}}> {"(Priority " + joint.priority_id  +")"} </span>
                  <span style={{ paddingLeft: "100px" }}>
                    {" "}
                  Recommended Care Pathway{" "}
                  </span>
                </div>

                <div className="chart-pdf-patient-header">
                  {this.context.state.patient.name}{" "}
                  <span className="chart-patient-header-middle">
                    {" "}
                  Evaluation Date : {this.context.state.patient.date}{" "}
                  </span>
                  <span className="chart-patient-header-right">
                    {" "}
                  ID: {this.context.state.patient_id}{" "}
                  </span>
                </div>

                <div className="Joint-Rcp-Image-Header-Hr"></div>
                <div className="Joint-Rcp-Image-Box-Wrapper">
                  {
                    (joint.joint_id.toString()===getLeftKneeId()||joint.joint_id.toString()===getRightKneeId())?
                    joint.Compartments.map((comp,id)=>         
                    <div className="Joint-Rcp-Image-Compartment-Box-Wrapper">
                      <div className="Joint-Rcp-Image-Compartment-Head">{comp.name}</div>
                      <div className="Joint-Rcp-Image-Compartment-Box"
                        style={{ background: getRatingById(comp.score.toString()).color }}
                      >
                        <span className="Centerer"></span>
                        <span className="Centered"> { getRatingById(comp.score.toString()).name} </span>
                      </div>
                    </div>
                    ):
                    (
                      <div className="Joint-Rcp-Image-Compartment-Box-Wrapper">
                        <div className="Joint-Rcp-Image-Compartment-Head"> Xray </div>
                        <div className="Joint-Rcp-Image-Compartment-Box"
                          style={{ background: getRatingById(getAggregate(joint.Compartments[0].score,joint.Compartments[1].score).toString()).color}}
                        >
                          <span className="Centerer"></span>
                          <span className="Centered"> {getRatingById(getAggregate(joint.Compartments[0].score,joint.Compartments[1].score).toString()).name} </span>        
                        </div>
                      </div>
                    )
                  }
                  
                  <div className="Joint-Rcp-Image-Score-Box-Wrapper">
                    <div className="Joint-Rcp-Image-Compartment-Head">Survey</div>
                    <div
                      className="Joint-Rcp-Image-Score-Box"
                      style={{
                        background: joint.Score < getJointReplacementThreshold() ? "#F26E82" : "#B3D89B",
                      }}
                    >
                      <span className="Centerer"></span>
                      <span className="Centered"> {joint.Score} </span>
                    </div>
                  </div>
                  <div className="Joint-Rcp-Image-Compartment-Hr"></div>
                  <div className="Joint-Rcp-Image-Compartment-Path">
                    { getPathById(joint.pathId).label2 }
                  </div>
                </div>

                {/* BOTTOM SECTION FOR HIP START */}
                {
                  ((joint.joint_id === getRightHipId()) || (joint.joint_id === getLeftHipId())) &&
                  <Grid
                    container
                    spacing={2}
                    style={{ padding: "0px 20px", marginTop: "20px" }}
                  >
                    {getUploadedXrayById(joint.joint_id,this.context).map((xray, key) => (
                      <Grid item md={4} key={key}>
                        <p className="xrayImage-pdf-image-head">{xray.name}</p>
                        <img src={xray.image} style={{ maxWidth: "100%" }} alt={`Xray ${key+1}`}/>
                      </Grid>
                    ))}
                  </Grid>
                 }
                {/* BOTTOM SECTION FOR HIP END */}


                {/* BOTTOM SECTION FOR KNEE START */}
                {
                  ((joint.joint_id === getRightKneeId()) || (joint.joint_id === getLeftKneeId())) &&
                  <React.Fragment>
                    <div style={{ marginLeft: "60px" }}>
                      <img
                        style={{ width: "90%" }}
                        src={
                          joint.Name === "Right Knee" ? BottomImage3 : BottomImage4
                        }
                        alt="Treatment Info"
                      />
                    </div>
                    {/* Compartments 1.Medial 2.Lateral 3.Kneecap 4.ApPelvis 5.LateralPelvis */}
                    {
                      joint.Compartments.map((c)=>
                      <div
                        style={{ background: getRatingById(c.score.toString()).color }}
                        className={`image-compartment-label-wrapper ${getJointClass(joint.joint_id,c.id)}`}
                      >
                        <span className="image-compartment-label">
                          {" "}
                            {getRatingById( c.score.toString()).name } 
                          {" "}
                        </span>
                      </div>
                      )
                    }
                  </React.Fragment>
                }
                {/* BOTTOM SECTION FOR KNEE END */}

              </div>
            </div>
          )
        )}
      </React.Fragment>
    );
  }
}

JointRcpImage.contextType = MyContext;
export default JointRcpImage;
