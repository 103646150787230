import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import NoMatching from '../../assets/no_matching.png'
import NotesIcon from '../../assets/notes.png'
import html2canvas from 'html2canvas'

import './matching.css'
import MyContext from '../../helper/themeContext';
import GetDataJson from '../../Fetch/getDataJson';
import GetImage from '../../Fetch/getImage';
import PostFormData from './uploadPostForm';

import ImageViewModalWithZoom from '../../components/ImageViewModal/ImageViewModalWithZoom';
import { getApPelvisCompartmentId, getApPelvisLateralCompartmentId, getLeftHipId, getLeftKneeId, getRightHipId, getRightKneeId } from '../../StoreFunctions/evaluationStoreFunctions';
import HipsOverlay from '../../components/Overlay/HipsOverlay';

class Matching extends Component {
    constructor(props) {
        super(props);
        this.state = {
            temp_url: null, image: null,
            Notes: '', openModal: false, openModal1: false, fullXrayModalState: false, tempNotes: null, textIndent: '0px', Aiprediction: 5, loadingImage: true
        }
    }

    componentDidMount() {
        // console.log('eval==->', this.context.state, this.context.state.Eval)
        if (this.context.state.Eval[this.context.state.activeJointIndex].joint_id.toString() === '3') {
            if (this.props.ActiveXray === "Flexion View") {
                this.setState({ textIndent: '200px' })
            }
            else if (this.props.ActiveXray === "Non-Flexion View") {
                this.setState({ textIndent: '250px' })
            }
            else this.setState({ textIndent: '150px' })

        }

        else if (this.context.state.Eval[this.context.state.activeJointIndex].joint_id.toString() === '4') {

            if (this.props.ActiveXray === "Flexion View") {
                this.setState({ textIndent: '180px' })
            }
            else if (this.props.ActiveXray === "Non-Flexion View") {
                this.setState({ textIndent: '230px' })
            }
            else this.setState({ textIndent: '130px' })
        }

        let current_joint_id = this.props.eval.joint_id;
        let currentProcessedCompartment = this.props.eval.Xrays[this.props.ActiveTypeIndex];
        let currProccessedXray = currentProcessedCompartment.xrays[this.props.ActiveXrayIndex];
        let imageExist = false;
        let xrayImage = "";
        if (currProccessedXray.image && currProccessedXray.image != null) {
            if (currProccessedXray.image.toString() !== "" && currProccessedXray.image.toString() !== " " && currProccessedXray.image.toString() !== 'null') { imageExist = true; }
        }

        //setting xray image
        if (currProccessedXray.name === "Flexion View") {
            xrayImage = this.context.state.Xrays.find((xray) => xray.id.toString() === "7").image
            // console.log('knee Image => ', xrayImage);
        }

        else if (currProccessedXray.name === "Non-Flexion View") {
            xrayImage = this.context.state.Xrays.find((xray) => xray.id.toString() === "1").image
        }

        else if (currProccessedXray.name === "Kneecap") {
            // console.log('context xrays => ', this.context.state.Xrays);
            xrayImage = this.context.state.Xrays.find((xray) => (xray.id.toString() === "6" || xray.id.toString() === "2" || xray.id.toString() === "4")).image
            // console.log('knee Image => ', xrayImage);
        }
        else if (currentProcessedCompartment.id === getApPelvisCompartmentId()) {
            //For Left Right Hip Both Zones
            xrayImage = this.context.state.Xrays.find((xray) => (xray.id.toString() === "8")).image
        }
        else if (currentProcessedCompartment.id === getApPelvisLateralCompartmentId() && current_joint_id === getLeftHipId()) {
            //For Left Hip Zone 1 and Zone 2
            xrayImage = this.context.state.Xrays.find((xray) => (xray.id.toString() === "9")).image
        }
        
        else if (currentProcessedCompartment.id === getApPelvisLateralCompartmentId() && current_joint_id === getRightHipId()) {
            xrayImage = this.context.state.Xrays.find((xray) => (xray.id.toString() === "10")).image
        }
       
        let Aiprediction = 5;
        if (currProccessedXray.prediction === "normaltoslight" || currProccessedXray.prediction === "normal") {
            Aiprediction = 1;
        }
        else if (currProccessedXray.prediction === "moderate") {
            Aiprediction = 2;
        }
        else if (currProccessedXray.prediction === "nearendstage") {
            Aiprediction = 3;
        }
        else if (currProccessedXray.prediction === "endstage") {
            Aiprediction = 4;
        }
        else {
            Aiprediction = 5;
        }

        if (currProccessedXray.roi_updated && currProccessedXray.roi_updated.toString() === "1") {
            this.setState({ Active: Aiprediction.toString(), Aiprediction: Aiprediction, imageExist: true, xrayImage: xrayImage, image: currProccessedXray.roi_updated_url })
        }
        else {
            this.setState({ Active: Aiprediction.toString(), Aiprediction: Aiprediction, imageExist: imageExist, xrayImage: xrayImage })
            this.GetImage();
        }

    }

    GetImage = () => {
        let imageId = this.props.eval.Xrays[this.props.ActiveTypeIndex].xrays[this.props.ActiveXrayIndex].image;
        let joint_id = this.props.eval.id

        if (imageId && imageId != null && imageId.toString() !== 'null' && imageId.toString() !== "" && imageId.toString() !== " ") {
            let req = {
                imageId: imageId,
                joint_id:joint_id
            }
            this.setState({ loadingImage: true })
            GetDataJson(this.context.baseUrl + '/api/v1/digest', 200, req, this.context.state.token, this.setDigest)
        }

        else this.setState({ loadingImage: false })
    }

    setDigest = (response) => {
        if (response.ResponseCode === "Success") {
            let Digest = response.Digest;
            let TimeStamp = response.TimeStamp;
            let ImageId = response.ImageId;
            let apiKey = response.apiKey;
            let Nonce = response.Nonce;
            let baseUrl = response.baseUrl;

            // console.log('getiing image')
            // console.log('baseUrl:', baseUrl);
            // console.log('ImageId:', ImageId);
            // console.log('Digest:', Digest);
            // console.log('TimeStamp:', TimeStamp);
            // console.log('apiKey:', apiKey);
            // console.log('Nonce:', Nonce);


            GetImage(baseUrl + '/' + ImageId, 200, apiKey, TimeStamp, Nonce, Digest, this.setImage);
        }
        else this.setState({ loadingImage: false })
    }

    setImage = (response) => {
        this.setState({ image: response, loadingImage: false })
    }


    Submit = () => {
        this.setState({ Notes: this.state.tempNotes, openModal: false });
    }

    textAreaChange = (e) => {
        this.setState({ tempNotes: e.target.value });
    }

    handleModalClose = () => {
        this.setState({ openModal: false })
    }
    handleModalOpen = () => {
        this.setState({ openModal: true, tempNotes: this.state.Notes })
    }


    textAreaChange1 = (e) => {
        this.setState({ tempNotes1: e.target.value });
    }

    Submit1 = () => {
        this.setState({ Notes1: this.state.tempNotes1, openModal1: false });
    }

    handleModalClose1 = () => {
        this.setState({ openModal1: false })
    }
    handleModalOpen1 = () => {
        this.setState({ openModal1: true, tempNotes1: this.state.Notes1 })
    }

    handleClick = (id) => {
        this.setState({ Active: id })
    }

    handleConfirmClick = () => {
        if (this.state.Active) {
            this.props.handleClick(this.state.Active, this.state.Notes)
        }
    }

    handleConfirmFromModal = () => {
        this.setState({ Notes1: this.state.tempNotes1, openModal1: false });
        if (this.state.Active) {
            let addonNotes = "";
            if (this.state.Active.toString() === "5") {
                if (this.state.tempNotes1) {
                    addonNotes = "Cannot Eval: " + this.state.tempNotes1
                }

                else addonNotes = "Cannot Eval: No Notes";

            }

            else if (this.state.Active.toString() !== this.state.Aiprediction.toString()) {
                if (this.state.tempNotes1) {
                    addonNotes = "Change Eval: " + this.state.tempNotes1
                }

                else addonNotes = "Change Eval: No Notes";
            }
            this.props.handleClick(this.state.Active, this.state.Notes + addonNotes)
        }
    }

    fullXrayModalClose = () => {
        this.setState({ fullXrayModalState: false })
    }

    fullXrayModalOpen = () => {
        this.setState({ fullXrayModalState: true })
    }

    updateView = async () => {
        //start loader

        console.log("inside update View")
        this.setState({ backdrop: true })
        let global = this;
        let processed_xray_id = this.props.eval.Xrays[this.props.ActiveTypeIndex].xrays[this.props.ActiveXrayIndex].processed_xray_id;

        console.log("page two debug on")

        let pagetwo = document.getElementById("Myfigure");

        console.log("page two debug finished")

        await new html2canvas(pagetwo, {
            useCORS: true,
            allowTaint: false,
            letterRendering: true,
            logging: true,
        }).then(async function (canvas2) {

            console.log("canvas console debugging")


            // const link = document.createElement('a');
            // link.download = "myfile";
            // link.href    = canvas2.toDataURL("image/png")
            // link.click();

            var file = global.dataURItoBlob(canvas2.toDataURL("image/png"));


            let form_data = new FormData();
            form_data.append('visitor_id', global.context.state.report_id)
            form_data.append('processed_xray_id', processed_xray_id)
            form_data.append('image', file)
            global.setState({ temp_url: canvas2.toDataURL("image/png"), })

            console.log("form data set")

            PostFormData(global.context.baseUrl + '/api/v1/update-roi', 200, form_data, global.context.state.token, global.setNewRoi)
            console.log("after posting")

        }).catch((e)=> {
            this.setState({ backdrop: false })
            alert("Image too large to handle!")
            console.log("Error at html2canvas",e)
        })
    }

    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

    setNewRoi = (response) => {
        //loader false
        this.setState({ backdrop: false, image: this.state.temp_url, imageExist: true })
    }
    render() {
        const joint_id = this.context.state.Eval[this.context.state.activeJointIndex].joint_id.toString()
        return (
            <div>
                <div id="Evaluaion_XrayMatching_Matching_Content1_Wrapper">
                    <div id="Evaluaion_XrayMatching_Matching_Heading1_Div">
                        Confirm Evaluation
                    </div>
                    <div id="Evaluaion_XrayMatching_Matching_Heading2_Div">
                        Confirm the levels of degeneration by clicking on the other levels and matching your X-ray to
                        the comparison X-ray.
                    </div>
                    {
                        [{ name: 'Normal to Slight', id: '1' }, { name: 'Moderate', id: '2' }, { name: 'Near End Stage', id: '3' }, { name: 'End Stage', id: '4' }, { name: 'Cannot Evaluate', id: '5' }].map((text, id) =>
                            <div className="Evaluaion_XrayMatching_Matching_State_Button_Div" key={id}>
                                {
                                    this.state.Aiprediction.toString() === text.id ?
                                        <div className="Evaluaion_XrayMatching_Matching_State_Button_Prediction_Box">
                                            <span> AI Result </span>
                                        </div>
                                        : null
                                }
                                <Button className="Evaluaion_XrayMatching_Matching_State_Button" style={{ border: text.id.toString() === this.state.Aiprediction.toString() ? '4px orange solid' : '1px hsla(0, 0%, 100%, 0.34) solid', color: this.state.Active === text.id.toString() ? text.id.toString() === "5" ? 'white' : '' : '#fff', background: this.state.Active === text.id.toString() ? text.id.toString() === "5" ? 'red' : '#fffb00' : 'hsla(0, 0%, 100%, 0.08)' }} variant="contained" onClick={() => this.handleClick(text.id)}> {text.name} </Button>
                            </div>
                        )
                    }
                    <div style={{ display: 'block', width: '100%' }}>
                        <div className="Evaluaion_XrayMatching_Matching_Confirm_Button_Div" >
                            <Button className="Evaluaion_XrayMatching_Matching_Confirm_Button" disabled={!this.state.Active} variant="contained" onClick={this.state.Active ? this.state.Active.toString() === "5" ? this.handleModalOpen1 : this.state.Active.toString() === this.state.Aiprediction.toString() ? this.handleConfirmClick : this.handleModalOpen1 : null}> {this.state.Active ? this.state.Active.toString() === this.state.Aiprediction.toString() ? "Accept Evaluation" : "Change Evaluation" : "Confirm Evaluation"} </Button>
                        </div>
                        {
                            this.state.Active &&
                            <div className="Evaluaion_XrayMatching_Matching_AddNotes_Button_Div">
                                <Button className="Evaluaion_XrayMatching_Matching_AddNotes_Button" variant="contained" onClick={this.handleModalOpen}> Add Notes <img style={{ marginLeft: '15px' }} alt="Notes" src={NotesIcon} /> </Button>
                            </div>
                        }
                    </div>

                </div>
                <div id="Evaluaion_XrayMatching_Matching_Content2_Wrapper">
                    <div id="Evaluaion_XrayMatching_Matching_Heading3_Div">
                        {this.props.eval.name.toUpperCase()} - {this.props.ActiveType.toString() === "Kneecap" ? 'KNEECAP' : <span> {this.props.ActiveType.toUpperCase()}  <br /> <span> {this.props.ActiveXray.toUpperCase()} </span>  </span>}
                    </div>
                    <div className="Evaluaion_XrayMatching_Matching_Xray_Image_Wrapper">
                        {/* <img className="Evaluaion_XrayMatching_Matching_Xray_Image" src={this.props.eval.Xrays[this.props.ActiveTypeIndex].xrays[this.props.ActiveXrayIndex].up}/> */}
                        {
                            this.state.imageExist ?
                                <React.Fragment>
                                    {
                                        ((joint_id.toString() === getLeftKneeId()) || (joint_id.toString() === getRightKneeId())) ?
                                            <div className="Evaluaion_XrayMatching_Matching_Xray_Image_Cropper" style={{ background: `url(${this.state.image})` }}>
                                                {/* // <img alt="Patient Xray" className="Evaluaion_XrayMatching_Matching_Xray_Image" src={this.state.image}/> */}
                                            </div>
                                        :
                                            <img alt="Patient Xray" className="Evaluaion_XrayMatching_Matching_Xray_Image" src={this.state.image}/>
                                    }

                                    <div className="Evaluaion_XrayMatching_Matching_View_FullXray_Button_Div">
                                        <Button id="Evaluaion_XrayMatching_Matching_View_FullXray_Button" variant="contained" onClick={this.fullXrayModalOpen}> View Full Xray </Button>
                                    </div>
                                    <div className="Evaluaion_XrayMatching_Matching_Image_Label1">
                                        Patient's X-ray
                                        </div>

                                    {/* WHITE BOX FOR LATERAL AND MEDIAL ONLY */}
                                    {
                                        ((joint_id.toString() === getLeftKneeId()) || (joint_id.toString() === getRightKneeId())) &&
                                        (this.props.ActiveType.toString().toUpperCase() === "MEDIAL" ?
                                            <div className={`${this.context.state.Eval[this.context.state.activeJointIndex].joint_id.toString() === '3' ? 'Evaluaion_XrayMatching_Matching_View_White_Box_Right' : 'Evaluaion_XrayMatching_Matching_View_White_Box_Left'} `} >
                                            </div>
                                            : this.props.ActiveType.toString().toUpperCase() === "LATERAL" ? //flipping whitebox
                                                <div className={`${this.context.state.Eval[this.context.state.activeJointIndex].joint_id.toString() === '4' ? 'Evaluaion_XrayMatching_Matching_View_White_Box_Right' : 'Evaluaion_XrayMatching_Matching_View_White_Box_Left'} `} >
                                                </div>
                                                : null)
                                    }
                                    <HipsOverlay joint_id={joint_id} ActiveType={this.props.ActiveXray.toString().toUpperCase()} />



                                </React.Fragment>
                                :
                                <div className="Evaluaion_XrayMatching_Matching_NoXray_Box">
                                    <div className="Evaluaion_XrayMatching_Matching_View_NoImage_FullXray_Text_Div">
                                        CANNOT IDENTIFY <br /> REGION OF INTEREST
                                    </div>
                                    <div className="Evaluaion_XrayMatching_Matching_View_FullXray_Button_Div">
                                        <Button id="Evaluaion_XrayMatching_Matching_View_FullXray_Button" variant="contained" onClick={this.fullXrayModalOpen}> View Full Xray </Button>
                                    </div>
                                </div>
                        }
                    </div>

                    {
                        this.state.Active != null ?
                            // <div className={`Evaluaion_XrayMatching_Matching_Xray_Image_Wrapper  ${this.context.state.joint_id.toString()==='4'?'flipme':''}`}>
                            // <div className={`Evaluaion_XrayMatching_Matching_Xray_Image_Wrapper ${ ( (joint_id === '4') || (joint_id === "2" && this.props.ActiveType.toString().toUpperCase() === "LATERAL") ) ? 'flipme' : ''} `} >
                            <div className={`Evaluaion_XrayMatching_Matching_Xray_Image_Wrapper ${ ( (joint_id === '4') || (joint_id === "2") ) ? 'flipme' : ''} `} >

                                <img alt="Comparing Xray" className="Evaluaion_XrayMatching_Matching_Xray_Image_Compairing" src={this.props.eval.Xrays[this.props.ActiveTypeIndex].xrays[this.props.ActiveXrayIndex][`up${this.state.Active}`]} />
                                <div className="Evaluaion_XrayMatching_Matching_Image_Label3">
                                    Comparison X-ray
                            </div>
                            </div>

                            :
                            <div className="matching-down" >
                                <img className="Evaluaion_XrayMatching_Matching_Xray_Image_Compairing" alt="Select A Comparision" src={NoMatching} />
                                <div className="Evaluaion_XrayMatching_Matching_Image_Label2">
                                    Comparison X-ray
                            </div>
                            </div>

                    }

                </div>

                <Modal
                    open={this.state.openModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{ position: 'absolute', left: 'calc(50vw - 250px)', top: 'calc(50vh - 250px)' }}
                >
                    <div className="Evaluaion_XrayMatching_Matching_Modal_Div">

                        <div className="Evaluaion_XrayMatching_Matching_Modal_Heading">
                            Notes
                        </div>
                        <div className="Evaluaion_XrayMatching_Matching_Modal_Notes_Div" >
                            <div className="Evaluaion_XrayMatching_Matching_Modal_Notes_Heading">{(this.context.state.Eval[this.context.state.activeJointIndex].joint_id.toString() === '3' ? "Right " : "Left ") + (this.props.ActiveXray.toString() === "Flexion View" ? "Flexion " + this.props.ActiveType + " : " : this.props.ActiveXray === "Non-Flexion View" ? "Non-Flexion " + this.props.ActiveType + " : " : "Kneecap : ")}</div>
                            <textarea style={{ textIndent: this.state.textIndent }} className="Evaluaion_XrayMatching_Matching_Modal_Notes_TextArea" value={this.state.tempNotes} onChange={this.textAreaChange}>
                            </textarea>
                        </div>
                        <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                            <div className="Evaluaion_XrayMatching_Matching_Modal1_Button_Div">
                                <Button className="Evaluaion_XrayMatching_Matching_AddNotes_Button" variant="contained" onClick={this.Submit}> Submit </Button>
                            </div>
                            <div className="Evaluaion_XrayMatching_Matching_Modal2_Button_Div">
                                <Button className="Evaluaion_XrayMatching_Matching_Modal2_Button" variant="outlined" onClick={this.handleModalClose}> Cancel </Button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={this.state.openModal1}
                    aria-labelledby="confirm-modal-title"
                    aria-describedby="confirm-modal-description"
                    style={{ position: 'absolute', left: 'calc(50vw - 250px)', top: 'calc(50vh - 250px)' }}
                >

                    <div className="Evaluaion_XrayMatching_Matching_Modal_Div1">

                        <div className="Evaluaion_XrayMatching_Matching_Modal_Heading1">
                            {this.state.Active ? this.state.Active.toString() === '5' ? 'Cannot Evaluate' : this.state.Aiprediction.toString() !== this.state.Active.toString() ? 'Evaluation Change' : null : null}
                        </div>

                        <div className="Evaluaion_XrayMatching_Matching_Modal_Description">
                            {this.state.Active ? this.state.Active.toString() === '5' ? 'Why are you unable to evaluate this compartment' : this.state.Aiprediction.toString() !== this.state.Active.toString() ? 'Are you sure you want to update evaluation' : null : null}
                        </div>

                        <div className="Evaluaion_XrayMatching_Matching_Modal_Sub_Head">
                            Notes
                        </div>

                        <div className="Evaluaion_XrayMatching_Matching_Modal_Notes_Div" >
                            <div className="Evaluaion_XrayMatching_Matching_Modal_Notes_Heading">
                                {this.state.Active ? this.state.Active.toString() === '5' ? 'Cannot Eval: ' : this.state.Aiprediction.toString() !== this.state.Active.toString() ? 'Eval Change: ' : null : null}
                            </div>
                            <textarea style={{ textIndent: this.state.Active ? this.state.Active.toString() === '5' ? '105px' : '112px' : '' }} className="Evaluaion_XrayMatching_Matching_Modal_Notes_TextArea" value={this.state.tempNotes1} onChange={this.textAreaChange1}>
                            </textarea>
                        </div>
                        <div style={{ marginTop: '20px', marginLeft: '50px' }}>
                            <div className="Evaluaion_XrayMatching_Matching_Modal1_Button_Div">
                                <Button className="Evaluaion_XrayMatching_Matching_AddNotes_Button" variant="contained" onClick={this.handleConfirmFromModal}> Submit </Button>
                            </div>
                            <div className="Evaluaion_XrayMatching_Matching_Modal2_Button_Div">
                                <Button className="Evaluaion_XrayMatching_Matching_Modal2_Button" variant="outlined" onClick={this.handleModalClose1}> Cancel </Button>
                            </div>
                        </div>
                    </div>

                </Modal>

                <ImageViewModalWithZoom loader={this.state.backdrop} showUpdateView={true} updateView={this.updateView} modalState={this.state.fullXrayModalState} modalClose={this.fullXrayModalClose} ActiveImage={this.state.xrayImage} />

            </div>
        );
    }
}
Matching.contextType = MyContext;
export default Matching;