import React, { Component } from 'react';
import './patientReport.css';
import Button from '@material-ui/core/Button';

import MyContext from '../../../helper/themeContext';

import Tick from '../../../assets/button-tick.png';
import { getJointNameById, getProStageId } from '../../../StoreFunctions/evaluationStoreFunctions';

class ManualFormWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount()
    {
        // console.log('On Manual Wrapper');
    }

    handleClick = () => {
        if (this.context.state.Eval.length > 1) {
            this.props.handleShowMultiChart();
        }
        else this.context.history.push('./patient-profile')
    }

    render() {
        /* currentFormJointSampleArray = [
            {
                {isDone:false,joint_id:'2'})
            }
        ] */
        let {currentFormJoints} = this.props;

        return (
            <div id="Evaluaion_Forms_Main_Div" >
                <div id="Evaluaion_Forms_Content1_Wrapper" >
                    <div id="Evaluaion_Forms_Heading1_Div">
                        Patient Reported Survey
                    </div>
                    <div id="Evaluaion_Forms_Box1_Div" >
                        {
                            currentFormJoints.map((cf,id)=>
                                <React.Fragment key={id}>
                                <div className="Evaluaion_Forms_Box1_Content1">
                                    <img className="Evaluaion_Forms_Box1_Content1_Image" src="https://assets.website-files.com/5d8d4f718f129fc077e4b98d/5d9e0d14916a658e42a03643_Asset%202doc.png" width="20px" alt="" />
                                </div>
                                <div className="Evaluaion_Forms_Box1_Content2">
                                    {
                                        cf.isDone === true ?
                                        <div>
                                            <div className="Evaluaion_Forms_Box_DisbaleText">
                                                {getJointNameById(cf.joint_id)} Survey
                                            </div>
                                            <div className="Evaluaion_Forms_Box_DisbaleText2"> <img src={Tick} alt="Completed" /> &nbsp; Complete </div>
                                        </div>
                                        :
                                        <Button className="Evaluaion_Forms_Box_Button" variant="contained"
                                            disabled={id>0 && currentFormJoints[id - 1].isDone===false} onClick={() => { this.props.setActive(cf.joint_id) }} >
                                            Enter {getJointNameById(cf.joint_id)} Survey
                                        </Button>

                                    }
                                    
                                </div>
                            </React.Fragment>
                            )
                        }
                                
                        { currentFormJoints.find((cF)=>cF.isDone === false)  ?
                            null :
                            <div id="Evaluaion_Forms_Next_Button_Div">
                                <Button id="Evaluaion_Forms_Next_Button" variant="contained"
                                    onClick={this.handleClick} > Next </Button>
                            </div>
                        }
                    </div>
                    <div id="Evaluaion_NewEvaluation_Back_Button_Div1">
                        <Button id="Evaluaion_NewEvaluation_Next_Button"
                            variant="contained"
                            onClick={() => this.context.history.push('./patient-profile')}> Back
                            </Button>
                    </div>
                </div>
            </div>);
    }
}
ManualFormWrapper.contextType = MyContext;
export default ManualFormWrapper;