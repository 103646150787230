import React, { Component } from "react";
import MyContext from "../../helper/themeContext";

import "./xrayImages.css";

import { Grid } from "@material-ui/core";

class XrayImages extends Component {
  constructor(props) {
    super(props);
    this.state = { JointMapArray: [] };
  }

  render() {
    const {isDevTesting} = this.props;
    // console.log("xrayimages  here------->", this.context.state.Xrays);
    return (
      <div className="pdf-xrays-images-wrapper" style={{position:isDevTesting? "relative":"absolute" , top:isDevTesting ? "0px" : "-1600px", left:isDevTesting ? "0px" : "2000px"}}>
        <div className="ImageReportMainDiv">
          {/* <div className="pdf-page-number-header">Page 5</div> */}
          <div className="xray-report-card-header">
            KNEE
            <span style={{ textAlign: "center", paddingLeft: "200px" }}>
              Patient X-rays
            </span>
          </div>

          <div className="chart-pdf-patient-header">
            {this.context.state.patient.name}{" "}
            <span className="chart-patient-header-middle">
              {" "}
              Evaluation Date : {this.context.state.patient.date}{" "}
            </span>
            <span className="chart-patient-header-right">
              {" "}
              ID: {this.context.state.patient_id}{" "}
            </span>
          </div>

          <Grid
            container
            spacing={2}
            style={{ padding: "0px 20px", marginTop: "20px" }}  >
            {/* <Grid item md={6}>
              <p className="xrayImage-pdf-image-head">
                {this.context.state.Xrays[0].name}
              </p>
              <img
                src={this.context.state.Xrays[0].image}
                style={{ maxWidth: "100%" }}
              />
            </Grid>
            <Grid item md={6}>
              <p className="xrayImage-pdf-image-head">
                {this.context.state.Xrays[1].name}
              </p>
              <img
                src={this.context.state.Xrays[1].image}
                style={{ maxWidth: "100%" }}
              />
            </Grid> */}

            {this.context.state.Xrays.map((xray, key) => (
              <Grid item md={4} key={key}>
                <p className="xrayImage-pdf-image-head">{xray.name}</p>
                <img src={xray.image} style={{ maxWidth: "100%" }} alt={`Xray ${key+1}`}/>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    );
  }
}

XrayImages.contextType = MyContext;
export default XrayImages;
