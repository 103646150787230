import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './selected.css'
import MyContext from '../../helper/themeContext';
import GetDataJson from '../../Fetch/getDataJson';
import GetImage from '../../Fetch/getImage';

const predictions = [
    { prediction:'1',name:'normal' },
    { prediction:'2',name:'moderate' },
    { prediction:'3',name:'nearendstage' },
    { prediction:'4',name:'endstage' },
    { prediction:'5',name:'cannot evaluate' },
]


class Selected extends Component {

    
    constructor(props) {
        super(props);
        this.state = {image:null}
    }
    componentDidMount()
    {
        let imageExist = false;
        if(this.props.XrayImage && this.props.XrayImage!=null )
        {
            if(this.props.XrayImage.toString()!=="" && this.props.XrayImage.toString()!==" " &&  this.props.XrayImage.toString()!=='null')
            { imageExist = true; }
        }
        if(this.props.roi_updated && this.props.roi_updated.toString() === "1")
        {
            this.setState({imageExist:true,image:this.props.roi_updated_url})
        }
        else
        {
            this.setState({imageExist:imageExist})
            this.GetImage(); 
        }
    }

    GetImage = () =>
    {
        let imageId = this.props.XrayImage;
        if(imageId && imageId!=null && imageId.toString()!=='null' && imageId.toString()!==""  && imageId.toString()!==" ")
        {
            let req={
                imageId:imageId
            }
            this.setState({loadingImage:true})
            // console.log('getiing digest for image id=>' ,imageId)
            GetDataJson(this.context.baseUrl+'/api/v1/digest',200,req,this.context.state.token,this.setDigest)
        }

        else this.setState({loadingImage:false})
    }

    setDigest = (response) =>
    {
        // console.log('in set digest =>',response)
        if(response.ResponseCode==="Success")
        {
            let Digest = response.Digest;
            let TimeStamp = response.TimeStamp;
            let ImageId = response.ImageId;
            let apiKey = response.apiKey;
            let Nonce = response.Nonce;
            let baseUrl =  response.baseUrl;

            // console.log('getiing image')
            // console.log('baseUrl:',baseUrl);
            // console.log('ImageId:',ImageId);

            GetImage(baseUrl+'/'+ImageId,200,apiKey,TimeStamp,Nonce,Digest,this.setImage);
        }
        else this.setState({loadingImage:false})
    }

    setImage = (response) =>
    {
        // console.log('response image :',response)
        this.setState({image:response,loadingImage:false})
    }
    

    handleClick = (e) =>
    {
        this.props.handleState(null,null,null,null,null,null,null,null,null);
        this.props.handleView("Report")
    }
    
    render() { 
        return (  
            <div id="Evaluaion_Report_Selected_Main_Div">
                <div  id="Evaluaion_Report_Selected_Content1_Wrapper">
                    <div id="Evaluaion_Report_Selected_Heading1_Div">
                        X-Ray Matching
                    </div>
                    <div id="Evaluaion_Report_Selected_Heading2_Div">
                        Click on the levels of degeneration to view X-rays from the database.
                        Once you believe you have found a match, click "Confirm Evaluation" to move onto the next view.
                    </div>
                    {
                        [{name:'Normal to Sight',id:'1'},{name:'Moderate',id:'2'},{name:'Near End Stage',id:'3'},{name:'End Stage',id:'4'},{name:'Cannot Evaluate',id:'5'}].map((text,id)=>
                        <div className="Evaluaion_XrayMatching_Matching_State_Button_Div" key={id}>
                            {
                                this.props.Aiprediction? predictions.find( el => el.name===this.props.Aiprediction).prediction===text.id?
                                <div className="Evaluaion_XrayMatching_Matching_State_Button_Prediction_Box">
                                    <span> SBS AI Prediction </span> 
                                </div>
                                :null
                                :text.id==="5"?
                                    <div className="Evaluaion_XrayMatching_Matching_State_Button_Prediction_Box">
                                        <span> SBS AI Prediction </span> 
                                    </div>
                                :null
                            }
                            <Button className="Evaluaion_XrayMatching_Matching_State_Button" style={{border:text.id.toString()===this.props.State.toString()?'4px orange solid':'1px hsla(0, 0%, 100%, 0.34) solid',color:this.props.State===text.id.toString()?text.id.toString()==="5"?'white':'':'#fff', background:this.props.State===text.id.toString()?text.id.toString()==="5"?'red':'#fffb00':'hsla(0, 0%, 100%, 0.08)'}} variant="contained" onClick={()=>this.handleClick(text.id)}> {text.name} </Button>
                        </div>
                        )
                    }
                    <div style={{display:'block',width:'100%'}}>
                        <div className="Evaluaion_Report_Selected_Confirm_Button_Div" >
                            <Button className="Evaluaion_Report_Selected_Confirm_Button" variant="contained" onClick={this.handleClick}> Back to Report Card </Button>
                        </div>                
                    </div>
                    
                </div>
                <div  id="Evaluaion_Report_Selected_Content2_Wrapper">
                    <div id="Evaluaion_Report_Selected_Heading3_Div">
                        {this.props.JointName.toUpperCase()} - {this.props.Type=="Kneecap"?"KNEECAP": <span> { this.props.Type.toUpperCase()}  <br/> { this.props.Xray.toUpperCase()} </span> }
                    </div>
                    <div className="Evaluaion_XrayMatching_Matching_Xray_Image_Wrapper">
                        {
                        this.state.imageExist?
                            <React.Fragment>
                                <div className="Evaluaion_XrayMatching_Matching_Xray_Image_Cropper" style={{background:`url(${this.state.image})`}}>
                                </div>
                                {/* <img className="Evaluaion_XrayMatching_Matching_Xray_Image" src={this.state.image}/> */}
                                <div className="Evaluaion_XrayMatching_Matching_Image_Label1">
                                    YOUR PATIENT
                                </div>
                            </React.Fragment>
                        :
                            <div className="Evaluaion_XrayMatching_Matching_NoXray_Box">
                                <div className="Evaluaion_XrayMatching_Matching_View_NoImage_FullXray_Text_Div">
                                    CANNOT IDENTIFY <br/> REGION OF INTEREST
                                </div>
                                {/* <div className="Evaluaion_XrayMatching_Matching_View_FullXray_Button_Div">
                                    <Button id="Evaluaion_XrayMatching_Matching_View_FullXray_Button" variant="contained" onClick={this.fullXrayModalOpen}> View Full Xray </Button>
                                </div> */}
                            </div>
                        }   
                    </div>
                   
                    <div className={`Evaluaion_XrayMatching_Matching_Xray_Image_Wrapper ${this.context.state.Eval[this.context.state.activeJointIndex].joint_id.toString()==='4'?'flipme':''} `}>
                        <img className="Evaluaion_XrayMatching_Matching_Xray_Image_Compairing" src={this.props.MatchingXray} />
                    </div>

                    {/* <div>
                        <img src={this.props.image}/>
                    </div> */}
                     
                </div>

            
            </div>
        );
    }
}

Selected.contextType=MyContext;
export default Selected;