
const   PostData =  async(url,statusCode,req,token,callback) => {


  // console.log(req)
  let object;
  let searchParams="";
  let arrayKey=0;
  req.forEach(element => {

    if(arrayKey>0)
    {
      searchParams=searchParams+'&';
    }

    searchParams = searchParams + Object.keys(element).map((key) => {
      if(key==='isEvaluated' || key==="name" || key==="joint_hurt_id")
      {
        return "";
      }

      if(key==="updateState")
      {
        return encodeURIComponent(key) + '=' + encodeURIComponent(element[key]);
      }
      let keys='evaluation['+arrayKey+'].'+key;
      return encodeURIComponent(keys) + '=' + encodeURIComponent(element[key]);
    }).join('&');

    arrayKey=arrayKey+1;

  });
  // console.log(searchParams)
  //  const searchParams = Object.keys(req).map((key) => {
  //   return encodeURIComponent(key) + '=' + encodeURIComponent(req[key]);
  // }).join('&');

  let response = await fetch(url,{
  method: 'POST',
  headers: {
    'Access-Control-Request-Headers':'Content-Type',
    'Content-Type': 'application/x-www-form-urlencoded',
    "Authorization": "bearer " + token
  },
  body:searchParams,
  })

  if (response.status != statusCode) {
    console.log('Looks like there was a problem. Status Code: ' + response.status);
    response = await response.json();
  }

  else response = await response.json();

  // console.log(response)
    // if(data.responseCode=="Success")
    // { console.log("Success")
    //   object=data;
    // }
    // else {object=data; console.log("Failed")}
  object=response;


  if(callback)
  {
    callback(response);
  }
  return response;
}

 
   
  export default PostData;