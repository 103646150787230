// Welcome Screen...!

import React, { Component } from 'react';
import "./ReportFeedback.css";
import Button from "@material-ui/core/Button";
import MyContext from '../../helper/themeContext';
import { Paper } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

class ReportFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = { isReviewModalOpen:false }
    }

    render() {
        return (
            <div id="RCPE-Welcome-main-container">
                <div id="all-content-wrapper">
                    
                    <div id="Report_Feedback_Welcome_Box1">
                        <div id="Report_Feedback_Heading_1"> What's Next? </div>

                        <div id="RCPE-Welcome-inner-box-1" style={{textAlign:'center'}}>
                            <div id="Report_Feedback_Heading_2"> Do you have any questions and want to talk to provider?  </div>

                            {/* <div id="RCPE_Welcome_Text_Div"> Recommended Care Pathway </div> */}

                            <div id="Report_Feedback_Div_2">
                                <Button className="Report_Feedback_Buttons">
                                    Set up an Appointment with your Provider
                                </Button>
                                <Button className="Report_Feedback_Buttons">
                                    Make a Telehealth Appointment
                                </Button>
                                <Button className="Report_Feedback_Buttons">
                                    Send Report to your Provider
                                </Button>
                                <Button className="Report_Feedback_Buttons">
                                    Chat Now
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div id="RCPE-Welcome-box-2">
                        <div id="RCPE-Welcome-footer-div1">
                            <Button
                                id="RCPE-Welcome-btn-1"
                                variant="contained"
                                onClick={()=>this.context.history.push('./complete-pdf')}
                            >
                                {" "} Back {" "}
                            </Button>
                        </div>

                        <div id="RCPE-Welcome-footer-div3">
                            <Button
                                id="RCPE-Welcome-btn-3"
                                variant="contained"
                                onClick={()=>this.context.history.push('/home') }
                            >
                                {" "} Finish {" "}
                            </Button>
                        </div>
                    </div>

                </div>
                    
                
            </div>
        );
    }
}
ReportFeedback.contextType=MyContext;
export default ReportFeedback;