import React, { Component } from 'react';
import "./confirmedViewType.css";
import Button from "@material-ui/core/Button";

// Note: Images URL from Assets...!
import Right_Kneecap_Cartoon from "../../assets/Cartoons/right kneecap cartoon.png";
import Right_Lateral_Cartoon from "../../assets/Cartoons/Right Lateral Cartoon.png";
import Left_Kneecap_Cartoon from "../../assets/Cartoons/left kneecap cartoon.png";
import Left_Lateral_Cartoon from "../../assets/Cartoons/Left Lateral Cartoon.png";
import Bilat_Kneecap_Cartoon from "../../assets/Cartoons/Bilat Kneecap Cartoon.png";
import Standing_Bilatral_Cartoon from "../../assets/Cartoons/standing bilatral cartoon.png";

import Right_Frog_Cartoon from "../../assets/Cartoons/Right Hip Cartoon.png";
import Left_Frog_Cartoon from "../../assets/Cartoons/Left Hip Cartoon.png";
import Ap_Pelvis_Cartoon from "../../assets/Cartoons/AP Cartoon.png";

const arrOfXRays = [
    {
        text1: 'X-ray View Confirmation: Right Kneecap',
        text2:'Rotate and reflect the X-ray as needed to match the orientation seen on the left.',
        id: '2',
        imageURL: Right_Kneecap_Cartoon
    },
    {
        text1: 'X-ray View Confirmation: Right Lateral',
        text2:'Rotate and reflect the X-ray as needed to match the orientation seen on the left.',
        id: '3',
        imageURL: Right_Lateral_Cartoon
    },
    {
        text1: 'X-ray View Confirmation: Left Kneecap',
        text2:'Rotate and reflect the X-ray as needed to match the orientation seen on the left.',
        id: '4',
        imageURL: Left_Kneecap_Cartoon
    },
    {
        text1: 'X-ray View Confirmation: Left Lateral',
        text2:'Rotate and reflect the X-ray as needed to match the orientation seen on the left.',
        id: '5',
        imageURL: Left_Lateral_Cartoon
    },
    {
        text1: 'X-ray View Confirmation: BILATERAL KNEECAP',
        text2:'Rotate and reflect the X-ray as needed to match the orientation seen on the left.',
        id: '6',
        imageURL: Bilat_Kneecap_Cartoon
    },
    {
        text1: 'X-ray View Confirmation:PA Standing with no Flexion',
        text2:'Rotate and reflect the X-ray as needed to match the orientation seen on the left.',
        id: '1',
        imageURL: Standing_Bilatral_Cartoon
    },
    {
        text1: 'X-ray View Confirmation:PA Standing with Flexion',
        text2:'Rotate and reflect the X-ray as needed to match the orientation seen on the left.',
        id: '7',
        imageURL: Standing_Bilatral_Cartoon
    },
    {
        text1: 'X-ray View Confirmation:Ap Pelvis',
        text2:'Rotate and reflect the X-ray as needed to match the orientation seen on the left.',
        id: '8',
        imageURL: Ap_Pelvis_Cartoon
    },
    {
        text1: 'X-ray View Confirmation:Left Frog',
        text2:'Rotate and reflect the X-ray as needed to match the orientation seen on the left.',
        id: '9',
        imageURL: Left_Frog_Cartoon
    },
    {
        text1: 'X-ray View Confirmation:Right Frog',
        text2:'Rotate and reflect the X-ray as needed to match the orientation seen on the left.',
        id: '10',
        imageURL: Right_Frog_Cartoon
    },
];

class ConfirmedViewType extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const xray = arrOfXRays.find((item) => item.id.toString() === this.props.Xray.id.toString())
        return (
            // Main box...!
            <div id="XRConfirmation">

                {/* Wrapper */}
                <div id="all-content-wrapper">

                    {/* Div: 1 */}
                    <div id="XRConfirmation-box-1">
                        <div id="XRConfirmation-inner-box-1">
                            {xray?xray.text1:'X-ray View Confirmation: Unknown Type'} <br/>
                            {xray?xray.text2:'Rotate and reflect the X-ray as needed to match the orientation seen on the left.'}
                        </div>

                        {/* <div id="XRConfirmation-inner-box-2">
                            
                        </div> */}

                        <div id="XRConfirmation-inner-box-3">

                            <div id="XRConfirmation-inner-box-left-image">
                                <div id="XRConfirmation-inner-box-left-button-div">

                                </div>
                                { xray ?
                                    <div className="XRConfirmation-img-1-Wrapper">
                                        <img alt="App-Image" src={
                                            xray.imageURL
                                        } id="XRConfirmation-img-1" />
                                    </div>
                                : <div className="XRConfirmation-img-1-Wrapper" style={{background:'black'}}>
                                </div>}
                               
                            </div>
                            <div id="XRConfirmation-inner-box-right-image">
                                <Button
                                    id="XRConfirmation-Upper-Btn"
                                    variant="contained"
                                >
                                    {" "} Adjust Image {" "}
                                </Button>
                                <div className="XRConfirmation-img-1-Wrapper">
                                    <img alt="App-Image" src={this.props.Xray.image} id="XRConfirmation-img-2" />
                                </div>
                            </div>      
                            
                        </div>
                    </div>

                    {/* Div: 2 : Buttons Box */}
                    <div id="XRConfirmation-box-2">
                        <div id="XRConfirmation-footer-box-1">
                            <Button
                                id="XRConfirmation-btn-1"
                                variant="contained"
                                onClick={this.props.handleBackClick}
                            >
                                {" "} Upload Different X-ray {" "}
                            </Button>
                        </div>

                        <div id="XRConfirmation-footer-box-2">
                            <Button
                                id="XRConfirmation-btn-2"
                                variant="contained"
                                onClick={this.props.handleNextClick}
                            >
                                {" "} Confirm View {" "}
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default ConfirmedViewType;