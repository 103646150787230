import React, { Component } from 'react';
import ShowReport from './showReport';
import Selected from './selected';

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            View: "Report", ActiveState: null, ActiveType: null,
            ActiveXray: null, XrayImage: null, Aiprediction: null, JointName: null, MatchingXray: null, roi_updated: null, roi_updated_url: null
        }
    }

    handleView = (View) => {
        this.setState({ View })
    }

    setActive = (ActiveState, ActiveType, ActiveXray, XrayImage, Aiprediction, JointName, MatchingXray, roi_updated, roi_updated_url) => {
        this.setState({ ActiveState, ActiveType, ActiveXray, XrayImage, Aiprediction, JointName, MatchingXray, roi_updated, roi_updated_url })
    }

    render() {
        return (

            <div>
                {this.state.View === "Report" && <ShowReport handleView={(View) => this.handleView(View)} handleState={(ActiveState, ActiveType, ActiveXray, XrayImage, Aiprediction, JointName, MatchingXray, roi_updated, roi_updated_url) => this.setActive(ActiveState, ActiveType, ActiveXray, XrayImage, Aiprediction, JointName, MatchingXray, roi_updated, roi_updated_url)} />}
                {this.state.View === "Selected" && <Selected XrayImage={this.state.XrayImage} State={this.state.ActiveState} Type={this.state.ActiveType} Aiprediction={this.state.Aiprediction} Xray={this.state.ActiveXray} JointName={this.state.JointName} MatchingXray={this.state.MatchingXray} roi_updated={this.state.roi_updated} roi_updated_url={this.state.roi_updated_url} handleView={(View) => this.handleView(View)} handleState={(ActiveState, ActiveType, ActiveXray, XrayImage, Aiprediction, JointName, MatchingXray, roi_updated, roi_updated_url) => this.setActive(ActiveState, ActiveType, ActiveXray, XrayImage, Aiprediction, JointName, MatchingXray, roi_updated, roi_updated_url)} />}
            </div>);
    }
}

export default Report;