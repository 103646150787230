// JointSummary Screen...!

import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import "./LearnMoreGeneral.css";
import MyContext from '../../helper/themeContext';
import { getNOCPathId, getPathById, getPHRPathId, getPJRPathId, getTHRPathId, getTJRPathId } from '../../StoreFunctions/PRCStoreFunctions';



const comparments = [
    { name: 'Normal to Slight', id: '1', color: '#B3D89B' },
    { name: 'Moderate', id: '2', color: '#FAF075' },
    { name: 'Near End Stage', id: '3', color: '#F26E82' },
    { name: 'End Stage', id: '4', color: '#F26E82' },
    { name: 'Cannot Evaluate', id: '5', color: '#E4E4E4' }
]

class LearnMoreGeneral extends Component {
    constructor(props) {
        super(props);
        this.state = { ActiveImage: null, modal: false }
    }

    handleSearchClick = (Xray) => {
        this.setState({ ActiveImage: Xray, modal: true })
    }

    modalClose = () => {
        this.setState({ modal: false })
    }

    getRecommendation = (pathId) => {

        let pathName = getPathById(pathId).label2
        if(pathName)
        {
            return pathName
        }
        else return "Unable to get Recommendation";
    }

    render() {
        return (
            // Main container
            <div id="JointSummary-main-box">
                <div id="all-content-wrapper">
                    <div id="JointSummary-box-1">
                        {/* <div id="Joint_Summary_Heading_2">
                            {this.props.Joint_Name}
                        </div> */}
                        <div id="JointSummary-inner-box-1">
                            <p id="JointSummary-heading"> Learn More About Your Individualized Care Pathway </p>

                            {this.props.Joints.map((joint, id) =>
                                <React.Fragment key={id}>
                                    <div className="LearnMore-heading-1">
                                        <span className="LearnMore-heading-1-span"> {joint.Joint_Name} </span> Recommendation: {this.getRecommendation(joint.pathId)}
                                    </div>
                                    <div id="LearnMore-row-1">
                                        {
                                            ( (joint.pathId === getTJRPathId()) || (joint.pathId === getTHRPathId()) )  ?
                                                <React.Fragment>
                                                    <div>
                                                        <Button
                                                            className="LearnMoreGeneral-btn-1"
                                                            variant="contained"
                                                            onClick={(e) => this.props.handleLearnMoreSur(e, joint)}
                                                        >
                                                            {" "} Learn About Total Joint Replacement Surgery  {" "}
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            className="LearnMoreGeneral-btn-1"
                                                            variant="contained"
                                                            onClick={(e) => this.props.prepForSur(e, joint)}
                                                        >
                                                            {" "} How to Best Prepare for Surgery {" "}
                                                        </Button>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                ( (joint.pathId === getPJRPathId()) || (joint.pathId === getPHRPathId()) ) ?
                                                    <React.Fragment>
                                                        <div>
                                                            <Button
                                                                className="LearnMoreGeneral-btn-1"
                                                                variant="contained"
                                                                onClick={(e) => this.props.handleLearnMorePar(e, joint)}
                                                            >
                                                                {" "} Learn About Partial Knee Replacement Surgery  {" "}
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Button
                                                                className="LearnMoreGeneral-btn-1"
                                                                variant="contained"
                                                                onClick={(e) => this.props.prepForSur(e, joint)}
                                                            >
                                                                {" "} How to Best Prepare for Surgery {" "}
                                                            </Button>
                                                        </div>
                                                    </React.Fragment>
                                                    :
                                                    <div>
                                                        <Button
                                                            className="LearnMoreGeneral-btn-1"
                                                            variant="contained"
                                                            onClick={joint.pathId === getNOCPathId() ? (e) => this.props.handleLearnMoreNoc(e, joint) : (e) => this.props.handleLearnMoreNocOi(e, joint)}
                                                        >
                                                            Learn About Non-Operative Treatments
                                                </Button>
                                                    </div>
                                        }
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div id="RCPE-Welcome-box-2">
                        <div id="RCPE-Welcome-footer-div1">
                            <Button
                                id="RCPE-Welcome-btn-1"
                                variant="contained"
                                onClick={this.props.handleBackClick}
                            >
                                {" "} Back {" "}
                            </Button>
                        </div>

                        {
                            !(this.props.isHandleNextDisable==="true") &&
                            <div id="RCPE-Welcome-footer-div3">
                                <Button
                                    id="JointSummary-btn-2"
                                    variant="contained"
                                    onClick={this.props.handleNextClick}
                                >
                                    {" "} Next {" "}
                                </Button>
                            </div>
                        }

                        
                    </div>
                </div>
            </div>
        );
    }
}

LearnMoreGeneral.contextType = MyContext
export default LearnMoreGeneral;