
const   PostFormData =  async (url,statusCode,req,token,callback) => {


      let object;
    
  
    let response = await fetch(url,{
      method: 'POST',
      headers: {
        // 'Access-Control-Request-Headers':'Content-Type',
        // 'Content-Type': 'multipart/form-data',
        "Authorization": "bearer " + token
      },
      body:req,
  
    });

    if (response.status != statusCode) {
      // console.log('Looks like there was a problem. Status Code: ' + response.status);
      response = await response.json();
    }
    else response = await response.json()

    object = response;
    if(callback)
    {
      callback(object);
    }
    return object;   
  }
  
   
     
    export default PostFormData;