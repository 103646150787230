// Welcome Screen...!

import React, { Component } from 'react';
import "./Welcome.css";
import Button from "@material-ui/core/Button";
import Bone1Image from "../../assets/bone1_Bitmap.png";

import ReactPlayer from 'react-player'
import SemipolarLoading from "react-loadingg/lib/SemipolarLoading";

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = { isReviewModalOpen: false }
    }

    handleReviewModalClose = () => {
        this.setState({ isReviewModalOpen: false })
    }

    handleReviewModalOpen = () => {
        this.setState({ isReviewModalOpen: true })
    }

    render() {
        return (
            <div id="RCPE-Welcome-main-container">

                <div id="all-content-wrapper">

                    <div id="RCPE-Welcome-box-1">
                        <div id="RCPE-Welcome-inner-box-1">
                            <div id="RCPE-Welcome-Heading"> Hip & Knee <br /> Step by Step </div>

                            <div id="RCPE_Welcome_Neon_Line"></div>

                            <div id="RCPE_Welcome_Heading2">
                                Patient Specific and Individualized<br />
                                Recommended Care Pathway
                            </div>

                            {/* <div id="RCPE_Welcome_Text_Div"> Recommended Care Pathway </div> */}
                            {/* <div id="RCPE_Welcome_Text_Div2">
                                Hip & Knee Step by Step will recommend an individualized care pathway.
                            </div> */}
                        </div>
                        <div id="RCPE-Welcome-inner-box-2">
                            <img src={Bone1Image} id="bone-image" />
                        </div>
                    </div>

                    <div id="RCPE-Welcome-box-2">
                        <div id="RCPE-Welcome-footer-div1">
                            <Button
                                id="RCPE-Welcome-btn-1"
                                variant="contained"
                                onClick={this.props.handleBackClick}
                            >
                                {" "} Back {" "}
                            </Button>
                        </div>

                        <div id="RCPE-Welcome-footer-div2">
                            <Button
                                id="RCPE-Welcome-btn-2"
                                variant="contained"
                                onClick={this.handleReviewModalOpen}
                            >
                                {" "} Review Care Pathway Education {" "}
                            </Button>
                        </div>

                        <div id="RCPE-Welcome-footer-div3">
                            <Button
                                id="RCPE-Welcome-btn-3"
                                variant="contained"
                                onClick={this.props.handleNextClick}
                            >
                                {" "} Next {" "}
                            </Button>
                        </div>
                    </div>

                </div>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.isReviewModalOpen}
                    onClose={this.handleReviewModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.isReviewModalOpen}>
                        <div id="SBSVideo_Content_Wrapper">
                            <div id="SBSVideo_Center_Wrapper">
                                <div id="SBSVideo_Image_Wrapper">
                                    <div className="sbs-video-text-wrapper">
                                        <div id="SBSVideo_Heading1_Div">
                                            Understanding Care Pathways
                                        </div>
                                    </div>
                                    {this.state.loading == true ? (
                                        <SemipolarLoading size={"large"} color={"#b4ec51"} />
                                    )
                                        : null}
                                    <ReactPlayer onReady={this.onVideoReady}
                                        height='700px'
                                        width='1000px'
                                        url='https://vimeo.com/508157082' controls={true} playing={true}
                                        onEnded={this.onVideoEnd} light={this.state.light} />
                                </div>
                                <div id="SBSVideo_Next_Button_Div">
                                    <Button
                                        id="SBSVideo_Next_Button"
                                        variant="contained"
                                        onClick={this.handleReviewModalClose}
                                    >
                                        {" "}
                                        Close{" "}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>

            </div>
        );
    }
}

export default Welcome;