
import React, { Component } from 'react';
import MyContext from '../../helper/themeContext';
import GetData from '../../Fetch/getData1';
import SemipolarLoading from 'react-loadingg/lib/SemipolarLoading';
import Button from '@material-ui/core/Button';
import {  loadNextEval, NextEvalIndex } from '../../StoreFunctions/evaluationStoreFunctions';

import Bone1Image from "../../assets/bone1_Bitmap.png";


const nextPageRoute = "./report-feedback";
const previousPageRoute = "./common-joint-pdf"


//css from evaluation pdf
class CompletePdf extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, base64: "", blobUrl: '' }
    }

    componentDidMount = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let viewReport = urlParams.get("view-report");

        this.setState({viewReport})

        let req = {
            visitor_id: this.context.state.report_id
        }
        GetData(this.context.baseUrl + '/api/v1/get-prc/pdf', 200, req,
            this.context.state.token, this.setMe)
        // Get  (this.context.baseUrl+'/api/v1/download/pdf',token,callback)

    }

    setMe = (response) => {
        // console.log(response)

        if (response.base64pdf) {
            const byteCharacters = atob(response.base64pdf);
            const byteNumbers = new Array(byteCharacters.length);
            // console.log('length:' + byteCharacters.length)

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            this.setState({ loading: false, base64: response.base64pdf, blobUrl })
        }

        else alert("Pdf not found")
    }

    printDownload = e => {
        e.preventDefault();
        let iframe =  document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = this.state.blobUrl;
        iframe.onload = function() {
          setTimeout(function() {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };
        // window.print(this.state.blobUrl);
    }
    handleDownload = () => {
        var link = document.createElement('a');
        link.innerHTML = 'Download PDF file';
        link.download = this.context.state.patient_id + '_Report.pdf';
        link.href = 'data:application/octet-stream;base64,' + this.state.base64;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleNext = async () =>
    {

        let nextIndex = await NextEvalIndex(this.context.state);
        if(nextIndex > -1)
        {
            loadNextEval(this.context);
        }

        else {
            this.context.history.push(nextPageRoute)
        }
    }

    handleBack = ( ) =>
    {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let viewReport = urlParams.get("view-report");

        if(viewReport=="true")
        {
            this.context.history.push('/evaluation-history')
        }

        else this.context.history.push(previousPageRoute)

    }

    handleCarePathWay = () =>
    {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let viewReport = urlParams.get("view-report");

        if(viewReport=="true")
        {
            this.context.history.push('/evaluation/recommended-care-pathway?view-report=true')
        }

        else  this.context.history.push('/evaluation/recommended-care-pathway')
    
    }

    render() {
        return (
            <div id="Evaluation_PDF_MAIN_DIV">

                {this.state.loading === false ?
                    <div id="Evaluation_PDF_Content_Wrapper" >


                        <div id="Evaluaion_pdf_Container">

                            <div id="Evaluaion_pdf_child1">
                                <div id="Evaluaion_pdf_Heading1_Div">
                                    Patient Summary + Recommended Care Pathway
                                </div>
                                <div id="Evaluaion_pdf_iframe_wrapper">
                                    <iframe title="pdf" src={this.state.blobUrl} style={{ width: '100%', height: '100%' }} >
                                    </iframe>
                                </div>
                                <div id="Evaluaion_PDF_Back_Button_Div">
                                    <Button id="Evaluaion_PDF_Button" variant="contained" onClick={this.handleBack}> Back </Button>
                                </div>
                            </div>

                            <div id="Evaluaion_pdf_child2">
                                <div id="Evaluaion_PDF_Download_Button_Div" style={{height:"calc(100vh - 180px)",display: "flex",flexDirection: "column", alignItems: "center"}} className="Evaluation_PDF_Button_Div" >
                                    <div style={{height:"70px"}}>
                                        <Button id="Evaluaion_PDF_Button" variant="contained" onClick={this.handleDownload}> Download </Button>
                                    </div>

                                    <div style={{height:"70px"}}>
                                        <Button id="Evaluation_Print_Button" className='print' variant="contained" onClick={this.printDownload}> Print </Button>
                                    </div>

                                        {
                                            this.state.viewReport==="true" &&
                                            <div style={{height:"140px"}}>
                                                <Button id="Evaluation_Print_Button" className='print' variant="contained"
                                                    onClick={this.handleCarePathWay}> Additional Care Information
                                                </Button>
                                            </div>
                                        }

                                     

                                       

                                        <div style={{marginTop:"20px", textAlign:"center", color:"white",fontSize:"20px"}}>
                                            Combined Summary
                                            <div style={{marginTop:"20px"}}>
                                                <img src={Bone1Image} style={{width:"auto", maxWidth:"100%", maxHeight:"400px" }}/>
                                            </div>
                                        </div>

                                        {
                                            !(this.state.viewReport==="true") &&
                                            <div id="Evaluaion_PDF_Back_Home_Button_Div" style={{position:"absolute", bottom:"20px"}}>
                                                <Button id="Evaluaion_PDF_Button" variant="contained" onClick={this.handleNext}> Next </Button>
                                            </div>
                                        }   

                                </div>

                                
                                {/* print button here */}

                                                            
                            </div>
                        </div>
                    </div> :
                    <SemipolarLoading size={"large"} color={'#b4ec51'} />
                }
            </div>
        );
    }
}

CompletePdf.contextType = MyContext;
export default CompletePdf;