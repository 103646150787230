import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import NonOpImage from "../../assets/non-op.png";
import GetAppIcon from "@material-ui/icons/GetApp";
import "./JointNoi1.css";
import HowtoBestPrepareforSurgery from "../../assets/pdf/HowtoBestPrepareforSurgery.pdf";
import { Grid } from "@material-ui/core";

class PrepareforSur extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _downloadPdf = () => {
    var link = document.createElement("a");
    link.innerHTML = "Download ";
    link.download = "HowtoBestPrepareforSurgery.pdf";
    link.href = HowtoBestPrepareforSurgery;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  render() {
    return (
      <div id="RCPE-Welcome-main-container">
        <div id="all-content-wrapper">
          <div id="RCPE-Welcome-box-1">
            <div id="JointNoi3-inner-box-1">
              {/* Dic 1 */}
              <div id="RCPNOI-container-1">
                <h2 id="RCPNOI-heading">
                  {" "}
                  {`How to Best Prepare for Surgery`}{" "}
                </h2>

                <div id="RCPNOI-form-box-1">
                  <form id="RCPNOI-form">
                    <div className="RCPNOI-heading-1-wrapper">
                      <div
                        className="RCPNOI-heading-1-button-2-wrapper"
                        onClick={this._downloadPdf}
                      >
                        <GetAppIcon style={{ color: "#B4EC51" }} />
                      </div>
                      <div className="RCPNOI-heading-1"
                        style={{ cursor: 'pointer' }}
                        onClick={this._downloadPdf}>
                        Learn How the Best Prepared Patients Have the Best
                        Outcomes
                      </div>
                    </div>

                    <ul className="LearnAbout-unstyled RCPNOIcentered">
                      <Grid container>
                        <Grid item md={6} sm={12}>
                          {[
                            "How the Body controls Pain",
                            "Physical Activity",
                            "Emotional Wellness",
                            "Relaxational Therapy",
                            "Complementary Pain management",
                            "Coping Mechanism for Dealing with Pain",
                          ].map((text,i) => (
                            <li key={i}>
                              <label htmlFor={text} className="RCPNOI-form-field">
                                -{text}
                              </label>
                            </li>
                          ))}
                        </Grid>
                        <Grid item md={6}>
                          {[
                            "Nutritional and Weight Management",
                            "Smoking Cessation",
                            "Physical and Occupational Therapy",
                            "Assistive Devices",
                            "Joint Injections"
                          ].map((text,i) => (
                            <li key={i}>
                              <label htmlFor={text} className="RCPNOI-form-field">
                                -{text}
                              </label>
                            </li>
                          ))}
                        </Grid>
                      </Grid>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div id="RCPE-Welcome-box-2">
            <div id="RCPE-Welcome-footer-div1">
              <Button
                id="RCPE-Welcome-btn-1"
                variant="contained"
                onClick={this.props.handleBackClick}
              >
                {" "}
                Back{" "}
              </Button>
              <Button
                id="LearnAbout-btn-2"
                variant="contained"
                onClick={this._downloadPdf}
              >
                {" "}
                Download Treatment Education{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrepareforSur;
