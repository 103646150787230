import React, { Component } from 'react';
import MyContext from '../../helper/themeContext';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tick from '../../assets/tick-black.png';
import { getCommonEval, getJointCompartmentName, getLeftHipId, getRightHipId, sortEval } from '../../StoreFunctions/evaluationStoreFunctions';
import { getAggregate, getRatingById, LoadDegeneration } from '../../StoreFunctions/PRCStoreFunctions';


const height = '60px';
const xray = { state: 1 }

class ReportCardSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {JointSummary:[]}
    }

    componentDidMount()
    {
        let Eval = getCommonEval(this.context.state.activeJointIndex,this.context.state.Eval)
        Eval = sortEval(Eval);
        let MatchingEvaluations = this.context.state.Evaluations;
        let JointSummary = [];

        for (let i = 0; i < Eval.length; i++) {
            let Compartments = [];
            let Curr_Joint_id = Eval[i].joint_id.toString();
            let Curr_Evaluation = MatchingEvaluations.find(joint => joint.joint_id.toString() === Curr_Joint_id);
            Curr_Evaluation.Xrays.forEach((comp)=>{
                let CompartmentScore = getAggregate(comp.xrays[0].state, comp.xrays[1]?.state)
                Compartments.push({name:comp.name,score:CompartmentScore,id:comp.id});
            });
            JointSummary.push({joint_id:Curr_Evaluation.joint_id, Joint_Name: Curr_Evaluation.name, Compartments})
        }

        this.setState({ JointSummary, loading: false,})

    }

    handleNextClick = () => {
        // this.context.history.push('./chart')
        // --------- Skipping Page Entry -v1 Start-------------
        if(this.context.state.allUploadReportImages)
        {
            this.context.history.push('./recommended-care-pathway')
        }
        else this.context.history.push('./upload-report-images')
        // --------- Skipping Page Entry -v1 End-------------

    }

    checkState(expected, curr1, curr2) {
        curr1 = parseInt(curr1)
        curr2 = parseInt(curr2)
        expected = parseInt(expected)
        if (curr1 && curr2) {
            if (curr1 > 0 && curr2 > 0) {
                if (curr1.toString() === expected.toString() && curr2.toString() === expected.toString()) {
                    return true
                }

                else if (curr1.toString() === expected.toString() || curr2.toString() === expected.toString()) {
                    if (curr1.toString() === '5' || curr2.toString() === '5') {
                        return true;
                    }
                    else if (curr1.toString() === expected.toString()) {
                        if (curr2 < curr1) {
                            return true;
                        }
                    }

                    else if (curr2.toString() === expected.toString()) {
                        if (curr1 < curr2) {
                            return true;
                        }
                    }

                    else return false;
                }

                else return false;

            }

            else return false;
        }
    }

    checkCannotEvaluate = (num1, num2) => {
        if (num1 && num2) {
            if (num1 === "5" && num2 === "5") {
                return true;
            }
            else return false;
        }

        else if (num1) {
            if (num1 === "5") {
                return true;
            }
            else return false;
        }

        return true;
    }

    render() {
        const {JointSummary} = this.state;
        return (
            <div id="Evaluaion_Report_Main_Div">
                <div id="Evaluaion_Report_Content_Wrapper">
                    <div id="ReportMainDiv">
                        <div className="Evaluaion_Report_Box_Text1">
                            Summary of Worst Degeneration
                            <br />
                            in Each Compartment
                        </div>
                        <div id="Evaluaion_Report_Box_Wrapper">
                            {
                                JointSummary.map((joint, id) => {
                                    // let currEn = this.context.state.Evaluations.find(el => el.joint_id.toString() === eva.joint_id.toString())

                                    return (
                                        <Grid container key={id}>
                                            <Grid item xs={4} spacing={0} style={{ height: '100px' }}>
                                                <React.Fragment>
                                                    <span className="Evaluaion_Report_Box_Text3"
                                                        style={{ paddingTop: '60px' }}>
                                                        {joint.Joint_Name}
                                                    </span>
                                                </React.Fragment>
                                            </Grid>
                                            <Grid item xs={8} spacing={0} style={{ height: '100px' }}>
                                                <Grid container>
                                                    <Grid item xs={3} >
                                                        <div style={{ width: '2px', height: '100px', background: 'white', transform: 'rotate(45deg) translateY(-15px) translateX(35px)', }}>

                                                        </div>
                                                        <div className="Evaluaion_Report_Box_Text4">
                                                            Normal/Slight
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <div style={{ width: '2px', height: '100px', background: 'white', transform: 'rotate(45deg) translateY(-15px) translateX(35px)', }}>
                                                        </div>
                                                        <div className="Evaluaion_Report_Box_Text4">
                                                            Moderate
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <div style={{ width: '2px', height: '100px', background: 'white', transform: 'rotate(45deg) translateY(-15px) translateX(35px)', }}>
                                                        </div>
                                                        <div className="Evaluaion_Report_Box_Text4">
                                                            Near End Stage
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <div style={{ width: '2px', height: '100px', background: 'white', transform: 'rotate(45deg) translateY(-15px) translateX(35px)', }}>
                                                        </div>
                                                        <div className="Evaluaion_Report_Box_Text4">
                                                            End Stage
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {joint.Compartments.map((type, id) =>
                                                <Grid item xs={12} key={id}>
                                                    <Grid container>
                                                        <Grid item xs={4} spacing={0} style={{ border: '1px solid #fff', height: `calc(${height} - 2px)` }}>
                                                            <React.Fragment>
                                                                <div style={{ height: '100%' }}>
                                                                    <div className="Evaluaion_Report_Box_Text5">
                                                                        {type.name} <br /> {type.name !== "Kneecap" && getJointCompartmentName(joint.joint_id)}
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        </Grid>
                                                        <Grid item xs={8} spacing={0} >
                                                            <Grid container>
                                                                {
                                                                    type.score < 0 ?
                                                                    <Grid item xs={8} style={{ border: '1px solid #fff', height: `calc(${height} - 2px)`, background: '#A7A9AC' }}>
                                                                        <div className="Evaluaion_Report_Box_Selected_Box"> Cannot Evaluate </div>
                                                                    </Grid> :
                                                                    <React.Fragment>
                                                                        <Grid item xs={3} style={{ border: '1px solid #fff', height: `calc(${height} - 2px)`, background: (type.score === 1) ? '#6C8D31' : '' }}>
                                                                            <div className="Evaluaion_Report_Box_Selected_Box"> {(type.score === 1) && <img src={Tick} alt="Ticked" />} </div>
                                                                        </Grid>
                                                                        <Grid item xs={3} style={{ border: '1px solid #fff', height: `calc(${height} - 2px)`, background: (type.score === 2) ? 'yellow' : '' }}>
                                                                            <div className="Evaluaion_Report_Box_Selected_Box"  > {(type.score === 2) && <img src={Tick} alt="Ticked" />}  </div>
                                                                        </Grid>
                                                                        <Grid item xs={3} style={{ border: '1px solid #fff', height: `calc(${height} - 2px)`, background: (type.score === 3) ? '#fa9e2d' : '' }}>
                                                                            <div className="Evaluaion_Report_Box_Selected_Box"> {(type.score === 3) && <img src={Tick} alt="Ticked" />} </div>
                                                                        </Grid>
                                                                        <Grid item xs={3} style={{ border: '1px solid #fff', height: `calc(${height} - 2px)`, background: (type.score === 4) ? '#C50000' : '' }}>
                                                                            <div className="Evaluaion_Report_Box_Selected_Box"> {(type.score === 4) && <img src={Tick} alt="Ticked" />} </div>
                                                                        </Grid>
                                                                    </React.Fragment>      
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}

                                            {
                                                ((joint.joint_id===getLeftHipId())||(joint.joint_id===getRightHipId())) &&
                                                <Grid item xs={12} spacing={0}>
                                                    <Grid container>
                                                        <Grid item xs={4}>                  
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <div style={{ border: '1px solid #fff', height: `calc(${height} - 2px)`, fontSize: "24px", lineHeight: "58px", textAlign: "center", color:"#fff" }} >
                                                                Worst Degeneration: <span style={{ fontWeight:"bold" }} > {getRatingById(LoadDegeneration(joint.Compartments,joint.joint_id).toString()).name} </span>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </div>

                        <div id="Evaluaion_NewEvaluation_Back_Button_Divs">
                            <Button id="Evaluaion_NewEvaluation_Next_Button_report"
                                variant="contained"
                                onClick={() => this.context.history.push('./x-ray-matching')}>
                                Back </Button>
                        </div>
                        <div id="Evaluaion_Report_Next_Button_Div">
                            <Button id="Evaluaion_Report_Next_Button" variant="contained" onClick={this.handleNextClick}> Next  </Button>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

ReportCardSummary.contextType = MyContext;
export default ReportCardSummary;