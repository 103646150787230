import React, { Component } from 'react';

import './evaluationHistory.css';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import MyContext from '../../helper/themeContext';
import GetData from '../../Fetch/getDataUniversal';
import { SemipolarLoading } from 'react-loadingg';
import { LoadNewEval } from '../../StoreFunctions/evaluationStoreFunctions';

class EvaluationHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rpt_id: null, rows: []
    }
  }


  componentDidMount() {
    GetData(this.context.baseUrl + '/api/v1/user/reports', 200, this.context.state.token, this.setMe);
  }

  setMe = (response) => {
    if (response.length > 0) {
      let rows = [];
      response.forEach(element => {
        let mydate = element.patient[0].date.toString().match(/\d+/g).map(Number);
        let mydate_year = mydate[0];
        let mydate_month = mydate[1];
        let mydate_date = mydate[2];

        if (mydate_month.toString().length === 1) {
          mydate_month = '0' + mydate_month;
        }

        if (mydate_date.toString().length === 1) {
          mydate_date = '0' + mydate_date;
        }
        let newdate = mydate_month + '-' + mydate_date + '-' + mydate_year;
        rows.push({ patient_id: element.patient[0].id, patient_name: element.patient[0].name, evaluation_date: newdate, report_id: element.id })
      });

      this.setState({ rows, loading: false })
    }

    else this.setState({ loading: false })

  }

  handleViewReport = (report_id) => {
    this.context.multipleUpdateValueWithHistory([{ key: 'random_report_id', value: report_id }], './evaluation/selected-patient-report')
  }

  handleRecoverReport = (id) => {
    this.setState({ loading: true })
    this.context.newVisitor();
    GetData(this.context.baseUrl + `/api/v1/get/report?report_id=${id}`,
      200, this.context.state.token, this.setMeThree)   //getCompletedReportsOnlyForPathway
  }

  handleRecover = (id) => {
    this.setState({ loading: true })
    this.context.newVisitor();
    GetData(this.context.baseUrl + `/api/v1/get/report?report_id=${id}`,
      200, this.context.state.token, this.setMeTwo)   //getCompletedReportsOnlyForPathway
  }

  setMeTwo = (response) => {
    if (response.report_id != null && response.report_id !== undefined && response.report_id !== "" && response.report_id !== " ") {
      LoadNewEval(this.context, response, '/evaluation/recommended-care-pathway');
    }
  }

  setMeThree = (response) => {
    if (response.report_id != null && response.report_id !== undefined && response.report_id !== "" && response.report_id !== " ") {
      LoadNewEval(this.context, response, '/evaluation/complete-pdf?view-report=true');
    }
  }


  handleDelete = id => {
    GetData(this.context.baseUrl + `/api/v1/delete/completed-report?report_id=${id}`,
      200, this.context.state.token,
      this.setMeFour)
  }


  setMeFour = res => {
    let new_rows = this.state.rows;
    new_rows = JSON.parse(JSON.stringify(new_rows));
    new_rows = new_rows.filter((row) =>
      row.report_id.toString() !== this.state.rpt_id?.toString())
    this.setState({ rpt_id: null, rows: new_rows })
  }

  render() {
    return (
      <div id="Evaluaion_PatientReport_Main_Div">
        {this.state.loading === true ? (
          <SemipolarLoading size={"large"} color={"#b4ec51"} />
        ) : (
          <div id="Evaluaion_PatientReport_Content_Wrapper" style={{ justifyContent: "flex-start" }}>
            <div id="Evaluaion_PatientReport_Heading1_Div">Select a Report to View</div>

            <div id="Evalution_History_Table_Wrapper" style={{ width: "80vw" }}>
              <div id="Evalution_History_Table_Head">
                <div style={{ width: "200px", display: "inline-block", marginRight: "20px" }}>Patient Name</div>
                <div style={{ width: "200px", display: "inline-block", marginRight: "20px" }}>
                  Date of Evaluation
                </div>
                <div style={{ width: "200px", display: "inline-block", marginRight: "20px" }}></div>
              </div>

              <div
                id="Evalution_History_Table_Line"
                style={{ width: "100%", height: "2px", background: "#ffffff" }}></div>

              <div id="Evalution_History_Table_Body">
                {this.state.rows.map((row, id) => (
                  <div className="Evalution_History_Table_Body_Row" key={id}>
                    <div
                      className="Evalution_History_Table_Body_Text"
                      style={{ width: "200px", display: "inline-block", marginRight: "20px" }}>
                      {row.patient_name}
                    </div>
                    <div
                      className="Evalution_History_Table_Body_Text"
                      style={{ width: "200px", display: "inline-block", marginRight: "20px" }}>
                      {row.evaluation_date}
                    </div>
                    <div style={{ width: "200px", display: "inline-block", marginRight: "20px" }}>
                      <Button
                        className="Evaluaion_History_Button"
                        variant="contained"
                        onClick={() => {
                          this.handleRecoverReport(row.report_id)
                        }}>
                        {" "}
                        View Report{" "}
                      </Button>
                    </div>

                    {/* <div style={{
                      width: "200px",
                      display: "inline-block", marginRight: "20px"
                    }}>
                      <Button
                        className="Evaluaion_History_Button"
                        variant="contained"
                        onClick={() => this.handleRecover(row.report_id)}>
                        {" "}
                        Suggested Care Pathway{" "}
                      </Button>
                    </div> */}
                    <div style={{
                      width: "40px",
                      display: "inline-block",
                      margin: "0 20px 0 0",
                    }} >
                      <IconButton className='Evaluation_Delete_btn'
                        onClick={() => {
                          this.setState({ rpt_id: row.report_id })
                          this.handleDelete(row.report_id)
                        }}>
                        <Delete style={{ color: '#B4EC51' }} fontSize='large' />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div id="Evaluaion_PatientReport_Back_Button_Div">
              <Button
                id="Evaluaion_PatientReport_Next_Button"
                variant="contained"
                onClick={() => this.context.history.push("/home")}>
                {" "}
                Back{" "}
              </Button>
            </div>

            {/* <div id="Evaluaion_PatientReport_Next_Button_Div">
                <Button
                  id="Evaluaion_PatientReport_Next_Button"
                  variant="contained"
                  onClick={() => {
                    this.context.history.push("/home");
                  }}>
                  {" "}
                  BACK TO HOME{" "}
                </Button>
              </div> */}
          </div>
        )}
      </div>
    );
  }
}
EvaluationHistory.contextType = MyContext;
export default EvaluationHistory;