import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import KneeImage from "../../assets/knee_evaluation_home.png";
import "./Knee.css";
import MyContext from "../../helper/themeContext";
import { GetPatientSurvey_Tutorial_Id, GetRCP_Tutorial_Id, GetSampleKnee_Tutorial_Id, GetTestMatching_Tutorial_Id, GetWhatYouKneeKnow_Tutorial_Id, GetXrayAi_Tutorial_Id, _checkIncomplete } from "../../StoreFunctions/tutorialStoreFunction";

class KneeLaunchpad extends Component {
  constructor(props) {
    super(props);
    this.state = {}; 
  }

  render() {
    return (
      <div id="Knee_Main_Div">
        <div id="Knee_Content1_Wrapper">
          <div id="Knee_Heading1_Div">
            Hip &<span style={{ color: "#b4ec51" }}> Knee </span> Step by Step
            <br />
            <span style={{ fontWeight: "bold", fontSize: "75px" }}> Additional Knee Information </span>{" "}
            <div id="Home_Neon_Line"></div>
          </div>

          <Grid container>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <div className="home-grid-left">

                <div className="Home_Button_Div">
                  <Button
                    id="Home_Button"
                    variant="contained"
                    onClick={() => {
                      this.context.history.push("/tutorials/knee-Arthiritis");
                    }}>
                    {" "}
                    What You Knee'd to Know{" "}
                  </Button>
                  {
                    _checkIncomplete(GetWhatYouKneeKnow_Tutorial_Id(),this.context) ?
                    <div className="Home_Incomplete_Div" >
                        Incomplete
                    </div>
                    :
                    <div className="Home_Complete_Div" >
                        Complete
                    </div>
                  }
                </div>

                <div className="Home_Button_Div">
                  <Button
                    id="Home_Button"
                    variant="contained"
                    onClick={() => {
                      this.context.history.push("/tutorials/matching-education/welcome");
                    }}>
                    {" "}
                    X-ray Matching &  <br/> Artificial Intelligence
                  </Button>
                  {
                    _checkIncomplete(GetXrayAi_Tutorial_Id(),this.context) ?
                    <div className="Home_Incomplete_Div" >
                        Incomplete
                    </div>
                    :
                    <div className="Home_Complete_Div" >
                        Complete
                    </div>
                  }
                </div>

                <div className="Home_Button_Div">
                  <Button
                    id="Home_Button"
                    variant="contained"
                    onClick={() => {
                      this.context.history.push("/tutorials/knee/patient-survey");
                    }}>
                    {" "} Patient Surveys {" "}
                  </Button>
                  {
                    _checkIncomplete(GetPatientSurvey_Tutorial_Id(),this.context) ?
                    <div className="Home_Incomplete_Div" >
                        Incomplete
                    </div>
                    :
                    <div className="Home_Complete_Div" >
                        Complete
                    </div>
                  }
                </div>

                <div className="Home_Button_Div">
                  <Button
                    id="Home_Button"
                    variant="contained"
                    onClick={() => {
                      this.context.history.push("/tutorials/knee/sample-knee-evaluation");
                    }}>
                    {" "} Sample Knee Evaluation {" "}
                  </Button>
                  {
                    _checkIncomplete(GetSampleKnee_Tutorial_Id(),this.context) ?
                    <div className="Home_Incomplete_Div" >
                        Incomplete
                    </div>
                    :
                    <div className="Home_Complete_Div" >
                        Complete
                    </div>
                  }
                </div>

                <div className="Home_Button_Div">
                  <Button
                    id="Home_Button"
                    variant="contained"
                    onClick={() => {
                      this.context.history.push("/tutorials/knee/sample-patient-report-and-recommended-care-pathway");
                    }}>
                    Recommended Care Pathway
                  </Button>
                  {
                    _checkIncomplete(GetRCP_Tutorial_Id(),this.context) ?
                    <div className="Home_Incomplete_Div" >
                        Incomplete
                    </div>
                    :
                    <div className="Home_Complete_Div" >
                        Complete
                    </div>
                  }
                </div>

                <div className="Home_Button_Div">
                  <Button
                    id="Home_Button"
                    variant="contained"
                    onClick={() => {
                      this.context.history.push("/tutorials/matching-training/welcome");
                    }}>
                    {" "}
                    Test your X-ray <br/> Matching Skills
                  </Button>
                  {
                    _checkIncomplete(GetTestMatching_Tutorial_Id(),this.context) ?
                    <div className="Home_Incomplete_Div" >
                        Incomplete
                    </div>
                    :
                    <div className="Home_Complete_Div" >
                        Complete
                    </div>
                  }
                </div>
              </div>
            </Grid>

            <Grid item xs={0} md={2} lg={2} xl={2}></Grid>

            <Grid item xs={12} md={4} lg={4} xl={4} className="image-grid">
              <div id="Home_Image_div_wrapper">
                <div id="Home_Image_div_knee_evaluation">
                  <img src={KneeImage} alt="SBS" id="Knee_Evaluation_KneeBone" />
                </div>
                <div className="Home_Button_Div">
                  <Button
                    id="Home_Button"
                    variant="contained"
                    style={{ width: "250px" }}
                    onClick={() => {
                      this.context.history.push("./options")
                    }}>
                    {" "}
                    BACK{" "}
                  </Button>
                </div>
              </div>

              {/* <div className="Home_Button_Div_Wrapper">
                               
                            </div> */}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
KneeLaunchpad.contextType = MyContext;
export default KneeLaunchpad;
