import React, { Component } from 'react';

import Page1B from '../../assets/page1b.png';
import MyContext from '../../helper/themeContext';
import { getAllCompletedJointsByPriority, moreThanOneJointEval, previousAlreadyDone } from '../../StoreFunctions/evaluationStoreFunctions';

import './page1b.css'

class ReportPage1B extends Component {
    constructor(props) {
        super(props);
        this.state = {allJoints:[]}
    }

    componentDidMount()
    {
        this._setPageInfo();
    }

    _setPageInfo = () =>
    {
        let Eval = this.context.state.Eval;
        // console.log(this.context.state.activeJointIndex)
        // console.log(Eval)
        let allJoints = getAllCompletedJointsByPriority(Eval,this.context.state.activeJointIndex);
        this.setState({allJoints})
    }

    render() {
        const {isDevTesting} = this.props;
        return (
            <div className="Report-Page1B" style={{
                width: "575px", height: "830px",
                backgroundRepeat:"no-repeat",
                backgroundSize:"100% 100%",
                backgroundImage: `url(${Page1B})`, position: isDevTesting ? 'relative' : 'absolute',
                 top: isDevTesting ? '0px' : '-4800px', //-4800px
                  zIndex: '3',
            }}>
                <div style={{ maxWidth:'110px',position: 'absolute', left: '80px', width: '476px', bottom: '70px' }}>
                    <div id="patient-name" style={{ marginBottom: '10px', color: 'white', fontWeight: '600', fontFamily: 'Google Sans,Roboto, arial,sans-serif', fontSize: '22px', fontStyle: 'italic' }}>
                        {this.context.state.patient.name}
                    </div>
                    <div id="patient-id" style={{ marginBottom: '10px', color: 'white', fontWeight: '600', fontFamily: 'Google Sans,Roboto, arial,sans-serif', fontSize: '22px', fontStyle: 'italic' }}>
                        IDS: {this.context.state.patient_id}
                    </div>
                    <div id="patient-name" style={{ marginBottom: '10px', color: 'white', fontWeight: '600', fontFamily: 'Google Sans,Roboto, arial,sans-serif', fontSize: '22px', fontStyle: 'italic' }}>
                        {/* Date of Evaluation: */}
                        <span id="evaluation-date"> {this.context.state.patient.date.replace(/-/g,'/')} </span>
                    </div>
                </div>

                <div style={{ maxWidth:"190px",position: 'absolute', left: '220px', width: '476px', bottom: `${ 110 - (this.state.allJoints.length * 20) }px` }}>
                    <div id="patient-name" style={{ marginBottom: '10px', color: 'white', fontWeight: '600', fontFamily: 'Google Sans,Roboto, arial,sans-serif', fontSize: '22px', fontStyle: 'italic' }}>
                        Joints By Priority:
                    </div>
                    {
                        this.state.allJoints.map((joint)=>
                        <div id="patient-name" style={{ marginBottom: '10px', color: 'white', fontWeight: '600', fontFamily: 'Google Sans,Roboto, arial,sans-serif', fontSize: '22px', fontStyle: 'italic' }}>
                            {joint.priority_id} - {joint.name}
                        </div>)
                    }
                </div>

                <div className="Page1B-Number">
                    1
            </div>
            </div>
        );
    }
}

ReportPage1B.contextType = MyContext;
export default ReportPage1B;