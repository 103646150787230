import React, { Component } from 'react';

import Button from '@material-ui/core/Button';

// import Tick from '../../assets/button-tick.png';

import Dropzone from 'react-dropzone'
import PostFormData from './uploadPostForm';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import './upload.css';
import MyContext from '../../helper/themeContext';

const viewTypes = [
    { id: '1', text: 'Bilateral PA Standing Detected', type: 'ap_knee' },
    { id: '2', text: 'Bilateral Kneecap Detected', type: 'bilateral_kneecap' },
    { id: '3', text: 'Unilateral Kneecap Detected', type: 'unilateral_kneecap' },
    { id: '4', text: 'Lateral Knee Detected', type: 'lateral_knee' },
    { id: '5', text: 'AP Pelvis Detected', type: 'ap_pelvis' },
    { id: '6', text: 'Right Hip Lateral Detected', type: 'right_frog' },
    { id: '7', text: 'Left Hip Lateral Detected', type: 'left_frog' },
    { id: '8', text: 'X-ray cannot be identified', type: 'unknown', background: 'red' },
]


class UploadBox extends Component {
    constructor(props) {
        super(props);
        this.state = { uploaded: false, file: null, loading: false }
    }

    handleUpload = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {

            this.setState({ file: acceptedFiles[0], loading: true, uploaded: false })
            let form_data = new FormData();
            form_data.append('visitor_id', this.context.state.report_id)
            let file = acceptedFiles[0];
            form_data.append('img', file)
            form_data.append('xray_type_id', this.props.Xray.id)

            PostFormData(this.context.baseUrl + '/api/v1/xray/upload-single', 200, form_data, this.context.state.token, this.newSetMe)
            // this.props.appendFile(acceptedFiles[0],this.props.Xray.name,this.props.Xray.id)
        }
    }

    handleDelete = () => {
        //this.props.handleDelete
    }

    newSetMe = (response) => {
        if (response.responseCode === "Success") {
            // const imageBlob =  response.get('image');        
            // const ImageUrl = URL.createObjectURL(imageBlob);
            
            this.setState({ uploaded: true, loading: false, viewType: response.viewType, image64:response.image })
        }

        else {
            this.setState({ loading: false })
            alert('Unable to upload!, Please try again')
        }

    }

    // newSetMe = (response) => {
    //     console.log(response);
    //     if (response.responseCode === "Success") {
    //         this.setState({ uploaded: true, loading: false, viewType: response.viewType, ImageUrl:response.ImageUrl })
    //     }

    //     else {
    //         this.setState({ loading: false })
    //         alert('Unable to upload!, Please try again')
    //     }

    // }

    getBackground = () => {

        if (this.state.viewType === 'unknown') {
            return 'orange';
        }

        if (this.state.viewType === this.props.Xray.type) {
            return '';
        }

        else {
            return 'red'
        }

    }

    render() {
        return (

            <div id="Evaluaion_UploadXray_Upload_Box1_Div">
                <span className="Evaluaion_UploadXray_Upload_Box_Head">
                    <strong>  {this.props.Xray.name} </strong>
                </span>
                <div className="Evaluaion_UploadXray_Upload_Box_Line">
                </div>
                {/* <div className="circle-with-arrow" style={{backgroundColor:this.state.uploaded?'#b4ec51':'hsla(0, 0%, 100%, 0.27)'}}></div> */}
                {
                    this.state.loading === true ?
                        <div id="Evaluaion_UploadXray_Upload_Box_Complete_Div">
                            {/* <span className="complete-circle-with-arrow"></span> */}
                            <span id="Evaluaion_UploadXray_Upload_Box_Complete_Text_Div">{this.state.file.name}</span>
                            <div className="Evaluaion_UploadXray_Upload_Box_Loader_Div">
                                <span className="Evaluaion_UploadXray_Upload_Box_Loader_Text"> Identifying View </span>
                                <Loader
                                    type="Rings"
                                    color="#B4EC51"
                                    height={60}
                                    width={60}
                                    style={{ display: 'inline-block', verticalAlign: 'middle', opacity: '1' }}
                                />
                            </div>
                        </div>

                        : this.state.uploaded === true ?

                            <div id="Evaluaion_UploadXray_Upload_Box_Complete_Div">
                                <span className="complete-circle-with-arrow"></span>
                                <span id="Evaluaion_UploadXray_Upload_Box_Complete_Text_Div">{this.state.file.name} </span>
                                <Button variant="contained" onClick={this.props.handleDelete} className="Evaluaion_UploadXray_Upload_Box_Delete_Button" > <DeleteOutlinedIcon color="default" /> </Button>
                                <div className="Evaluaion_UploadXray_Upload_Box_ViewType_Text" style={{ background: this.getBackground() === 'red' ? '' : this.getBackground() }}>
                                    {
                                        viewTypes.find((view) => this.state.viewType === view.type).text
                                    }
                                </div>
                                {this.getBackground() === "red" &&
                                    <div className="Evaluaion_UploadXray_Upload_Box_ViewType_Text" style={{ background: 'red' }}>
                                        Incorrect X-ray Type Detected
                                </div>
                                }

                            </div>

                            :
                            <Dropzone accept={'image/jpeg,image/png,.dcm'} multiple={false} onDrop={acceptedFiles => this.handleUpload(acceptedFiles)}>
                                {({ getRootProps, getInputProps }) => (
                                    <section style={{ height: '100%' }}>
                                        <div {...getRootProps()} id="Evaluaion_UploadXray_Upload_Box_Main">
                                            <input {...getInputProps()} />
                                            {/* <div className="circle-with-arrow-tiny" ></div> */}
                                            <div className="circle-with-arrow" style={{ backgroundColor: this.state.uploaded ? '#b4ec51' : 'hsla(0, 0%, 100%, 0.27)' }}></div>

                                            <div id="Evaluaion_UploadXray_Upload_Box_Main_Text">Drag and Drop or Click Here <br /> to Upload Xray</div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                }


                {this.state.uploaded === true ?
                    <div id="Evaluaion_UploadXray_Upload_Next_Button_Div">
                        <Button id="Evaluaion_UploadXray_Upload_Next_Button" variant="contained" onClick={() => this.props.appendFile(this.state.file, this.props.Xray.name, this.props.Xray.id,`data:image/jpeg;base64,${this.state.image64}`)}> Next </Button>
                    </div>
                    : null
                }

            </div>

        );
    }
}

UploadBox.contextType = MyContext;
export default UploadBox;